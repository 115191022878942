<div class="assistance">
  <div>
    <div class="row">
      <span>{{ assistanceSelected?.date }}</span>
    </div>
    <form class="row mui-form" [formGroup]="assistanceForm" novalidate>
      <div
        class="row detailAssistance"
        *ngIf="attendanceIntegrationParking && assistanceInfo?.parking"
      >
        <div class="col-sm-12 iconAssistance" [ngClass]="activeIconParking()">
          <i
            [ngClass]="
              assistanceInfo?.parking?.parkingSizeAttendance[0]?.typeCode ===
              'Motorcycle'
                ? 'vdp-parkingMotoBig'
                : 'vdp-parking_big'
            "
          ></i>
        </div>
        <div class="col-12" class="codeParking" *ngIf="showParkingSpotInfo">
          <span>{{
            assistanceInfo?.parking?.parkingSizeAttendance[0]?.code
          }}</span>
        </div>
        <div class="col-sm-12">
          <label>{{getMessageParking()}}</label>
        </div>
        <div
          class="col-6"
          *ngIf="
            !assistanceInfo?.parking?.assignmentId &&
            typeVehicle > 1 &&
            !assistanceInfo?.parking?.userInParkingWaitingList &&
            parkingSizeAttendanceEnable(assistanceInfo)
          "
        >
          <div class="select-by form-group select-group">
            <label for="placeParking">{{
              'assistance_text_available_vehicles' | messagesPipe
            }}</label>
            <select
              id="placeParking"
              name="placeParking"
              class="form-control"
              formControlName="placeParking"
            >
              <option [value]="0">
                {{ 'none' | messagesPipe }}
              </option>
              <option
                *ngFor="
                  let place of assistanceInfo?.parking?.parkingSizeAttendance
                "
                [value]="place?.typeId"
                [disabled]="
                  !assistanceInfo?.parking?.parkingWaitingListEnabled &&
                  !place?.enabled
                "
              >
                {{ place?.size }}
                {{
                  assistanceInfo?.parking?.parkingWaitingListEnabled &&
                  !place?.enabled
                    ? ('assistance_text_waiting' | messagesPipe)
                    : ''
                }}
              </option>
            </select>
          </div>
        </div>
        <div
          [ngClass]="
            assistanceInfo?.parking?.assignmentId ||
            typeVehicle == 1 ||
            assistanceInfo?.parking?.userInParkingWaitingList
              ? 'col-12'
              : 'col-6'
          "
        >
          <div
            class="placed-button"
            *ngIf="
              (assistanceInfo?.parking?.assignmentId &&
                !assistanceSelected.disabledWhitAssistance) ||
              (!assistanceInfo?.parking?.assignmentId &&
                !assistanceInfo?.parking?.size)
            "
          >
            <button
              (click)="
                assistanceInfo?.parking?.parkingWaitingListEnabled &&
                assistanceInfo?.parking?.userInParkingWaitingList
                  ? cancelWaitingList(assistanceInfo?.attendance?.day)
                  : liberateOrSolictParking(
                      assistanceInfo?.parking?.assignmentId
                    )
              "
              class="check-availability"
              [disabled]="
                buttonDisabledRequestParking || checkSelectedDisabled()
              "
            >
              {{
                assistanceInfo?.parking?.assignmentId ||
                (!assistanceInfo?.parking?.assignmentId &&
                  assistanceInfo?.parking?.parkingWaitingListEnabled &&
                  assistanceInfo?.parking?.userInParkingWaitingList)
                  ? ('assistance_bottom_cancel' | messagesPipe)
                  : ('assistance_bottom_solict' | messagesPipe)
              }}
            </button>
            <br />
            <span>{{
              !assistanceInfo?.parking?.hasVehicle
                ? ('assistance_text_not_assign_parking_text_alert'
                  | messagesPipe)
                : !assistanceInfo?.parking?.parkingSizeAttendance
                ? ('assistance_text_not_parking_space_available' | messagesPipe)
                : ''
            }}</span>
          </div>
        </div>
      </div>
      <div
        class="row detailAssistance"
        *ngIf="attendanceIntegrationDinnerDesk && assistanceInfo?.dinner"
      >
        <div class="col-sm-12 iconAssistance" [ngClass]="activeIconDinner()">
          <i class="vdp-comedor_big"></i>
        </div>
        <div class="col-sm-12">
          <label
            >{{
              !checkDinnerDays(assistanceSelected.indexWeek) ||
              !checkDinnerMonths(assistanceSelected.dateComplete)
                ? ('assistance_text_not_dining_room_service' | messagesPipe)
                : assistanceInfo?.dinner?.id
                ? ('assistance_text_assign_dinner' | messagesPipe)
                : ('assistance_text_not_assign_dinner' | messagesPipe)
            }}<span
              *ngIf="
                checkDinnerDays(assistanceSelected.indexWeek) &&
                checkDinnerMonths(assistanceSelected.dateComplete) &&
                assistanceInfo?.dinner?.id
              "
              >{{ assistanceInfo?.dinner.inningFromHourString }}:{{
                assistanceInfo?.dinner.inningFromMinuteString
              }}</span
            ></label
          >
        </div>
        <div
          class="col-sm-6"
          *ngIf="
            checkDinnerDays(assistanceSelected.indexWeek) &&
            checkDinnerMonths(assistanceSelected.dateComplete) &&
            !assistanceInfo?.dinner?.id &&
            assistanceInfo?.dinner?.availableTurns.length > 0 &&
            !assistanceSelected.disabledWhitAssistance
          "
        >
          <div class="select-by form-group select-group">
            <label for="turn">{{
              'assistance_text_available_shifts' | messagesPipe
            }}</label>
            <select
              id="turn"
              name="turn"
              class="form-control"
              formControlName="turn"
              (change)="changeTurn($event.target.value)"
            >
              <option
                [value]="0"
                [selected]="
                  checkShiftPriority(assistanceInfo.dinner.availableTurns)
                "
              >
                {{ 'none' | messagesPipe }}
              </option>
              <option
                *ngFor="let turn of assistanceInfo.dinner.availableTurns"
                [value]="turn?.id"
                [selected]="assistanceForm.controls['turn'].value === turn.id"
              >
                {{ turn?.shiftFromAux }} - {{ turn?.shiftToAux }}
              </option>
            </select>
          </div>
        </div>
        <div
          *ngIf="
            checkDinnerDays(assistanceSelected.indexWeek) &&
            checkDinnerMonths(assistanceSelected.dateComplete) &&
            !assistanceInfo?.dinner?.id &&
            assistanceInfo?.dinner?.availableTurns.length > 0 &&
            !assistanceSelected.disabledWhitAssistance
          "
        >
          <div class="col-sm-12">
            <div class="placed-button">
              <button
                (click)="solictTurn()"
                class="check-availability"
                [disabled]="buttonDisabledRequestDinner || !turnOK"
              >
                {{ 'assistance_bottom_solict' | messagesPipe }}
              </button>
            </div>
          </div>
        </div>
        <div
          class="col-sm-12"
          *ngIf="
            checkDinnerDays(assistanceSelected.indexWeek) &&
            checkDinnerMonths(assistanceSelected.dateComplete) &&
            assistanceInfo?.dinner?.id &&
            !assistanceSelected.disabledWhitAssistance
          "
        >
          <div class="placed-button">
            <button
              (click)="confirmCancelAsistTurn()"
              class="check-availability"
            >
              {{ 'assistance_bottom_cancel' | messagesPipe }}
            </button>
          </div>
        </div>
      </div>
      <div
        class="row detailAssistance"
        *ngIf="
          attendanceIntegrationDinnerDesk &&
          !assistanceInfo?.dinner &&
          (assistanceSelected.workingHome || assistanceSelected.partTime)
        "
      >
        <div class="col-sm-12 iconAssistance">
          <i class="vdp-comedor_big"></i>
        </div>
        <div class="col-sm-12">
          <label>{{
            checkDinnerDays(assistanceSelected.indexWeek) &&
            (assistanceSelected.workingHome || assistanceSelected.partTime)
              ? ('assistance_text_not_dining_room_service' | messagesPipe)
              : null
          }}</label>
        </div>
      </div>
    </form>
    <div class="row">
      <div
        class="col-12 cancelAssistence"
        *ngIf="!assistanceSelected.disabledWhitAssistance"
      >
        <a (click)="confirmCancelAssistance()">
          <i class="vdp-user-x">
            {{ 'assistance_bottom_cancel_assistance' | messagesPipe }}</i
          >
        </a>
      </div>
    </div>
  </div>
</div>
