<div
  class="right-drawer reservation-search-by-room-period"
  [@slideDetailsBooking]="getNewReservationAnimate()"
>
  <button
    type="button"
    class="vdp-icon-cancelar"
    (click)="closeComponent()"
  ></button>
  <span>{{ 'search_reservations_periods_by_room' | messagesPipe }}</span>

  <div *ngIf="availablePeriodsInput">
    <app-reservation-results-by-room-and-period
      [availablePeriodsInput]="availablePeriodsInput"
      [selectedRoom]="roomSelected"
      [reservationReason]="reservationReason"
      (reservationDone)="closeComponent()"
    >
    </app-reservation-results-by-room-and-period>
  </div>

  <form
    [hidden]="availablePeriodsInput"
    class="row mui-form"
    (ngSubmit)="checkReservations()"
    [formGroup]="reservationForm"
  >
    <div class="date-by form-group col-sm-6 mui-textfield">
      <label for="fecha">{{ 'from' | messagesPipe }}</label>
      <datepicker
        #datePickerFromElement
        class="custom-datepicker"
        id="fechaFrom"
        [options]="datepickerFromOptions"
        (dateChanged)="onDateFromChanged($event)"
      ></datepicker>
      <span class="required" [hidden]="reservationDateFrom">{{
        'error_required' | messagesPipe
      }}</span>
    </div>

    <div class="date-by form-group col-sm-6 mui-textfield">
      <label for="fecha">{{ 'since' | messagesPipe }}</label>
      <datepicker
        #datePickerToElement
        class="custom-datepicker"
        id="fechaTo"
        [options]="datepickerToOptions"
        (dateChanged)="onDateToChanged($event)"
      ></datepicker>
      <span class="required" [hidden]="reservationDateTo">{{
        'error_required' | messagesPipe
      }}</span>
    </div>

    <div class="form-group select-group col-sm-6">
      <div class="mui-select">
        <label for="hourFrom">{{ 'duration' | messagesPipe }}</label>
        <select
          #selectDurationHours
          class="form-control"
          id="durationHours"
          name="durationHours"
          (change)="onChangeDurationHours($event)"
        >
          <option
            *ngFor="let durationHour of durationHours"
            [value]="durationHour"
            >{{ durationHour }}</option
          >
        </select>
      </div>
      <span>:</span>
      <div class="mui-select">
        <select
          #selectDurationMinutes
          class="form-control"
          id="durationMinutes"
          name="durationMinutes"
          (change)="onChangeDurationMinutes($event)"
        >
          <option
            *ngFor="let durationMinute of durationMinutes"
            [value]="durationMinute"
            >{{ durationMinute }}</option
          >
        </select>
      </div>
      <span>{{ 'hours' | messagesPipe }}</span>
    </div>

    <div class="last-textfield form-group col-sm-12 mui-textfield">
      <label for="reason">{{ 'meeting_reason' | messagesPipe }}</label>
      <textarea
        placeholder="{{ 'reservation_reason_placeholder' | messagesPipe }}"
        id="reason"
        name="reason"
        class="form-control"
        rows="3"
        [formControl]="reservationForm.controls['reason']"
      ></textarea>
      <span class="required" [hidden]="reservationForm.controls.reason.valid">{{
        'error_required' | messagesPipe
      }}</span>
    </div>

    <div class="form-group select-group col-sm-12">
      <div class="mui-select">
        <label for="userHeadquarters">{{
          'headquarters' | messagesPipe
        }}</label>
        <span id="userHeadquarters" name="userHeadquarters">
          {{ userHeadquarter.name }}
        </span>
      </div>
    </div>

    <div class="building-by form-group select-group col-sm-12">
      <div class="mui-select">
        <label for="selectBuildings">{{
          'building_select_lbl' | messagesPipe
        }}</label>
        <select
          #selectBuildings
          id="selectBuildings"
          name="selectBuildings"
          class="form-control"
          (change)="onBuildingChanged($event)"
        >
          <option [value]="-1">{{ 'select_building' | messagesPipe }}</option>
          <option
            *ngFor="let building of buildings"
            [value]="building.id"
            [selected]="
              building && user.building && building.id == user.building.id
            "
          >
            {{ building.name }}
          </option>
        </select>
      </div>
    </div>

    <div class="rooms-by form-group select-group col-sm-12">
      <div class="mui-select">
        <label for="selectRooms">{{ 'room_select_lbl' | messagesPipe }}</label>
        <select
          #selectRooms
          id="selectRooms"
          name="selectRooms"
          class="form-control"
          (change)="onRoomChanged($event)"
        >
          <option [value]="-1">{{ 'select_room' | messagesPipe }}</option>
          <option *ngFor="let room of rooms" [value]="room.id">{{
            room.name
          }}</option>
        </select>
      </div>
    </div>

    <div class="col-sm-12 check">
      <button
        type="submit"
        class="check-availability button-by"
        [disabled]="!submitButtonEnabled()"
      >
        {{ 'btn_view_rooms' | messagesPipe }}
      </button>
    </div>
  </form>
</div>
