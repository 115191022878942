<div
  class="right-drawer new-incidence"
  [@slideDetailsBooking]="getNewIncidenceAnimate()"
>
<app-spinner></app-spinner>
  <button
    type="button"
    class="vdp-icon-cancelar"
    (click)="closeFormNewIncidence()"
  ></button>
  <span>{{'title_incidence' | messagesPipe}}</span>
  <form class="row mui-form" [formGroup]='incidenceForm' (ngSubmit)="checkIncidence()" novalidate >
    <div class="select-by form-group select-group col-sm-12">
      <div class="mui-select">
        <label for="selectBuildings"
          >{{('building' | messagesPipe) | uppercase}}</label
        >
        <select
          #selectBuildings
          id="selectBuildings"
          name="selectBuildings"
          class="form-control"
          (change)='changeBuilding()'
          formControlName='idBuilding'
        >
          <option [value]="-1" selected>{{'select_building' | messagesPipe}}</option>
          <option
            *ngFor="let building of buildings"
            [value]="building.id"
          >
            {{building.name}}
          </option>
        </select>
      </div>
    </div>
    <div class="select-by form-group select-group col-sm-12">
        <div class="mui-select">
          <label for="selectFloor"
            >{{('floor' | messagesPipe) |uppercase}}</label
          >
          <select
            #selectFloor
            id="selectFloor"
            name="selectFloor"
            class="form-control"
            formControlName= 'idFloor'
            (change)='changeFloor()'
            
          >
            <option [value]="-1">{{'select_floor' | messagesPipe}}</option>
            <option
              *ngFor="let floor of floors"
              [value]="floor.id"
            >
              {{floor.name}}
            </option>
          </select>
        </div>
      </div>
    <div class="select-by form-group select-group col-sm-12">
        <div class="mui-select">
          <label for="selectArea"
            >{{('area' | messagesPipe) |uppercase}}</label
          >
          <select
            #selectArea
            id="selectArea"
            name="selectArea"
            class="form-control"
            (change)='changeArea()'
            formControlName='idZona'
          >
            <option [value]="-1">{{'select_area' | messagesPipe}}</option>
            <option
              *ngFor="let area of areas"
              [value]="area.id"
            >
              {{area.name}}
            </option>
          </select>
        </div>
      </div>
      <div class="select-by form-group select-group col-sm-12">
        <div class="mui-select">
          <label for="selectResources"
            >{{('resource' | messagesPipe) |uppercase}}</label
          >
          <select
            #selectResources
            id="selectResources"
            name="selectResources"
            formControlName='idResource'
            (change)=' changeResource()'
            class="form-control"
          >
            <option [value]="-1">{{'select_resource' | messagesPipe}}</option>
            <option
              *ngFor="let resource of resources"
              [value]="resource.id"
            >
              {{resource.name}}
            </option>
          </select>
        </div>
      </div>
     <div class="select-by form-group select-group col-sm-12">
        <div class="mui-select">
          <label for="selectCategories"
            >{{('categorie' | messagesPipe) |uppercase}}</label
          >
          <select
            #selectCategories
            id="selectCategories"
            name="selectCategories"
            formControlName='idCategory'
            class="form-control"
          >
            <option [value]="-1">{{'select_categorie' | messagesPipe}}</option>
            <option
              *ngFor="let categorie of categories"
              [value]="categorie.id"
            >
              {{categorie.name}}
            </option>
          </select>
        </div>
      </div>
      <div class="last-textfield form-group mui-textfield col-sm-12">
          <label for="selectBuildings"
            >{{('observation' | messagesPipe) |uppercase}}</label
          >
          <textarea
          placeholder="{{'reservation_reason_placeholder' | messagesPipe}}"
          #textObservation
          id="textObservation"
          name="textObservation"
          formControlName='description'
          class="form-control"
          rows="3"
        ></textarea>
      </div>
      <div class="select-by form-group select-group col-sm-12">
        <label for="imageFile">{{'user_image' | messagesPipe}} {{'user_image_optional' | messagesPipe}}</label>
        <input #imageFile id="imageFile" type="file"  accept="image/*" (change)=changeImagen($event)/>
      </div>
    <button
      type="submit"
      class="check-availability button-by"
      [disabled]="validForm()"
    >
      {{'send_report' | messagesPipe}}
    </button>
  </form>
</div>