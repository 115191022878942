<div [ngClass]="showForm ? 'edit-assistance' : ''">
  <div class="right-drawer" [@slideDetailsBooking]="getAnimations()">
    <button
      type="button"
      class="vdp-icon-cancelar"
      (click)="closeForm()"
    ></button>

    <span>
      {{
        mode === 'ASSISTANCE-USER'
          ? ('menu_assistance_user' | messagesPipe)
          : assistanceConfirm
          ? ('assistance_text_confirm' | messagesPipe)
          : ('assistance_text_not_confirm' | messagesPipe)
      }}</span
    >

    <div *ngIf="mode === 'ASSISTANCE'">
      <app-calendar-edit-confirm
        *ngIf="assistanceConfirm"
        [assistanceSelected]="assistanceSelected"
        [assistanceInfo]="assistanceInfo"
        [shiftPriority]="shiftPriority"
        [mainVehicle]="mainVehicle"
        [typeVehicle]="typeVehicle"
        [showParkingSpotInfo]="showParkingSpotInfo"
        [attendanceIntegrationParking]="attendanceIntegrationParking"
        [attendanceIntegrationDinnerDesk]="attendanceIntegrationDinnerDesk"
        [userSelect]="userSelect"
        (closeFormEmmitter)="closeForm()"
        (confirmCancel)="confirmCancelCalendar($event)"
        (confirmCancelPlace)="confirmCancelCalendarPlace($event)"
        (confirmCancelTurn)="confirmCancelCalendarTurn($event)"
      ></app-calendar-edit-confirm>
      <app-calendar-edit-not-confirm
        *ngIf="!assistanceConfirm"
        [assistancesList]="assistancesList"
        [shiftPriority]="shiftPriority"
        [mainVehicle]="mainVehicle"
        [showParkingSpotInfo]="showParkingSpotInfo"
        [attendanceIntegrationParking]="attendanceIntegrationParking"
        [attendanceIntegrationDinnerDesk]="attendanceIntegrationDinnerDesk"
        [userSelect]="userSelect"
        (closeFormEmmitter)="closeForm()"
      ></app-calendar-edit-not-confirm>
    </div>
    <div *ngIf="mode === 'ASSISTANCE-USER'">
      <app-calendar-edit-list-users
        [assistanceSelected]="assistanceSelected"
        [listUsersForDay]="listUsersForDay"
      ></app-calendar-edit-list-users>
    </div>
  </div>
</div>
