import { Location } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { MenuPermissions } from 'src/app/core/models/menu.permissions';
import { environment } from '../../../../environments/environment';
import {
  ANIMATE_CLASS_ACTIVE,
  ANIMATE_CLASS_INACTIVE,
  COLLAPSED_RESOLUTION,
} from '../../../core/constants/consts';
import { LOCAL_CURRENT_USER } from '../../../core/constants/const';
import { UserLdapWs } from '../../../core/models/user.ldap.ws';
import { AuthenticationService } from '../../services/authentication.service';
import { NavigatorRefreshService } from '../../services/navigator.refresh.service';
import { slideButtons } from '../../utils/reservation.list.animations';
import { UserService } from '../../../shared/services/user.service';

@Component({
  templateUrl: 'menu.component.html',
  styleUrls: ['navbar.scss'],
  animations: [slideButtons()],
})
export class MenuComponent implements OnInit {
  user: UserLdapWs;
  showLogoutButton: boolean;
  navCollapsed: boolean;
  navSticky: boolean;
  userName: string;
  linkName = '';
  permissions: MenuPermissions;
  ie: boolean;
  showBack: boolean;
  routerLinkLopd: any;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private navigatorRefreshService: NavigatorRefreshService,
    private azureService: MsalService,
    private location: Location,
    private userService: UserService
  ) {
    this.showLogoutButton = false;
    this.ie = false;
    this.showBack = false;
  }

  ngOnInit() {
    var userAgent, ieReg;
    userAgent = window.navigator.userAgent;
    ieReg = /msie|Trident.*rv[ :]*11\./gi;
    this.ie = ieReg.test(userAgent);
    this.navCollapsed = window.innerWidth <= COLLAPSED_RESOLUTION;
    this.user = this.authenticationService.getCurrentUser();
    this.permissions = this.authenticationService.getPermissionsForCurrentUser();

    if (this.user == null) {
      this.router.navigate(['/login']);
    } else {
      this.setUserName();

      this.navigatorRefreshService
        .getMenuRefreshProfileEmitter()
        .subscribe((imageBase64: string) => {
          this.user = this.authenticationService.getCurrentUser();
          this.setUserName();

          if (imageBase64 && imageBase64.length > 0) {
            this.user.image = imageBase64;
          }
        });

      this.navigatorRefreshService
        .getMenuRefreshLinkEmitter()
        .subscribe((linkName: string) => {
          this.linkName = linkName;
        });
    }

    this.getLanguageDefault();
  }

  setUserName() {
    if (this.user.name && this.user.name.length > 0) {
      this.userName = this.user.name;
    } else {
      this.userName = this.user.username;
    }
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(event): void {
    if (event.target.innerWidth <= COLLAPSED_RESOLUTION) {
      this.navCollapsed = true;
    } else {
      this.navCollapsed = false;
    }
  }

  setNavCollapsed(): void {
    this.navCollapsed = !this.navCollapsed;
  }

  setNavSticky(): void {
    this.navSticky = !this.navSticky;
  }

  showLogout() {
    this.showLogoutButton = !this.showLogoutButton;
    if (this.ie) {
      const el = document.getElementById('logout');
      if (this.showLogoutButton) {
        el.style.display = 'block';
        el.style.opacity = '1';
      } else {
        el.style.display = 'none';
        el.style.opacity = '0';
      }
    }
  }

  getSlideButtonsClass(): string {
    if (!this.ie) {
      if (this.showLogoutButton) {
        return ANIMATE_CLASS_ACTIVE;
      } else {
        return ANIMATE_CLASS_INACTIVE;
      }
    }
  }

  logout() {
    if (environment['use_azure']) {
      localStorage.removeItem(LOCAL_CURRENT_USER);
      this.azureService.logout();
    } else {
      this.authenticationService.logout();
    }
  }

  get enableLopd(): boolean {
    return environment['enableLopd'];
  }

  get disableDownload(): boolean {
    return environment['downloadAvailable'];
  }

  get hiddenParking(): boolean {
    return environment['hiddenParking'];
  }

  get hiddenDinnerDesk(): boolean {
    return environment['hiddenDinnerDesk'];
  }

  get hiddenRoomReservation(): boolean {
    return environment['hiddenRoomReservation'];
  }

  get hiddenIncidences(): boolean {
    return environment['hiddenRoomReservation'];
  }

  get hiddenHotDesktop(): boolean {
    return environment['hiddenHotDesktop'];
  }

  goBack() {
    this.location.back();
  }

  showIconBack(): boolean {
    const url = this.activatedRoute.snapshot['_routerState'].url as String;
    if (url.includes('/viewMultiple-position/')) {
      this.showBack = true;
    } else {
      this.showBack = false;
    }

    return this.showBack;
  }

  getLanguageDefault() {
    this.userService
      .getLanguage(this.authenticationService.getToken())
      .subscribe((response) => {
        if (response) {
          this.routerLinkLopd = [`/lopd/${response.text}`];
        }
      });
  }
}
