import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { UserLdapWs } from 'src/app/core/models/user.ldap.ws';
import { environment } from '../../../../../../environments/environment';
import { MessagesPipe } from '../../../../pipes/messages.pipe';
import { AttendanceService } from '../../../../services/attendance.service';
import { AuthenticationService } from '../../../../services/authentication.service';
import { BaseService } from '../../../../services/base.service';
import { FlashMessagesService } from '../../../../services/flash-messages.service';

@Component({
  selector: 'app-calendar-edit-not-confirm',
  templateUrl: './calendar-edit-not-confirm.component.html',
  styleUrls: ['./calendar-edit-not-confirm.component.scss'],
})
export class CalendarEditNotConfirmComponent implements OnInit, OnChanges {
  @Input() assistancesList: any;
  @Input() shiftPriority: any;
  @Input() mainVehicle: any;
  @Input() showParkingSpotInfo: any;
  @Input() attendanceIntegrationParking: any;
  @Input() attendanceIntegrationDinnerDesk: any;
  @Input() userSelect: any;
  @Output() closeFormEmmitter = new EventEmitter<any>();

  assistanceForm: FormGroup;
  messagesPipe = new MessagesPipe();
  dinnerShift: any;
  user: UserLdapWs;

  buttonDisabledConfirm = false;
  placesAvailables: any;

  constructor(
    private formBuilder: FormBuilder,
    private flashMessagesService: FlashMessagesService,
    private authenticationService: AuthenticationService,
    private attendanceService: AttendanceService,
    private baseService: BaseService
  ) {}

  ngOnInit() {
    this.user = this.authenticationService.getCurrentUser();
    this.initForm();
    this.dinnerShift = this.user.dinnerShiftFrom
      ? moment(this.user.dinnerShiftFrom).format('HH:mm')
      : '';
  }

  initForm() {
    this.assistanceForm = this.formBuilder.group({
      turn: [this.shiftPriority ? this.shiftPriority : '0'],
      placeParking: [
        this.mainVehicle && this.user.parkingRequest ? this.mainVehicle : '0',
      ],
    });
  }

  ngOnChanges() {
    this.ngOnInit();
  }

  confirmAssistance() {
    this.buttonDisabledConfirm = true;
    const attendancesSave = [];
    const valueTurn =
      this.assistanceForm.controls['turn'].value > 0
        ? parseInt(this.assistanceForm.controls['turn'].value, 10)
        : null;
    const valuePlaceParking =
      this.assistanceForm.controls['placeParking'].value > 0
        ? parseInt(this.assistanceForm.controls['placeParking'].value, 10)
        : null;
    this.assistancesList.forEach((element) => {
      let availableTurn = null;
      let availablePlaceParking = null;
      if (
        valueTurn &&
        element.info.dinnerDesk &&
        element.info.dinnerDesk.availableTurns.length > 0
      ) {
        availableTurn = element.info.dinnerDesk.availableTurns.filter(
          (turn) => {
            return parseInt(turn.id, 10) === valueTurn;
          }
        );
      }

      if (
        valuePlaceParking &&
        element.info.parking &&
        element.info.parking.parkingSizeAttendance.length > 0
      ) {
        availablePlaceParking =
          element.info.parking.parkingSizeAttendance.filter((place) => {
            return parseInt(place.typeId, 10) === valuePlaceParking;
          });
      }

      if (
        availableTurn &&
        this.checkDinnerDays(element.indexWeek) &&
        this.checkDinnerMonths(element.dateComplete)
      ) {
        const availableTurnsAux = {
          shiftFrom:
            availableTurn && availableTurn.length > 0
              ? availableTurn[0].shiftFrom
              : '',
          shiftTo:
            availableTurn && availableTurn.length > 0
              ? availableTurn[0].shiftTo
              : '',
        };
        element.info.dinnerDesk.availableTurns = [availableTurnsAux];
      } else {
        element.info.dinnerDesk = null;
      }

      if (availablePlaceParking) {
        const availablePlaceParkingAux = {
          typeId: availablePlaceParking[0].typeId,
          code: availablePlaceParking[0].code,
          size: availablePlaceParking[0].size,
          enabled: availablePlaceParking[0].enabled,
        };
        element.info.parking.parkingSizeAttendance = availablePlaceParkingAux;
      } else {
        element.info.parking = null;
      }
      attendancesSave.push(element.info);
    });
    this.update(attendancesSave);
  }

  parkingSizeAttendanceEnable(assistance) {
    return assistance?.info?.parking?.parkingSizeAttendance.find(
      (x) => x.enabled
    );
  }

  update(attendances: any) {
    this.attendanceService.update(attendances, this.userSelect).subscribe(
      (res) => {
        if (res) {
          this.flashMessagesService.show(
            this.messagesPipe.transform('assistance_text_confirm_ok'),
            { cssClass: 'alert-success', timeout: 3000 }
          );
          this.closeForm();
        }
      },
      (error) => {
        this.baseService.showErrorDialog(
          this.flashMessagesService,
          error,
          this.messagesPipe.transform('assistance_update_error')
        );
        this.closeForm();
      },
      () => {
        this.closeForm();
        this.buttonDisabledConfirm = false;
      }
    );
  }

  getActiveConfirm() {
    let find = false;
    if (this.assistancesList) {
      this.assistancesList.forEach((element) => {
        if (
          element.info.availableTurns &&
          element.info.availableTurns.length > 0
        ) {
          find = true;
        }
      });
    }
    if (this.assistanceForm.controls['turn'].value < 0 && find) {
      return ` a-not-active`;
    } else {
      return ` `;
    }
  }

  checkShiftPriority(value: any) {
    let res = true;
    if (value) {
      value.forEach((element) => {
        if (element.id == this.shiftPriority) {
          res = false;
        }
      });
    }

    return res;
  }

  checkSelectedPlace(value: any) {
    let res = false;
    if (value.main) {
      res = true;
    }

    return res;
  }

  objectKeys(objeto: any) {
    const values = objeto ? Object.values(objeto) : '';
    return values;
  }

  closeForm() {
    this.assistanceForm.reset();
    this.closeFormEmmitter.emit();
    this.buttonDisabledConfirm = false;
  }

  checkDinnerDays(indexWeek) {
    let hasDinner = false;
    const dinnerDays = environment['dinnerDays'];
    if (dinnerDays) {
      dinnerDays.forEach((element) => {
        if (element == indexWeek) {
          hasDinner = true;
        }
      });
    } else {
      hasDinner = true;
    }
    return hasDinner;
  }

  checkDinnerMonths(day) {
    const date = moment(day, 'DD-MM-YYYY');
    const month = date.month() + 1;
    let hasDinner = false;
    const dinnerMonths = environment['dinnerMonths'];
    if (dinnerMonths) {
      dinnerMonths.forEach((element) => {
        if (element == month) {
          hasDinner = true;
        }
      });
    } else {
      hasDinner = true;
    }
    return hasDinner;
  }
}
