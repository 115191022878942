import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { LOGOUT_TIMEOUT } from "../../core/constants/consts";
import { NavigatorRefreshService } from "../../shared/services/navigator.refresh.service";
import { ReservationService } from "../../shared/services/reservation.service";
import { FlashMessagesService } from "../../shared/services/flash-messages.service";
import { ConfirmService } from "../../shared/services/confirm.service";
import { AuthenticationService } from "../../shared/services/authentication.service";
import { DalkonService } from "../../shared/services/dalkon.service";
import { RoomService } from "../../shared/services/room.service";
import { BaseService } from "../../shared/services/base.service";
import { MessagesPipe } from "../../shared/pipes/messages.pipe";
import { ReservationWs } from "../../core/models/reservation.ws";
import { DalkonValorDevuelto } from "../../core/models/dalkon.valor.devuelto";
import { UserLdapWs } from "../../core/models/user.ldap.ws";
import { STATUS_FORBIDDEN } from "../../core/constants/const";
import { MenuPermissions } from "src/app/core/models/menu.permissions";
import { ModalIndicateDepartmentService } from '../../shared/components/modal-indicate-department/modal-indicate-department.service';
import { HomeService } from './home.service';

@Component({
  templateUrl: "home.component.html",
  styleUrls: ["home.scss"]
})
export class HomeComponent implements OnInit {
  user: UserLdapWs;
  permissions: MenuPermissions;
  nextReservation: ReservationWs;
  reservationDetail: ReservationWs;
  reservationDetailSpeed: ReservationWs;
  messagesPipe: MessagesPipe;
  parking: DalkonValorDevuelto;
  userName: string;
  headquartersAllowedParking: boolean;

  constructor(
    private authenticationService: AuthenticationService,
    private reservationService: ReservationService,
    private roomService: RoomService,
    private flashMessagesService: FlashMessagesService,
    private confirmService: ConfirmService,
    private baseService: BaseService,
    private navigatorRefreshService: NavigatorRefreshService,
    private modalIndicateDepartmentService: ModalIndicateDepartmentService,
    private homeService: HomeService
  ) {
    this.reservationDetail = new ReservationWs();
    this.reservationDetailSpeed = new ReservationWs();

    this.messagesPipe = new MessagesPipe();
  }

  ngOnInit() {
    this.user = this.authenticationService.getCurrentUser();
    this.permissions = this.authenticationService.getPermissionsForCurrentUser();

    if (!this.checkDptForUser()) {
      this.modalIndicateDepartmentService.openModal();
    }

    this.loadLastReservationAndParking();

    if (this.user.name && this.user.name.length > 0) {
      this.userName = this.user.name;
    } else {
      this.userName = this.user.username;
    }

    this.navigatorRefreshService.emitMenuRefreshLinkEvent(
      this.messagesPipe.transform("menu_init")
    );
  }

  loadLastReservationAndParking(): void {
    this.reservationService
      .nextReservation(this.authenticationService.getToken())
      .subscribe(
        response => {
          if (response.id) {
            this.nextReservation = response;
          } else {
            this.nextReservation = null;
          }
          this.homeService.inicio();
        },
        (error) => {
          this.handleNetworkError(error, 'error_nextBooking', () => {
            this.loadLastReservationAndParking();
          });
        }
      );
  }

  loadLastReservation(): void {
    this.reservationService
      .nextReservation(this.authenticationService.getToken())
      .subscribe(
        response => {
          if (response.id) {
            this.nextReservation = response;

            if (typeof this.nextReservation.dateFrom == "number") {
              this.nextReservation.dateFrom = new Date(
                this.nextReservation.dateFrom as number
              );
            }

            if (typeof this.nextReservation.dateTo == "number") {
              this.nextReservation.dateTo = new Date(
                this.nextReservation.dateTo as number
              );
            }
          } else {
            this.nextReservation = null;
          }
        },
        (error) => {
          this.handleNetworkError(error, 'error_nextBooking', () => {
            this.loadLastReservation();
          });
        }
      );
  }

  getRoomBuildingName(): string {
    if (
      this.nextReservation.room.floor &&
      this.nextReservation.room.floor.building
    ) {
      return `${this.nextReservation.room.name}, ${this.nextReservation.room.floor.building.name}`;
    } else {
      return this.nextReservation.room.name;
    }
  }

  getHeadquartersName(): string {
    if (
      this.nextReservation.room.floor &&
      this.nextReservation.room.floor.building
    ) {
      return `${this.nextReservation.room.floor.building.headquarters.name}`;
    }
  }

  showDetail() {
    this.roomService
      .getRoomDetail(
        this.nextReservation.room.id,
        this.nextReservation.dateFrom,
        this.authenticationService.getToken()
      )
      .subscribe(
        room => {
          this.nextReservation.room = room;

          if (typeof room.serverDate == "number") {
            this.nextReservation.room.serverDate = new Date(
              room.serverDate as number
            );
          }

          if (!this.nextReservation.room.reservations) {
            this.nextReservation.room.reservations = [];
          }

          if (!this.nextReservation.room.equipment) {
            this.nextReservation.room.equipment = [];
          }

          if (this.nextReservation.room.type.id == 1) {
            this.reservationDetail = this.nextReservation;
            this.reservationDetailSpeed = new ReservationWs();
          } else {
            this.reservationDetailSpeed = this.nextReservation;
            this.reservationDetail = new ReservationWs();
          }
        },
        (error) => {
          this.handleNetworkError(error, 'error_getEquipement', () => {
            this.showDetail();
          });
        }
      );
  }

  closeDetail() {
    if (this.reservationDetail.id) {
      this.reservationDetail = new ReservationWs();
    } else if (this.reservationDetailSpeed.id) {
      this.reservationDetailSpeed = new ReservationWs();
    }
  }

  cancelReservation($event) {
    this.confirmService
      .activate(
        this.messagesPipe.transform("reservation_delete_msg"),
        this.messagesPipe.transform("reservation_delete_msg_title")
      )
      .then(result => {
        if (result) {
          this.cancelReservationService();
        }
      })
      .catch(error => {
        this.flashMessagesService.grayOut(false);
        this.flashMessagesService.show(
          this.messagesPipe.transform("confirm_dialog_err"),
          { cssClass: "alert-danger", timeout: 3000 }
        );
      });
  }

  cancelReservationService() {
    this.reservationService
      .cancelReservation(
        this.nextReservation,
        this.authenticationService.getToken()
      )
      .subscribe(
        response => {
          this.closeDetail();
          this.nextReservation = null;
          this.flashMessagesService.grayOut(false);
          this.flashMessagesService.show(
            this.messagesPipe.transform("reservation_cancelled"),
            { cssClass: "alert-success", timeout: 3000 }
          );
          this.loadLastReservation();
        },
        (error) => {
          this.handleNetworkError(error, 'reservation_cancelled_error', () => {
            this.cancelReservationService();
          });
        }
      );
  }

  editReservation($event) {
    this.reservationService
      .editReservationService($event, this.authenticationService.getToken())
      .subscribe(
        reservation => {
          this.nextReservation.dateFrom = reservation.dateFrom;
          this.nextReservation.dateTo = reservation.dateTo;
          this.nextReservation.reason = reservation.reason;
          this.closeDetail();

          this.flashMessagesService.grayOut(false);
          this.flashMessagesService.show(
            this.messagesPipe.transform("edit_reservation_msg"),
            { cssClass: "alert-success", timeout: 3000 }
          );
        },
        (error) => {
          this.handleNetworkError(error, 'edit_reservation_error', () => {
            this.editReservation($event);
          });
        }
      );
  }

  checkDptForUser() {
    if (this.permissions && this.user) {
      if (this.permissions.mandatoryDpto && this.user.department) {
        return true;
      } else if (!this.permissions.mandatoryDpto) {
        return true;
      }
    }
    return false;
  }

  private handleNetworkError(
    error: any,
    errorMessage: string,
    callback: Function
  ): void {
    if (error.code === STATUS_FORBIDDEN) {
      this.authenticationService.refreshToken().subscribe(
        (response) => {
          callback();
        },
        () => {
          this.authenticationService.validateSessionId().subscribe(
            (response) => {
              callback();
            },
            (error) => {
              this.flashMessagesService.grayOut(false);
              this.flashMessagesService.show(
                this.messagesPipe.transform('error_forbidden'),
                { cssClass: 'alert-danger', timeout: 3000 }
              );

              setTimeout(() => {
                this.authenticationService.logout();
              }, LOGOUT_TIMEOUT);
            }
          );
        }
      );
    }  else {
      this.flashMessagesService.grayOut(false);
      this.flashMessagesService.show(
        this.messagesPipe.transform(errorMessage),
        { cssClass: 'alert-danger', timeout: 3000 }
      );
    }
  }
}
