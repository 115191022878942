import {
  Component,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
  EventEmitter
} from '@angular/core';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { MyDatePicker } from '../../../shared/components/date_picker/datepicker.component';
import { BuildingWs } from '../../../core/models/building.ws';
import { UserLdapWs } from '../../../core/models/user.ldap.ws';
import { Headquarters } from '../../../core/models/headquarters';
import { RoomWs } from '../../../core/models/room.ws';
import { slideDetailsBooking } from '../../../shared/utils/reservation.list.animations';
import {
  ANIMATE_CLASS_ACTIVE,
  ANIMATE_CLASS_INACTIVE,
  LOGOUT_TIMEOUT
} from '../../../core/constants/consts';
import {
  STATUS_CONFLICT,
  STATUS_FORBIDDEN
} from '../../../core/constants/const';
import { MessagesPipe } from '../../../shared/pipes/messages.pipe';
import { Router } from '@angular/router';
import { ReservationWs } from '../../../core/models/reservation.ws';
import { RoomService } from '../../../shared/services/room.service';
import { FlashMessagesService } from '../../../shared/services/flash-messages.service';
import { BaseService } from '../../../shared/services/base.service';
import { Utils } from '../../../shared/utils/utils';

@Component({
  selector: 'reservationNewDetailByRoom',
  templateUrl: 'reservation.new.detail.room.html',
  styleUrls: ['new-reservation.scss'],
  animations: [slideDetailsBooking()]
})
export class ReservationNewDetailByRoom implements OnInit, OnChanges {
  @Input() showForm: boolean;
  @Input() buildings: BuildingWs[];
  @Output() closeFormEmmitter = new EventEmitter<any>();
  @ViewChild('datePickerElement', null) datePickerElement: MyDatePicker;
  @ViewChild('selectBuildings', null) selectBuildings: any;
  @ViewChild('selectRooms', null) selectRooms: any;
  datepickerOptions: any;
  reservationDate: Date;
  rooms: RoomWs[];
  buildingSelected: BuildingWs;
  roomSelected: RoomWs;
  user: UserLdapWs;
  userHeadquarter: Headquarters;

  constructor(
    private authenticationService: AuthenticationService,
    private utils: Utils,
    private roomService: RoomService,
    private flashMessagesService: FlashMessagesService,
    private baseService: BaseService,
    private router: Router
  ) {}

  ngOnInit() {
    this.datepickerOptions = this.utils.getDatepickerOptions(
      this.authenticationService.getMaxReservationDate()
    );

    /* Setting the User's headquarters */
    this.user = this.authenticationService.getCurrentUser();
    this.userHeadquarter = this.user.headquarters;
    this.buildingSelected = new BuildingWs(this.user.building.id, '');
    this.changeRooms(this.buildingSelected);
  }

  ngOnChanges() {
    let actualDate;
    let month;

    if (this.showForm) {
      actualDate = new Date();
      month = actualDate.getMonth() + 1;
      this.datepickerOptions = this.utils.getDatepickerOptions(
        this.authenticationService.getMaxReservationDate()
      );
      this.datePickerElement.clearDate();
      this.datePickerElement.selectDate({
        day: actualDate.getDate(),
        month: month,
        year: actualDate.getFullYear()
      });

      // this.selectBuildings.nativeElement.selectedIndex = 0;
      this.selectRooms.nativeElement.selectedIndex = 0;
      this.buildingSelected = null;
      this.roomSelected = null;
    }
  }

  onDateChanged($event) {
    if ($event.formatted.length > 0) {
      this.reservationDate = new Date();
      this.reservationDate.setFullYear(
        $event.date.year,
        $event.date.month - 1,
        $event.date.day
      );
    } else {
      this.reservationDate = null;
    }
  }

  buildingChanged($event) {
    this.roomSelected = null;
    let buildingId = parseInt($event.target.value);

    if (buildingId > 0) {
      this.buildingSelected = new BuildingWs($event.target.value, '');
      this.changeRooms(this.buildingSelected);
    } else {
      this.buildingSelected = null;
      this.rooms = [];
    }
  }

  roomChanged($event) {
    if ($event.target.value.length > 0) {
      this.roomSelected = new RoomWs('', '', '', '', '');
      this.roomSelected.id = $event.target.value;
    } else {
      this.roomSelected = null;
    }
  }

  changeRooms(building: BuildingWs) {
    let messagesPipe = new MessagesPipe();
    this.roomService
      .getRoomsByBuilding(building, this.authenticationService.getToken())
      .subscribe(
        rooms => {
          this.rooms = rooms;
        },
        error => {
          if (error.code == STATUS_FORBIDDEN) {
            this.authenticationService.refreshToken().subscribe(
              response => {
                this.changeRooms(building);
              },
              error => {
                this.authenticationService.validateSessionId().subscribe(
                  response => {
                    this.changeRooms(building);
                  },
                  error => {
                    this.flashMessagesService.grayOut(false);
                    this.flashMessagesService.show(
                      messagesPipe.transform('error_forbidden'),
                      { cssClass: 'alert-danger', timeout: 3000 }
                    );

                    setTimeout(() => {
                      this.authenticationService.logout();
                    }, LOGOUT_TIMEOUT);
                  }
                );
              }
            );
          } else {
            this.baseService.showErrorDialog(
              this.flashMessagesService,
              error,
              messagesPipe.transform('load_rooms_error')
            );
          }
        }
      );
  }

  checkReservations() {
    let reservation = new ReservationWs();
    reservation.dateFromInMillis = this.reservationDate.getTime();
    reservation.room = null;

    if (this.roomSelected) {
      reservation.room = this.roomSelected;
      this.router.navigate(['/menu/room/detail']);
    } else {
      this.router.navigate(['/menu/room/mosaic']);
    }

    /* We set the same headquarteers as the user because in this screen the user can search by a room
        under his headquarters. */
    reservation.headquarters = this.user.headquarters;
    localStorage.setItem(
      'reservationFilterNoTime',
      JSON.stringify(reservation)
    );
  }

  closeFormNewReserve() {
    this.showForm = false;
    this.closeFormEmmitter.emit();
  }

  getNewReservationAnimate(): string {
    if (this.showForm) {
      return ANIMATE_CLASS_ACTIVE;
    } else {
      return ANIMATE_CLASS_INACTIVE;
    }
  }
}
