import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import * as moment from 'moment';
import { MenuPermissions } from 'src/app/core/models/menu.permissions';
import { UserLdapWs } from 'src/app/core/models/user.ldap.ws';
import { DalkonService } from 'src/app/shared/services/dalkon.service';
import {
  ANIMATE_CLASS_ACTIVE,
  ANIMATE_CLASS_INACTIVE,
} from '../../../../core/constants/consts';
import { VehicleService } from '../../../../shared/services/vehicle.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { slideDetailsBooking } from '../../../utils/reservation.list.animations';

@Component({
  selector: 'app-calendar-edit',
  templateUrl: './calendar-edit.component.html',
  styleUrls: ['./calendar-edit.component.scss'],
  animations: [slideDetailsBooking()],
})
export class CalendarEditComponent implements OnInit, OnChanges {
  @Input() showForm: boolean;
  @Input() assistanceConfirm: boolean;
  @Input() assistancesList: any;
  @Input() assistanceSelected: any;
  @Input() mode: any;
  @Input() listUsersForDay: UserLdapWs;
  @Input() assistanceInfo: any;
  @Input() userSelect: any;
  @Input() mainVehicle: any;
  @Output() closeFormEmmitter = new EventEmitter<any>();
  @Output() confirmCancel = new EventEmitter<any>();
  @Output() confirmCancelPlace = new EventEmitter<any>();
  @Output() confirmCancelTurn = new EventEmitter<any>();

  permissions: MenuPermissions;
  user: UserLdapWs;
  shiftPriority: any;
  typeVehicle: any;
  showParkingSpotInfo: any;
  attendanceIntegrationParking: any;
  attendanceIntegrationDinnerDesk: any;

  constructor(
    private authenticationService: AuthenticationService,
    private dalkonService: DalkonService,
    private vehicleService: VehicleService
  ) {}

  ngOnInit() {
    this.getTypesVehicle();
    this.user = this.authenticationService.getCurrentUser();
    this.permissions =
      this.authenticationService.getPermissionsForCurrentUser();
    this.shiftPriority =
      this.user.dinnerShiftFrom && this.user.dinnerShiftTo
        ? moment(this.user.dinnerShiftFrom).format('HHmm') +
          '' +
          moment(this.user.dinnerShiftTo).format('HHmm')
        : null;

    this.showParkingSpotInfo = this.user.headquarters.showParkingSpotInfo;
    this.attendanceIntegrationParking =
      this.user.headquarters.attendanceIntegrationParking;
    this.attendanceIntegrationDinnerDesk =
      this.user.headquarters.attendanceIntegrationDinnerDesk;
  }

  ngOnChanges() {
    this.ngOnInit();
  }

  closeForm() {
    this.showForm = false;
    this.shiftPriority = null;
    this.closeFormEmmitter.emit();
    this.listUsersForDay = null;
  }

  getAnimations(): string {
    if (this.showForm) {
      return ANIMATE_CLASS_ACTIVE;
    } else {
      return ANIMATE_CLASS_INACTIVE;
    }
  }

  getTypesVehicle() {
    this.vehicleService
      .getTypesVehicle(this.authenticationService.getToken())
      .subscribe((res) => {
        if (res) {
          this.typeVehicle = res.length;
        }
      });
  }

  confirmCancelCalendar(assistanceInfo: any) {
    this.confirmCancel.emit(assistanceInfo);
  }

  confirmCancelCalendarPlace(assistanceInfo: any) {
    this.confirmCancelPlace.emit(assistanceInfo);
  }

  confirmCancelCalendarTurn(assistanceInfo: any) {
    this.confirmCancelTurn.emit(assistanceInfo);
  }
}
