import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  ANIMATE_CLASS_ACTIVE,
  ANIMATE_CLASS_INACTIVE,
} from '../../../core/constants/consts';
import { LOCAL_CURRENT_USER } from '../../../core/constants/const';
import { slideDetailsBooking } from '../../../shared/utils/reservation.list.animations';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AttendanceService } from 'src/app/shared/services/attendance.service';
import { finalize } from 'rxjs/operators';
import { FlashMessagesService } from 'src/app/shared/services/flash-messages.service';
import { MessagesPipe } from 'src/app/shared/pipes/messages.pipe';
import {
  STATUS_CONFLICT,
  STATUS_NOT_ACCEPTABLE,
} from '../../../core/constants/const';
import { LANGUAGE } from '../../../../environments/environment';
import { map } from 'rxjs-compat/operator/map';
import { UserService } from '../../../shared/services/user.service';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { UserLdapWs } from 'src/app/core/models/user.ldap.ws';
import { environment } from '../../../../environments/environment';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-modal-language',
  templateUrl: './modal-language.component.html',
  styleUrls: ['./modal-language.component.scss'],
  animations: [slideDetailsBooking()],
})
export class ModalLanguageComponent implements OnInit {
  @Input() showForm: boolean;
  @Input() user: UserLdapWs;
  @Output() closeFormEmmitter = new EventEmitter<any>();
  languageForm: FormGroup;
  messagesPipe = new MessagesPipe();
  mapLanguages = new Map();
  languageDefault: string;

  constructor(
    private attendanceService: AttendanceService,
    private flashMessagesService: FlashMessagesService,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private azureService: MsalService
  ) {}

  ngOnInit() {
    this.createForm();
    this.getLanguageDefault();
    this.getListLanguages();
  }

  createForm() {
    this.languageForm = new FormGroup({
      languages: new FormControl('', [Validators.required]),
    });
  }

  getAnimations(): string {
    if (this.showForm) {
      return ANIMATE_CLASS_ACTIVE;
    } else {
      return ANIMATE_CLASS_INACTIVE;
    }
  }

  closeForm() {
    this.languageForm = null;
    this.showForm = false;
    this.closeFormEmmitter.emit();
    this.createForm();
  }

  getLanguageDefault() {
    this.userService
      .getLanguage(this.authenticationService.getToken())
      .pipe(
        finalize(() => {
          // this.closeForm();
        })
      )
      .subscribe((response) => {
        if (response) {
          this.languageDefault = response.text;
        }
      });
  }

  getListLanguages() {
    let languages: any;
    if (this.portugueseLanguageDisabled()) {
      languages = LANGUAGE.filter(lang => lang !== 'pt');
    } else {
      languages = LANGUAGE;
    }

    languages.forEach((element) => {
      this.mapLanguages.set(
        element,
        this.messagesPipe.transform(`language_${element}`)
      );
    });
  }

  save() {
    const language = this.languageForm.controls['languages'].value;
    this.userService
      .updateLanguage(this.authenticationService.getToken(), language)
      .pipe(
        finalize(() => {
          this.getLanguageDefault();
          this.closeForm();
          this.logout();
        })
      )
      .subscribe((response) => {
        this.flashMessagesService.show(
          this.messagesPipe.transform('language_update_ok'),
          { cssClass: 'alert-success', timeout: 3000 }
        );
      });
  }

  portugueseLanguageDisabled(): boolean {
    return environment['portugueseLanguageDisabled'];
  }

  logout() {
    if (environment['use_azure']) {
      localStorage.removeItem(LOCAL_CURRENT_USER);
      this.azureService.logout();
    } else {
      this.authenticationService.logout();
    }
  }
}
