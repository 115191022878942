<div
  class="right-drawer new-reservation"
  [@slideDetailsBooking]="getAnimations()"
>
  <!-- Spinner -->
  <app-spinner></app-spinner>

  <!-- Close button -->
  <button
    type="button"
    class="vdp-icon-cancelar"
    (click)="closeReservationPositionForm()"
  ></button>

  <!-- Form title -->
  <span>{{ 'reservation_by_position_title' | messagesPipe }}</span>

  <form
    class="row mui-form"
    [formGroup]="reservationPositionForm"
    (ngSubmit)="checkPosition()"
    novalidate
  >
    <!-- Building -->
    <div class="select-by form-group select-group col-sm-12">
      <div class="mui-select">
        <label for="selectBuildings">{{
          'building' | messagesPipe | uppercase
        }}</label>
        <select
          #selectBuildings
          id="selectBuildings"
          name="selectBuildings"
          class="form-control"
          (change)="changeBuilding()"
          formControlName="idBuilding"
        >
          <option [value]="-1" selected>
            {{ 'select_building' | messagesPipe }}
          </option>
          <option *ngFor="let building of buildings" [value]="building.id">
            {{ building.name }}
          </option>
        </select>
      </div>
    </div>

    <!-- Floor -->
    <div class="select-by form-group select-group col-sm-12">
      <div class="mui-select">
        <label for="selectFloor">{{
          'floor' | messagesPipe | uppercase
        }}</label>
        <select
          #selectFloor
          id="selectFloor"
          name="selectFloor"
          class="form-control"
          formControlName="idFloor"
          (change)="changeFloor()"
        >
          <option [value]="-1">{{ 'select_floor' | messagesPipe }}</option>
          <option *ngFor="let floor of floors" [value]="floor.id">
            {{ floor.name }}
          </option>
        </select>
      </div>
    </div>

    <!-- Area -->
    <div class="select-by form-group select-group col-sm-12">
      <div class="mui-select">
        <label for="selectArea">{{ 'area' | messagesPipe | uppercase }}</label>
        <select
          #selectArea
          id="selectArea"
          name="selectArea"
          class="form-control"
          formControlName="idZona"
        >
          <option [value]="-1">{{ 'select_area' | messagesPipe }}</option>
          <option *ngFor="let area of areas" [value]="area.id">
            {{ area.name }}
          </option>
        </select>
      </div>
    </div>

    <!-- DatePicker -->
    <div class="form-group select-group col-sm-12 mui-textfield">
      <label for="fecha">{{ 'date' | messagesPipe }}</label>
      <datepicker
        class="custom-datepicker"
        #datePickerElement
        id="fecha"
        [options]="datepickerOptions"
        [locale]="locale"
        (dateChanged)="onDateChanged($event)"
      ></datepicker>
    </div>

    <div class="placed-button">
      <button
        type="submit"
        class="check-availability button-by"
        [disabled]="validForm() || (!reservationDate || !canBook)"
      >
        {{ 'reservation_by_position_btn' | messagesPipe }}
      </button>
    </div>
  </form>
</div>
