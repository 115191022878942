import { Message } from '../message';

export const MESSAGES_PT_LANG = 'pt';
export const MESSAGES_PT_PT_LANG = 'pt-PT';

export const MESSAGES_PT = [
  new Message('user', 'Utilizador'),
  new Message('password', 'Palavra passe'),
  new Message('email', 'Email'),
  new Message('welcome', 'Seu espaço para gerenciar seu'),
  new Message('welcome2', 'presença, recursos e serviços'),
  new Message('menu_init', 'Início'),
  new Message('menu_room', 'Reserva de sala'),
  new Message('menu_profile', 'Perfil'),
  new Message('menu_available_rooms', 'Salas disponiveis'),
  new Message('menu_available_rooms_speed', 'Salas Speed disponiveis'),
  new Message('menu_descargas', 'Download'),
  new Message('menu_incidence', 'Incidência'),
  new Message('menu_security', 'Segurança'),
  new Message('menu_job_position', 'Cargo'),
  new Message('menu_dinning_position', 'Reserva de restaurante'),
  new Message('menu_assistance', 'Assistência'),
  new Message('menu_assistance_user', 'Assistência ao usuário'),
  new Message('menu_cantine', 'Sala de jantar'),
  new Message('menu_logout', 'Sair'),
  new Message('error_getEquipement', 'Erro ao obter o equiment do sala'),
  new Message('error_getAllEquipement', 'Erro ao obter o equiment'),
  new Message('error_getRoomDetail', 'Erro ao obter detalhes do sala'),
  new Message('rooms_reserve', 'Salas reservadas'),
  new Message('rooms_disable_reservation_title', 'Quartos liberados'),
  new Message('rooms_disable_reservation_description', 'Liberamos as salas de reunião durante a crise do COVID-19'),
  new Message('rooms_reserve_empty', 'Não há sala reservada'),
  new Message(
    'rooms_waiting_get_error',
    'Erro ao obter reservas e lista de espera do serviço'
  ),
  new Message('waiting_list', 'Lista de espera'),
  new Message('express_reservation_btn', 'Reserva expresso {0}'),
  new Message('room_reserve_btn', 'Reservar sala'),
  new Message('room_speed_reserve', 'Reservar Sala Speed'),
  new Message('room_reserve_byRoom_btn', 'Pesquisa de sala '),
  new Message('room_reserve_byHQ', 'Reservar sala em outra sede'),
  new Message('room_available', 'Acessível'),
  new Message('room_available_btn', 'Reservar'),
  new Message('room_edit', 'Sua Reserva'),
  new Message('room_edit_btn', 'Editar'),
  new Message('room_busy', 'Ocupada'),
  new Message('room_busy_user', 'Ocupada por {0}'),
  new Message(
    'express_resevation_success',
    'Você recebeu a sala {0} para a programação {1} ({2} - {3}'
  ),
  new Message('incorrect_login', 'Login  malfeito'),
  new Message('azure_token_renewal_error', 'Falha ao entrar no SSO, feche o pop-up e tente novamente'),
  new Message('error_forbidden', 'Sessão expirada'),
  new Message('error_required', '* Campo requerido'),
  new Message('error_numeric_required', '* Campo numérico e obrigatório'),
  new Message('error_license_required', '* Registro e campo obrigatório'),
  new Message('btn_view_rooms', 'Ver salas disponíveis'),
  new Message('error_nextBooking', 'Erro ao obter a última reserva'),
  new Message('home_events_title', 'PRÓXIMOS EVENTOS'),
  new Message('cancel_reservation_btn', 'Cancelar'),
  new Message('reservation_cancelled', 'Reserva cancelada com sucesso'),
  new Message('reservation_cancelled_error', 'Erro ao cancelar reserva'),
  new Message(
    'confirm_dialog_err',
    'Erro ao mostrar confirmação de cancelamento'
  ),
  new Message(
    'reservation_delete_msg',
    'Tem certeza de que deseja cancelar a nova reserva?'
  ),
  new Message('reservation_delete_msg_title', 'Cancelamento de reserva'),
  new Message(
    'cancel_waiting_msg',
    'Tem certeza de que deseja cancelar a espera?'
  ),
  new Message('cancel_waiting_msg_title', 'Cancelar espera'),
  new Message('cancel_waiting_successfully', 'Espera cancelada com succeso'),
  new Message('cancel_waiting_error', 'Erro ao cancelar espera'),
  new Message(
    'cancel_waiting_dialog_error',
    'Erro ao mostrar confirmação de cancelamento'
  ),
  new Message('express_create_error', 'Erro ao criar uma reserva expresso'),
  new Message('error_dalkonParking', 'Erro ao obter estacionamento'),
  new Message('home_parking_title', 'ESTACIONAMENTO'),
  new Message('parking_ceded', 'Cedida'),
  new Message('parking_resident', 'quadrado fixo'),
  new Message('parking_lot', 'Lugar designado para hoje'),
  new Message('no_parking', 'Sem estacionamento'),
  new Message('no_vehicle', 'Sem veículo'),
  new Message('session_expired', 'Sessão expirada'),
  new Message('schedule', 'Horário'),
  new Message('issue', 'Motivo'),
  new Message('periodicity', 'Periodicidade'),
  new Message('periodicity_none', 'Nenhuma'),
  new Message('periodicity_daily', 'Diário'),
  new Message('periodicity_weekly', 'Semanal'),
  new Message('periodicity_max_date', 'Data máxima'),
  new Message('equipment', 'Equipamento'),
  new Message('time_str', 'De {0} até {1}'),
  new Message('date', 'Data'),
  new Message('assistants', 'Assistentes'),
  new Message('from', 'De'),
  new Message('since', 'Até'),
  new Message('meeting_reason', 'Motivo da reunião'),
  new Message('date_from_greater', 'A data De deve ser maior que a data atual'),
  new Message('date_to_greater', 'A data Até deve ser maior que a data De'),
  new Message(
    'date_from_greater_actual',
    'A data de não pode ser menor que a data atual'
  ),
  new Message('date_from_in_day', 'A duração da reserva deve ser no mesmo dia'),
  new Message('duraton_greater', 'A duração não pode exceder o dia atual'),
  new Message(
    'date_max_periodicity_greater',
    'A data máxima da periodicidade deve ser maior que a data da reserva'
  ),
  new Message('no_available_rooms_msg', 'Não há salas disponíveis'),
  new Message(
    'no_available_rooms_equipment_msg',
    'Com o equipamento que você precisa'
  ),
  new Message('rooms_without_equip', 'Salas sem equipamento'),
  new Message('available_rooms_list_error', 'Erro ao obter salas disponíveis'),
  new Message(
    'room_reserved_msg',
    'Sala {0} reservada: adicionado no dia {1} de {2} a {3}'
  ),
  new Message(
    'room_speed_reserved_msg',
    'Sala {0} reservada: adicionado no dia {1} de {2} com duração {3} min.'
  ),
  new Message('room_reserved_multiple_msg', 'Reservas feitas corretamente'),
  new Message('reserve_creation_error', 'Erro ao criar reserva'),
  new Message('room_to_waiting', 'A reserva foi adicionada à lista de espera'),
  new Message(
    'room_to_waiting_error',
    'Erro ao adicionar reserva à lista de espera'
  ),
  new Message('room_detail_name_title', 'Sala'),
  new Message('room_conference_code_title', 'ID'),
  new Message('waiting_msg', '{0} de {1} até {2}'),
  new Message('reservation_reason_placeholder', 'Escreva um motivo'),
  new Message('edit_reservation_msg', 'Reserva atualizada com sucesso'),
  new Message('edit_reservation_error', 'Erro na edição da reserva'),
  new Message('reservation_in_progress', 'Em progresso'),
  new Message('name', 'Nome'),
  new Message('building', 'Edifício'),
  new Message('no_building', 'Sem edifício'),
  new Message('select', 'Seleccionar..'),
  new Message('none', 'Nenhum'),
  new Message('not_available', 'Não disponível'),
  new Message('all_dept', 'Todos os departamentos'),
  new Message('all_user_dept', 'Todos os usuários do departamento: '),
  new Message('save_profile_btn', 'Atualizar perfil'),
  new Message('save_password_btn', 'Atualizar  Palavra Passe'),
  new Message('change_password_btn', 'Alterar Palavra Passe'),
  new Message('save_building_btn', 'Gravar edifício'),
  new Message('buildings', 'Edifícios'),
  new Message('save_building_successfully', 'Edifício gravado com sucesso'),
  new Message('save_building_error', 'Erro ao gravar edifício'),
  new Message('error_getBuildings', 'Erro ao obter lista de edifício'),
  new Message('send_reservation_mail_btn', 'Alarme'),
  new Message('send_reservation_mail_success', 'Reserva enviada corretamente'),
  new Message('send_reservation_mail_error', 'Erro a enviar a reserva'),
  new Message('ok', 'OK'),
  new Message('reservation_listView_date', '{0} de {1}, de {2} a {3}'),
  new Message(
    'periodicity_types_load_error',
    'Erro ao carregar tipos de periocidade'
  ),
  new Message(
    'periodicity_empty_rooms',
    'Existem salas ocupadas no período selecionado'
  ),
  new Message(
    'building_list_load_errror',
    'Erro ao carregar a lista de edifícios'
  ),
  new Message('show_all', 'Ver tudo'),
  new Message('profile_email_required', 'O email deve ser válido'),
  new Message('profile_building_selected', 'O edifício é obrigatório'),
  new Message('profile_password_required', 'A palavra passe é obrigatória'),
  new Message(
    'profile_newpassword_required',
    'A nova palavra passe é obrigatória'
  ),
  new Message(
    'profile_newpasswordrepeat_required',
    'Deve repetir a nova palavra passe'
  ),
  new Message(
    'profile_newpasswordrepeat_notSame',
    'As palavras passe não correspondem'
  ),
  new Message('getBuildings_error', 'Erro ao obter edifícios'),

    new Message('updateProfile_error', 'Erro ao atualizar perfil'),
    new Message('updateProfile_success', 'Perfil atualizado com sucesso'),
    new Message('updatePassword_success', 'Palavr passe atualizada corretamente'),
    new Message(
        'updatePassword_error',
        'Ocorreu um erro ao alterar a plavra passe'
    ),
    new Message('user_image', 'Imagem'),
    new Message('user_image_type_error', 'O arquivo deve ser uma imagem'),
    new Message(
        'user_emailOrName_empty',
        'Qualquer um dos seguintes campos está vazio: email, nome. Se o usuário não tiver um email, o email não será enviado ao fazer uma reserva'
    ),
    new Message('reservation_list_filter_msg', 'Filtrado por {0}'),
    new Message('login_username', 'Utilizador'),
    new Message('login_password', 'Palavra passe'),
    new Message('reset_password', 'Palavra passe'),
    new Message('reset_newpassword', 'Nova Palavra passe'),
    new Message('login_username_err', 'O campo do utilizador é obrigatório'),
    new Message('login_password_err', 'O campo da palavra passe é obrigatório'),
    new Message(
        'reset_password_msj',
        'Para solicitar uma nova palavra passe, digite'
    ),
    new Message('reset_password_err', 'Deve inserir um email válido'),
    new Message('error_login', 'Erro no login'),
    new Message(
        'error_reset',
        'Ocorreu um erro ao enviar o email. Entre em contato com o administrador.'
    ),
    new Message('dowloads_android_title', 'App SmartBuilding para Android'),
    new Message(
        'dowloads_android_desc',
        'deve autorizar a instalação em "Configurações", "Configurações avançadas", "Segurança" e ativar "Aplicativos de origem desconhecida"'
    ),
    new Message('dowloads_btn', 'Descarregar'),
    new Message('dowloads_ios_title', 'App SmartBuilding para iOS'),
    new Message(
        'dowloads_ios_desc',
        'Receberá um aviso de que o aplicativo é de um desenvolvedor não identificado; você deve aceitar a instalação de qualquer maneira (Confie no provedor de serviços)'
    ),
    new Message('dowloads_android_tower_title', 'App Tower para Android'),
    new Message('dowloads_ios_tower_title', 'App Tower para iOS'),
    new Message('modal_edit_reservation', 'Editar reserva'),
    new Message('modal_save_btn', 'Salvar'),
    new Message('building_select_lbl', 'Edifícios'),
    new Message('room_select_lbl', 'Salas'),
    new Message('select_building', 'Seleccione edifício'),
    new Message('select_room', 'Seleccione sala'),
    new Message('reserve_room', 'Reservar sala'),
    new Message('room_detail_error', 'Erro ao obter detalhes da sala'),
    new Message(
        'avaiableRooms_empty',
        'Não há salas disponíveis para a data selecionada'
    ),
    new Message('room_not_available', 'A sala não está disponível'),
    new Message('load_rooms_error', 'Erro ao carregar salas'),
    new Message('duration', 'duração'),
    new Message('room_detail_name_title', 'Sala'),
    new Message('headquarters_list', 'Sede'),
    new Message('headquarters', 'Campus'),
    new Message('getHeadquarters_error', 'Erro ao obter sede'),
    new Message(
        'userWithoutHeadquartersMustSelectOne',
        'Por favor, selecione uma sede para começar a usar a aplicaçao'
    ),
    new Message('login_access_btn', 'Login'),
    new Message('login_forgot_password', 'Esqueci minha palavra passe'),
    new Message(
        'login_problem_mail',
        'Se você tiver problemas para acessar o serviço, entre em contato conosco a partir '
    ),
    new Message('login_problem_here', 'daqui'),
    new Message(
        'resetPassword_message_ok',
        'Um email com uma nova palavra passe foi enviado a você.'
    ),
    new Message(
        'resetPassword_message_error',
        'Erro ao atualizar a senha'
    ),
    new Message('resetPassword_request_btn', 'Pedido'),
    new Message('resetPassword_return_btn', 'Volte'),
    new Message('parking_nextDraw_message', 'A próxima tarefa será'),
    new Message('parking_yieldToday_message', 'Ceda hoje'),
    new Message('parking_yield_message', 'Dar lugar'),
    new Message('parking_next_raffle', 'O próximo sorteio será no'),
    new Message('parking_notVehicle', 'Para registrar seu veículo, você pode fazê-lo em Seus veículos dentro de seu perfil.'),
    new Message('parking_raffle_info', 'Hoje você não tem estacionamento'),
    new Message('parking_raffle_info_not_available', 'Sorteio não disponível'),
    new Message('parking_enter_raffle', 'Digite o sorteio'),
    new Message(
        'parking_yieldTemporary_message',
        'Use a opção "Transferência temporária" para períodos de férias ou ausências. Você pode determinar até 3 períodos nos quais deseja transferir seu local.'
    ),
    new Message(
        'parkingOne_yieldTemporary_message',
        'Ceda agora para deixar seu lugar livre até o próximo sorteio. Use a opção "Transferência temporária" para períodos de férias ou ausências. '
    ),
    new Message(
        'parkingOne_checkedRaffle_title',
        'Você está participando do sorteio'
    ),
    new Message(
        'parkingOne_checkedRaffle_description',
        'Enquanto você mantiver essa opção ativa, todos os dias você entrará na loteria para vagas de estacionamento. Lembre-se de desativá-lo durante férias ou ausências. '
    ),
    new Message(
        'parkingOne_no_checkedRaffle_title',
        'Você não está participando do sorteio'
    ),
    new Message(
        'parkingOne_checkedRaffle_description_second',
        'Se você quiser saber como a loteria funciona, pressione'
    ),
    new Message(
        'parkingOne_raffle_end',
        'Erro, a oferta foi excluída ou desativada'
    ),
    new Message(
        'parking_without_assigned_parkingSpot',
        'Você não tem um lugar de estacionamento atribuído hoje'
    ),
    new Message(
        'parking_with_assigned_parkingSpot_today',
        'Seu lugar de estacionamento para hoje '
    ),
    new Message(
        'parking_yielded_parkingSpot',
        'Seu lugar de estacionamento é transferido até '
    ),
    new Message('parking_yielded_parkingRecover', 'Você vai recuperar seu lugar em '),
    new Message('parking_yielded_parkingConfirm', 'Você cancelou a transferência do seu lugar'),
    new Message('parking_assigned_parkingSpot', 'Você tem um lugar até '),
    new Message('parking_assigned_fixed_parkingSpot', 'Você tem um local fixo atribuído '),
    new Message('parking_assigned_temporary_parkingSpot', 'Você tem um local temporário atribuído até '),
    new Message('parking_request_seeDetails_btn', 'Ver detalhes'),
    new Message('parking_request_btn', 'Solicitar um lugar de estacionamento'),
    new Message(
        'parking_parkingSpot_absence',
        'Você pode transferir seu lugar de estacionamento em caso de ausência ou férias'
    ),
    new Message('parking_user_message', 'Lugar atribuído até o próximo sorteio'),
    new Message('parking_temporal_message', 'PLugar cedido temporariamente'),
    new Message(
        'parking_request_periods',
        'Para solicitar um lugar de estacionamento, selecione até 3 períodos'
    ),
    new Message('parking_yield_temporary', 'Transferência temporária'),
    new Message('parking_from_date', 'De'),
    new Message('parking_to_date', 'Até'),
    new Message('parking_disabled_reservation_title', 'Estacionamento liberado'),
    new Message('parking_disabled_reservation_description', 'Lançamos o estacionamento durante a crise do COVID-19'),

    new Message('send_invite_btn', 'Convidar'),
    new Message('send_invitation', 'Enviar convite'),
    new Message('user_to_send_invite', 'Pesquisar usuários…'),
    new Message('search_user', 'Pessoa de busca'),
    new Message(
        'search_users_to_send_invitation',
        'Escolha os usuários para os quais você deseja enviar o convite:'
    ),
    new Message('invitation_user_not_found', 'Nenhum usuário encontrado'),
    new Message('invitation_sent', 'O convite foi enviado com sucesso'),
    new Message(
        'invitations_users_search_error',
        'Ocorreu um problema ao procurar usuários, tente novamente.'
    ),
    new Message(
        'invitations_users_department_search_error',
        'Ocorreu um problema ao pesquisar por usuário ou departamento, tente novamente'
    ),
    new Message(
        'invitations_users_send_error',
        'Ocorreu um problema ao enviar o convite, tente novamente.'
    ),
    new Message('invitations_user_not_registered', 'Usuário não registrado'),
    new Message('reservation_pending_confirmation', 'Pendente de confirmação'),
    new Message(
        'error_validate_token',
        'Ocorreu um erro ao validar seu usuário. Você pode fazer login manualmente no seguinte link.'
    ),
    new Message(
        'room_reserve_byRoomAndPeriod_btn',
        'Pesquisa por quarto e período'
    ),
    new Message(
        'search_reservations_periods_by_room',
        'Encontre horários para um quarto'
    ),
    new Message('hours', 'Horas'),
    new Message('time_str_from', 'Desde as'),
    new Message('time_str_to', 'Até las'),
    new Message('floor', 'Plantar'),
    new Message('select_floor', 'Selecionar planta'),
    new Message('area', 'Espaço'),
    new Message('select_area', 'Selecionar espaço'),
    new Message('resource', 'Recurso'),
    new Message('select_resource', 'Selecionar recurso'),
    new Message('categorie', 'Categoria'),
    new Message('select_categorie', 'Selecione a categoria'),
    new Message('observation', 'Observações'),
    new Message('send_report', 'Enviar relatório'),
    new Message('title_incidence', 'Relatar incidente'),
    new Message('box_title', 'Selecione uma posição para reservar'),
    new Message('box_title_legend', 'Lenda'),
    new Message('box_free', 'Livre'),
    new Message('box_partially_occupied', 'Parcialmente ocupado'),
    new Message('box_occupied', 'Ocupado'),
    new Message('box_disabled', 'Desativado'),
    new Message('box_reserved', 'Reservado'),
    new Message('box_yourDesk', 'Sua mesa'),
    new Message('box_userSelectDesk', 'Posição do usuário selecionada'),
    new Message('box_position_available', 'Posições selecionadas'),
    new Message('reserveDesk_title', 'Reservar trabalho'),
    new Message('reserveDeskDinning_title', 'Reservar jantar'),
    new Message('reserveDesk_busy', 'Ocupado'),
    new Message('reserveDesk_toSelect', 'SELECCIONAR'),
    new Message('reserveDesk_selected', 'SELECCIONADO'),
    new Message('reserveDesk_cancel', 'CANCELAR'),
    new Message('reserveDesk_reserve', 'RESERVA'),
    new Message('reserveDesk_reserve_all', 'JORNADA COMPLETA'),
    new Message('reserveDesk_edit', 'EDITAR'),

    new Message('message_DeskNotSelected_Title', 'Não pode ser selecionado'),
    new Message('message_DeskConsecutive_Description', 'Apenas slots de tempo consecutivos podem ser reservados.'),
    new Message('message_DeskNot_Description', 'Não é possível selecionar mais intervalos de tempo.'),

    new Message('message_DeskNotSelected_Title--NotSelect', 'Não pode ser reservado'),
    new Message('message_DeskConsecutive_Description--NotSelect', 'Para fazer a reserva você deve selecionar pelo menos um horário.'),

  // Security
  new Message('security_search_title', 'Procurar'),
  new Message('security_search_select', 'Selecione uma opção'),
  new Message('security_search_name', 'Nome'),
  new Message('security_search_email', 'Endereço de correio'),
  new Message('security_search_code', 'Código local'),
  new Message('security_search_model', 'Modelo'),
  new Message('security_search_plate', 'Inscrição'),
  new Message('security_search_place', 'Número do local'),
  new Message('security_search_building', 'Construção'),
  new Message('security_search_floor', 'Chão'),
  new Message('security_search_btn', 'Olhe para'),
  new Message('security_search_value', 'Texto'),
  new Message('security_search_value_error', 'digite pelo menos 3 caracteres'),
  new Message('security_search_by_name', 'Não há resultados para esse nome'),
  new Message('security_search_by_plate', 'Não há resultados para essa inscrição'),
  new Message('security_search_by_place', 'Não há resultados para essa posição'),
  new Message('security_search_service_error', 'Ups! Ocorreu um erro inesperado'),
  new Message('security_search_results', 'Resultados'),
  new Message('security_search_places_available_error', 'Erro ao obter vagas disponíveis'),
  new Message('security_assign_place_btn', 'Atribuir lugar'),
  new Message('security_enrollment_value', 'inscrição'),
  new Message('security_search_assign_place_error', 'Erro de atribuição de lugar'),
  new Message('security_search_found_vehicle_error', 'Veículo não encontrado'),
  new Message('security_search_headquarters_vehicle_error', 'O veículo não pertence à sede'),
  new Message('security_search_assign_place_ok', 'Assento atribuído corretamente'),
  new Message('security_search_available_places', 'Lugares disponíveis'),
  new Message('security_search_result_list', 'Procure por lugares'),

    // Reservation by position
    new Message('reservation_by_position_title', 'Reservar uma posição'),
    new Message('reservation_by_position_btn', 'Reserva'),
    new Message('reserved_seats', 'Assentos reservados'),
    new Message('no_reserved_seats', 'Você não tem posições reservadas'),
    new Message('reservation_recover_desk_error', 'Erro ao recuperar assentos reservados'),
    new Message('reservation_desk_success', 'Seu assento foi reservado com sucesso.'),
    new Message('reservation_desk_error', 'Ocorreu um erro durante a reserva.'),
    new Message('reservation_checkin_success', 'Check-in realizado com sucesso.'),
    new Message('reservation_checkin_error', 'Não foi possível fazer o check-in.'),
    new Message('reservation_checkout_success', 'Check-out realizado com sucesso.'),
    new Message('reservation_checkout_error', 'Não foi possível fazer o check-out.'),
    new Message('reservation_not_available', 'Não foi possível reservar. Você já tem uma reserva de assento na mesma data.'),
    new Message('reservation_give', 'Cancelar'),
    new Message('reservation_edit', 'Editar'),
    new Message('reservation_checkin', 'Check-In'),
    new Message('reservation_checkout', 'Check-Out'),
    new Message('reservation_edit_success', 'Sua reserva foi modificada com sucesso.'),
    new Message('reservation_edit_error', 'Ocorreu um erro durante a modificação.'),
    new Message('reservation_cancel_success', 'Sua reserva foi cancelada com sucesso.'),
    new Message('reservation_cancel_error', 'Ocorreu um erro durante o processo.'),
    new Message('reservation_historical_title', 'Histórico'),
    new Message('reservation_historical_description', 'Você ainda não finalizou as reservas'),
    new Message('reservation_permissions', 'Você não pode fazer mais reservas neste período.'),
    new Message('reservation_load_more', 'Carregue mais'),
    new Message('reservation_share', 'Compartilhar'),
    new Message('reservation_add_people', 'Adicionar pessoas'),
    new Message('reservation_by', 'por '),
    new Message('reservation_busy_by', 'OCUPADO POR'),
    new Message('reservation_by_admin', 'Reservado por '),
    new Message('reservation_menu_user', 'Pesquisar posição do usuário'),
    new Message('reservation_menu_multiple', 'Reservas múltiplas'),
    new Message('reservation_menu_position', 'Posição de reserva'),
    new Message('reservation_user_position_date', 'Data'),
    new Message('reservation_user_position_see_reservations', 'Ver reservas'),
    new Message('reservation_multiple_tab1', 'Datos'),
    new Message('reservation_multiple_tab2', 'Pessoas'),
    new Message('reservation_multiple_number_of_positions', 'Posição nº'),
    new Message('reservation_multiple_number_of_hours', 'Horas'),
    new Message('reservation_multiple_number_of_minutes', 'Minutos'),
    new Message('reservation_multiple_start_date', 'Data de início'),
    new Message('reservation_multiple_end_date', 'Data final'),
    new Message('reservation_multiple_see_positions', 'Veja as vagas disponíveis'),
    new Message('reservation_share_success', 'Sua posição foi compartilhada com sucesso'),
    new Message('reservation_share_error', 'Erro, não foi possível compartilhar sua postagem'),
    new Message('reservation_no_available_positions', 'Não há vagas disponíveis para essa seleção'),
    new Message('reservation_available_positions_error', 'Erro ao obter assentos disponíveis'),
    new Message('reservation_multiple_desk_success', 'Sua reserva múltipla foi bem-sucedida'),
    new Message('reservation_search_user', 'Pessoa'),

    // Incidences
    new Message('incidence_file_type', 'Erro. O arquivo selecionado não é uma imagem'),
    new Message('user_image_optional', '(Opcional)'),
    new Message('no_incidences', 'Nenhum incidente foi criado'),
    new Message('user_image_select_file', 'Selecione o arquivo'),
    new Message('user_image_no_file', 'Nenhum arquivo selecionado'),
    new Message('user_image_file_charge', 'Clique para carregar o arquivo'),

    // Azure
    new Message('login_with_azure', 'Entrar como SSO'),
    new Message('login_with_azure_loading', 'Executando autenticação por meio de SSO. Por favor espere.'),
    new Message('login_with_azure_loading_error', 'Erro. Não está autorizado'),


    // Profile
    new Message('change_password_btn', 'Mudar senha'),
    new Message('my_vehicles_btn', 'Meus veículos'),
    new Message('update_password_ok', 'Senha atualizada corretamente'),
    new Message('update_password_fail', 'Erro, a senha introduzida não corresponde à sua senha'),
    new Message('update_password_error', 'Erro, não se ha podido atualizar la contraseña'),
    new Message('profile_vehicle_main', 'Diretor'),
    new Message('profile_vehicle_edit', 'Editar'),
    new Message('profile_vehicle_remove', 'Remover'),
    new Message('profile_principal_vehicle', 'Veículo primário corretamente atribuído'),
    new Message('profile_edit_vehicle', 'Veículo editado corretamente'),
    new Message('profile_edit_vehicle_error', 'Você deve selecionar outro veículo como principal anteriormente'),
    new Message('profile_vehicleEdit_type_title', 'TIPO DE VEÍCULO'),
    new Message('profile_vehicleEdit_type_select', 'Selecione o tipo'),
    new Message('profile_vehicleEdit_license_title', 'PLACA DO CARRO'),
    new Message('profile_vehicleEdit_license_placeholder', 'Insira a placa do carro'),
    new Message('profile_vehicleEdit_model_title', 'MODELO'),
    new Message('profile_vehicleEdit_model_placeholder', 'Apresentar o modelo'),
    new Message('profile_vehicleEdit_color_title', 'COR'),
    new Message('profile_vehicleEdit_color_placeholder', 'Insira a cor'),
    new Message('profile_vehicleEdit_main_title', 'VEÍCULO PRINCIPAL'),
    new Message('profile_vehicleEdit_add', 'Adicionar veículo'),
    new Message('profile_vehicleEdit_edit', 'Editar veículo'),
    new Message('profile_vehicleEdit_cancel', 'Cancelar'),
    new Message('profile_department', 'Departamento'),
    new Message('profile_my_reservations', 'Tornar minhas reservas públicas'),
    new Message('profile_departments_error', 'Falha ao obter departamentos de sua sede'),
    new Message('profile_assistance_parking', 'Solicitar vaga de estacionamento'),
    new Message('profile_assistance_parking_description', 'Reserve automaticamente uma vaga de estacionamento ao relatar a presença no escritório'),
    new Message('profile_assistance_no_vehicle_for_parking', 'Você deve registrar um veículo para solicitar uma vaga de estacionamento'),
    new Message('profile_assistance_dinner', 'Solicite uma mudança na sala de jantar'),
    new Message('profile_assistance_dinner_description', 'Reserve automaticamente um horário no restaurante ao relatar a presença no escritório. Se não houver horários disponíveis, selecione um novo'),
    new Message('support_preferences_btn', 'Preferências de suporte'),
    new Message('support_language_btn', 'Idioma'),
    new Message('profile_form_update_error', 'Falha na atualização do suporte'),
    new Message('profile_form_assistance_config', 'Configurações de atendimento'),
    new Message('profile_form_vehicle', 'Seus veículos'),
    new Message('profile_form_pass_mod', 'Modificar senha'),
    new Message('profile_form_vehicle_add', 'Veículo adicionado com sucesso'),
    new Message('profile_form_vehicle_delete', 'Veículo removido com sucesso'),
    new Message('profile_form_vehicle_delete_error', 'Falha ao remover veículo'),
    new Message('profile_error_image', 'Erro. O tamanho é maior que 5 MB'),
    new Message('profile_preferred_time_slot', 'PERÍODO DE TEMPO PREFERIDO'),

    // MODAL INDICATE YOUR DEPT
    new Message('indicate_your_dept_title', 'Indique seu departamento'),
    new Message('indicate_your_dept_desc',
        'Para utilizar o aplicativo, o usuário deve selecionar o departamento ao qual pertence.'),
    new Message('indicate_your_dept_bt_cancel', 'CANCELAR'),
    new Message('indicate_your_dept_bt', 'ACEITAR'),
    new Message('indicate_your_dept_error', 'Você deve selecionar um departamento'),

  // ASSISTANCE
  new Message('assistance_bottom_previous', 'Anterior'),
  new Message('assistance_bottom_next', 'Próximo'),
  new Message('assistance_bottom_save', 'SALVE'),
  new Message('assistance_bottom_solict', 'SOLICITAR'),
  new Message('assistance_bottom_cancel', 'CANCELAR'),
  new Message('assistance_bottom_cancel_assistance', 'Cancelar comparecimento'),
  new Message('assistance_text_waiting', '(Lista espera)'),
  new Message('assistance_text_confirm', 'Assistência confirmada'),
  new Message('assistance_text_confirmed', 'Participantes confirmados'),
  new Message('assistance_text_not_confirm', 'Confirmar assistência'),
  new Message('assistance_text_assign_parking_space', 'Você atribuiu uma vaga de estacionamento'),
  new Message('assistance_text_waiting_list', 'Voce esta na lista de espera'),
  new Message('assistance_text_space_available', 'Há espaço de estacionamento disponível'),
  new Message('assistance_text_not_space_available', 'Sem espaço de estacionamento disponível'),
  new Message('assistance_text_not_assign_dinner_space_hour_select', 'Não há turno livre na sala de jantar no horário selecionado'),
  new Message('assistance_text_not_assign_dinner_space', 'Não há turno livre na sala de jantar'),
  new Message('assistance_text_assign_dinner_space', 'Você tem uma reserva na sala de jantar'),
  new Message('assistance_text_not_request_dinner_space', 'Nenhum encontro será reservado na sala de jantar'),
  new Message('assistance_text_not_assign_parking', 'Você não tem uma vaga de estacionamento atribuída'),
  new Message('assistance_text_not_assign_parking_text_alert', 'Você tem que registrar um veículo em seu perfil'),
  new Message('assistance_text_not_parking_space_available', 'Não há vagas de estacionamento disponíveis'),
  new Message('assistance_text_not_assign_vehicle_parking', 'você não tem um lugar para {0}'),
  new Message('assistance_text_not_vehicle_assign', 'Você não tem um veículo cadastrado em seu perfil'),
  new Message('assistance_text_not_request_parking', 'A vaga de estacionamento não será reservada'),
  new Message('assistance_text_assign_parking', 'Você tem uma vaga de {0} reservada'),
  new Message('assistance_text_not_assign_dinner', 'Você não tem um turno reservado na sala de jantar'),
  new Message('assistance_text_assign_dinner', 'Você tem um turno reservado na sala de jantar às '),
  new Message('assistance_text_cancel_ok', 'Assistência cancelada com sucesso'),
  new Message('assistance_text_cancel_ko', 'Falha ao cancelar o assistência'),
  new Message('assistance_text_confirm_ok', 'Assistência confirmada com sucesso'),
  new Message('assistance_update_error', 'Falha ao confirmar presença'),
  new Message('assistance_text_liberate_parking_ok', 'Lugar de estacionamento liberada corretamente'),
  new Message('assistance_text_liberate_parking_ko', 'Erro ao liberar a lugar de estacionamento'),
  new Message('assistance_text_liberate_waiting_list_ok', 'Lista de espera cancelada com sucesso'),
  new Message('assistance_text_liberate_waiting_list_ko', 'Erro de cancelamento da lista de espera'),
  new Message('assistance_text_solict_parking_ok', 'Lugar atribuído corretamente'),
  new Message('assistance_text_solict_parking_ko', 'Erro de atribuição de lugar'),
  new Message('assistance_text_solict_dinner_ok', 'Posição de jantar atribuída corretamente'),
  new Message('assistance_text_solict_dinner_ko', 'Erro de atribuição de local de jantar'),
  new Message('assistance_text_cancel_dinner_ok', 'Posição de jantar cancelada com sucesso'),
  new Message('assistance_text_cancel_dinner_ko', 'Erro no cancelamento do refeitório'),
  new Message('assistance_text_check_daily_menu', 'VERIFICAR O MENU DO DIA'),
  new Message('assistance_text_available_shifts', 'TURNOS DISPONÍVEIS'),
  new Message('assistance_text_available_vehicles', 'TIPO DE VEÍCULO'),
  new Message('assistance_text_legend', 'Lenda'),
  new Message('assistance_text_reserve_error', 'Você excedeu o número máximo de reservas'),
  new Message('assistance_text_turn_selection_error', 'Você deve escolher uma curva'),
  new Message('assistance_text_not_dining_room_service', 'Não há serviço de refeitório para este dia'),
  new Message('assistance_text_legend_alert', 'Capacidade excedida. Revise seu planejamento de presença no escritório'),
  new Message('assistance_configuration_days_search_error', 'Ocorreu um erro ao pesquisar a configuração de dias'),
  new Message('assistance_search_error', 'Ocorreu um erro ao procurar assistências'),
  new Message('assistance_users_for_day_search_error', 'Ocorreu um erro na pesquisa do usuário'),
  new Message('assistance_info_search_error', 'Ocorreu um erro ao pesquisar informações de suporte'),
  new Message('assistance_candidates_search_error', 'Ocorreu um erro ao pesquisar por assistentes de candidatos'),
  new Message('assistance_user_manager_search_error', 'Ocorreu um erro ao pesquisar usuários'),
  new Message('assistance_select_user', 'Selecione o usuário: '),
  new Message('assistance_button_confirm', 'Confirme'),
  new Message('assistance_button_close', 'Desligar'),
  new Message('assistance_dialog_confirm_title', 'Cancelar Comparecimento'),
  new Message('assistance_dialog_confirm_text', 'Você quer cancelar a comparecimento? Os seguintes itens serão removidos: '),
  new Message('assistance_dialog_confirm_text_assistance', 'Assistência'),
  new Message('assistance_dialog_confirm_text_assign_place', 'Lugar designado'),
  new Message('assistance_dialog_confirm_text_restaurant_shift', 'Turno da sala de jantar'),

    // DINNING OCCUPATION
    new Message('occupation_text_free', 'Livre'),
    new Message('occupation_text_occupied', 'Ocupado'),
    new Message('occupation_text_occupancy_level', 'Nível de ocupação em tempo real'),
    new Message('occupation_text_last_update', 'Última atualização às '),

    // PARKING OCCUPATION
    new Message('parking_occupation_title', 'Estacionamento'),
    new Message('parking_occupation_free_places', 'Lugares livres'),
    new Message('parking_occupation_building_a', 'EDIFÍCIO A'),
    new Message('parking_occupation_building_b', 'EDIFÍCIO B'),
    new Message('parking_occupation_schedule', 'Perfil de agenda de ocupação'),
    new Message('parking_occupation_normal_parking', 'Estacionamento normal'),
    new Message('parking_occupation_electric_parking', 'Estacionamento elétrico'),
    new Message('parking_occupation_external_error', 'Falha do servidor Obix ao obter dados de ocupação'),

    // CANTINE
    new Message('cantine_text_error_data', 'Falha ao obter dados'),

    // MODAL LANGUAGE
    new Message('language_assign', 'Atribuir idioma'),
    new Message('language_text_available', 'Idiomas disponíveis'),
    new Message('language_es', 'Espanhol'),
    new Message('language_en', 'Inglês'),
    new Message('language_pt', 'Português'),
    new Message('language_update_ok', 'O idioma foi atualizado com sucesso'),
    
	// LOPD PUIG
    new Message('lopd_text_title', 'Política de privacidade'),
    new Message(
        'lopd_text_subtitle',
        'Bem-vindo ao nosso site / aplicativo <b> SmartBuilding </b> (o <b> “Site e / ou aplicativo” </b>).' +
        'Por favor, leia nossa Política de Privacidade com atenção.'
    ),
    new Message('lopd_text_our_commitment_title', 'Nossos compromissos'),
    new Message(
        'lopd_text_our_commitment_parraf_1',
        'Todos têm o direito de proteger seus dados pessoais. Respeitamos os direitos dos usuários ' +
        'ser informado sobre a coleta de seus Dados Pessoais e outras operações relacionadas a esses dados. ' +
        'Ao usar dados que podem identificá-lo direta ou indiretamente,' +
        ' Aplicaremos um princípio de estrita necessidade. Por este motivo, criamos o site e / ou aplicativo' +
        ' de forma que o uso de seus Dados Pessoais seja feito de forma mínima e não ultrapasse os objetivos ' +
        'para os quais seus Dados Pessoais foram coletados ou processados; não vamos tratar o mesmo quando podemos ' +
        'fornecer a você serviços por meio do uso de dados anônimos ou de tráfego, ' +
        'así como mediante otros medios que nos permitan identificarle, aparte de cuando sea estrictamente necesario ' +
        'ou a pedido das autoridades públicas competentes ou da polícia (por exemplo, ' +
        'no caso de dados de tráfego ou o endereço MAC / IP do seu dispositivo).'
    ),
    new Message(
        'lopd_text_our_commitment_parraf_2',
        'Para efeitos da Legislação de Proteção de Dados (conforme definido abaixo), ANTONIO PUIG S.A. ' +
        '(doravante, <b> “APSA” </b> ou a <b> “Empresa”) </b>, com sede na Plaça Europa, 46-48, 08902 Hospitalet de Llobregat ' +
        '(Barcelona), titular do Número de Identificação Fiscal B-64.797.129, inscrita no Registro Mercantil ' +
        'de Barcelona, ​​no tomo 40.696, folha 197, folha número B-371.911, o responsável pelo ' +
        'tratamento de seus dados pessoais. A Empresa compromete-se a tratar todas as informações prestadas ' +
        'online por um usuário como estritamente confidencial. '
    ),
    new Message(
        'lopd_text_our_commitment_parraf_3',
        'Esta Política de Privacidade refere-se às informações pessoais que identificam qualquer usuário ' +
        'ao acessar nosso Site e / ou Aplicativo, para usar seus serviços ou para preencher formulários em nosso ' +
        'Site e / ou Aplicativo com ou sem necessidade de compra de qualquer produto (doravante <b> "Dados Pessoais" </b>). ' +
        'Esta Política de Privacidade ajuda você a entender como a Empresa coleta e usa seus Dados Pessoais ' +
        'e para quais fins, e relaciona seus direitos em relação aos seus dados pessoais.'
    ),
    new Message(
        'lopd_text_our_commitment_parraf_4',
        'Ao usar este Site e / ou Aplicativo, você aceita as práticas descritas nesta Política de Privacidade. ' +
        'Se você não deseja aceitar as práticas descritas nesta Política de Privacidade, não use o Site e / ou Aplicativo.'
    ),
    new Message(
        'lopd_text_our_commitment_parraf_5',
        'Você deve notificar nosso Departamento de Recursos Humanos sobre qualquer alteração nos Dados Pessoais com o ' +
        'a fim de garantir que as informações contidas em nossos arquivos sejam, em todos os momentos, atualizadas e precisas. ' +
        'Você é responsável, em todos os casos, pela exatidão dos Dados Pessoais fornecidos, e nós nos reservamos ' +
        'o direito de suspender ou interromper a prestação dos serviços solicitados no caso de prestação ' +
        'Dados pessoais imprecisos, sem prejuízo de qualquer ação permitida por lei. '
    ),
    new Message('lopd_text_our_commitment_parraf_6',
        'Para qualquer problema ou dúvida relacionado a esta Política de Privacidade, você pode entrar em contato ' +
        'com nosso Departamento de Recursos Humanos: <b> hrpersonaldata@puig.com </b>'),
    new Message('lopd_text_applicable_legislation_title', 'Legislação aplicável'),
    new Message('lopd_text_applicable_legislation_parraf1',
        'Todos os Dados Pessoais enviados para a Empresa através do Site e / ou Aplicativo serão coletados ou ' +
        'processados ​​pela Empresa de acordo com a legislação de proteção de dados aplicável, incluindo o Regulamento ' +
        '2016/679 da UE em relação à proteção de pessoas singulares no que diz respeito ao processamento de dados ' +
        'pessoais e a livre circulação desses dados (Regulamento Geral de Proteção de Dados ou RGPD), ' +
        'sem prejuízo de quaisquer outros regulamentos nacionais em vigor ou que venham a ser promulgados no futuro ' +
        'sobre o assunto, bem como legislações locais obrigatórias como a Lei Orgânica 3/2018, de 5 de dezembro, ' +
        'Proteção de Dados Pessoais e garantia de direitos digitais (em conjunto, <b> "Legislação de Proteção ' +
        'de dados ”</b>).'),
    new Message('lopd_text_personal_data_title', 'Quem coleta e processa seus Dados Pessoais, como e com que finalidade? '),
    new Message('lopd_text_personal_data_parraf1',
        '<b> Dados pessoais que você nos fornece: </b> por meio dos formulários correspondentes no site ' +
        'e / ou Aplicativo, referidos Dados Pessoais (nome, sobrenome, e-mail profissional, registro do ' +
        'veículo particular, outros dados profissionais, etc.) podem ser processados ​​para fins relacionados ' +
        'com o controle de capacidade e acesso às áreas comuns, visando prevenir riscos que afetem ' +
        'à proteção de pessoas, instalações e bens patrimoniais administrados pela APSA ou qualquer empresa do ' +
        'O Grupo PUIG, em particular, o controlo dos acessos ao edifício, ao parque de estacionamento e à sala de jantar. ' +
        '(neste documento, <b> “Fins de segurança” </b>).'),
    new Message('lopd_text_personal_data_parraf2', 'A base jurídica para o processamento dos seus dados pessoais com base em ' +
        'do propósito descrito acima, baseia-se em nosso legítimo interesse de garantir a saúde e a segurança do ' +
        'pessoal, por força do mandato estabelecido na Lei 31/1995, de 8 de novembro, de Prevenção de Riscos Laborais.'),
    new Message('lopd_text_personal_data_parraf3',
        'Os motivos para a coleta de dados pessoais serão expressamente listados nas informações da declaração ' +
        'de privacidade que apresentaremos de tempos em tempos na seção do site e / ou aplicativo que você solicitar ' +
        'ao Usuário que fornece seus Dados Pessoais, em relação a Fins de Segurança.'),
    new Message('lopd_text_no_personal_data_title', 'O que acontece se você não nos fornecer seus dados pessoais?'),
    new Message('lopd_text_no_personal_data_parraf1',
        'A recusa em nos fornecer alguns de seus Dados pessoais necessários para realizar os fins acima pode, ' +
        'portanto, supõe-se a impossibilidade de se poder processar a reserva de acesso às instalações da APSA ' +
        'ou qualquer empresa do Grupo PUIG. '),
    new Message('lopd_text_no_personal_data_parraf2',
        'Divulgação de dados pessoais adicionais para nós que não sejam necessários para cumprir obrigações ' +
        'legal ou contratual é, ao contrário, opcional e não tem efeito sobre o uso do Site e / ou ' +
        'Aplicável. '),
    new Message('lopd_text_disclosure_personal_data_title', 'A quem os seus dados pessoais serão divulgados?'),
    new Message('lopd_text_disclosure_personal_data_parraf1', 'APSA contratou a prestação de determinados serviços ' +
        'aos fornecedores, que podem ter acesso e / ou processar dados pessoais na qualidade de gestores do ' +
        'tratamento (doravante, <b> “Processadores” </b>), em particular: '),
    new Message('lopd_text_disclosure_personal_data_parraf2', 'Provedores de hospedagem e manutenção de sites ' +
        'e / ou aplicativos e soluções de software corporativo que o requeiram;'),
    new Message('lopd_text_disclosure_personal_data_parraf3', 'Prestadores de serviços de atenção aos direitos das pessoas afetadas.'),
    new Message('lopd_text_disclosure_personal_data_parraf4', 'Em qualquer caso, seus dados pessoais não serão ' +
        'transferidos para países localizados fora do Espaço Econômico Europeu que não oferecem um nível adequado ou não ' +
        'fornecer garantias de proteção adequadas de acordo com as disposições da Legislação de Proteção de Dados.'),
    new Message('lopd_text_disclosure_personal_data_parraf5', 'Além disso, podemos comunicar seus dados para ' +
        'autoridades competentes quando legalmente contemplado. Além disso, seus dados pessoais podem ser divulgados a ' +
        'autoridades públicas (por exemplo, polícia ou judiciário) de acordo com as leis aplicáveis ​​e a pedido formal de ' +
        'ditas entidades.'),
    new Message('lopd_text_disclosure_personal_data_parraf6', 'Seus dados pessoais podem ser destinados ao ' +
        'empresas pertencentes ao Grupo PUIG, com sede em países da UE ou países fora da UE, ' +
        'para seus próprios fins de segurança.'),
    new Message('lopd_text_security_measures_title', 'Medidas de segurança e duração da conservação'),
    new Message('lopd_text_security_measures_parraf1', 'Adotamos medidas de segurança para proteger os Dados ' +
        'proteção pessoal contra destruição acidental ou ilegal, perda acidental, alteração, divulgação ou não acesso ' +
        'autorizado, e contra qualquer outro motivo de processamento de dados que não esteja em conformidade com a nossa Política de Privacidade.'),
    new Message('lopd_text_security_measures_parraf2', 'Para a melhor proteção possível de seus dados pessoais externos ' +
        'dos limites de nosso controle e gestão do mesmo, é aconselhável que seu aparelho tenha soluções de ' +
        'software que protege a transmissão / recepção de dados de rede (como sistemas antivírus atualizados) e que o seu ' +
        'o provedor de serviços de Internet toma medidas adequadas para a segurança da transmissão de dados de rede ' +
        '(como firewalls e filtragem de spam).'),
    new Message('lopd_text_security_measures_parraf3', 'Apenas reteremos seus dados pessoais enquanto ' +
        'necessárias ao cumprimento dos propósitos estabelecidos nesta Política de Privacidade. Quando não precisarmos mais tentar ' +
        'seus dados pessoais para os fins estabelecidos nesta Política de Privacidade ou tenha exercido o seu direito de ' +
        'exclusão, iremos excluir seus dados pessoais de nosso sistema.'),
    new Message('lopd_text_data_transfer_title', 'Transferência de seus dados pessoais para outros países'),
    new Message('lopd_text_data_transfer_parraf1', 'Os dados pessoais que coletamos de você são atualmente ' +
        'dentro da União Europeia (“UE”). '),
    new Message('lopd_text_data_transfer_parraf2', 'No entanto, é possível que, no futuro, tais Dados Pessoais ' +
        'são transferidos, armazenados ou processados ​​fora da UE ou do Espaço Econômico Europeu ("EEE"). Nesse caso, ' +
        'seus dados serão transferidos apenas para países localizados fora da União Europeia nos quais: (i) haja um ' +
        'decisão de adequação da Comissão Europeia; ou (ii) as garantias de proteção adequadas são fornecidas ' +
        '(ou seja, cláusulas de proteção de dados padrão adotadas pela Comissão Europeia ou uma autoridade de supervisão, ' +
        'Normas corporativas vinculativas, códigos de conduta, mecanismos de certificação ou cláusulas contratuais ad-hoc).'),
    new Message('lopd_text_right_personal_data_title', 'Seu direito em relação aos seus dados pessoais'),
    new Message('lopd_text_right_personal_data_parraf1', 'Abaixo está um resumo dos direitos ' +
        'disponíveis em relação aos seus dados pessoais. '),
    new Message('lopd_text_right_personal_data_parraf2', 'Para sua comodidade, e sem prejuízo de certas ' +
        'requisitos formais estabelecidos na Legislação de Proteção de Dados, você pode exercer qualquer um desses direitos ' +
        'entrando em contato conosco em <b> hrpersonaldata@puig.com </b>'),
    new Message('lopd_text_withdraw_consent_title', 'Direito de retirar seu consentimento:'),
    new Message('lopd_text_withdraw_consent_parraf1', 'A qualquer momento, você pode retirar o consentimento que deu ' +
        'à Empresa para processar seus Dados Pessoais. Observe, no entanto, que no caso de você retirar seu ' +
        'consentimento ou de outra forma objetar ao nosso processamento de seus dados pessoais, isso pode afetar o ' +
        'funcionalidade do nosso site e / ou aplicativo.'),
    new Message('lopd_access_personal_data_title', 'Direito de acessar seus dados pessoais em nossa posse:'),
    new Message('lopd_access_personal_data_parraf1', 'Você tem o direito de obter, a qualquer momento, confirmação ' +
        'se estamos tratando ou não seus Dados Pessoais e, quando apropriado, o acesso a esses Dados Pessoais. '),
    new Message('lopd_access_personal_data_parraf2', 'Além disso, você tem o direito de receber informações sobre a fonte de ' +
        'suas informações pessoais; as finalidades e a forma de tratar seus Dados Pessoais; a lógica envolvida em qualquer ' +
        'processamento eletrônico de dados; os detalhes do controlador de dados e os responsáveis ​​pelo ' +
        'tratamento de dados; os nomes das entidades e categorias de entidades para as quais seus dados podem ser divulgados ' +
        'dados pessoais ou quem pode acessar seus dados pessoais, por exemplo, como um controlador de dados ou ' +
        'uma parte assim designada. '),
    new Message('lopd_text_right_rectify_data_title', 'Direito de retificar dados pessoais imprecisos:'),
    new Message('lopd_text_right_rectify_data_parraf1', 'Você tem o direito de obter de nós, sem atrasos indevidos, o ' +
        'retificação de Dados Pessoais imprecisos que mantemos e que afetam você. Isso inclui o direito de solicitar ' +
        'Dados pessoais incompletos a serem preenchidos. '),
    new Message('lopd_text_right_to_erasure_title', 'Direito de exclusão:'),
    new Message('lopd_text_right_to_erasure_parraf1', 'Você tem o direito de obter a exclusão de nós sem demora ' +
        'dos Dados Pessoais que guardamos e que lhe dizem respeito, nas circunstâncias estipuladas por ' +
        'a Legislação de Proteção de Dados. '),
    new Message('lopd_text_right_restriction_title', 'Direito à restrição de tratamento:'),
    new Message('lopd_text_right_restriction_parraf1', 'Você tem o direito de restringir a forma como processamos seus dados ' +
        'pessoais nas circunstâncias estipuladas pela Legislação de Proteção de Dados. '),
    new Message('lopd_text_data_portability_right_title', 'Direito à portabilidade de dados:'),
    new Message('lopd_text_data_portability_right_parraf1', 'Você tem o direito de receber nossos dados pessoais ' +
        'relacionados a você que você nos forneceu, em um formato estruturado, comumente usado e legível por máquina, ' +
        'e o direito de transmitir esses dados a outro responsável pelo tratamento sem impedimentos de nossa parte. ' +
        'Este direito incluirá o direito de exigir que transmitamos os Dados Pessoais relevantes a outra pessoa responsável pelo ' +
        'tratamento em seu nome, quando tecnicamente viável. Este direito só se aplica a dados pessoais para ' +
        'aqueles que: (i) obtivemos seu consentimento para tratar, ou (ii) obtivemos o cumprimento de nossas obrigações ' +
        'contratual com você e, em cada caso, na medida em que processamos seus Dados Pessoais por meios automatizados.'),
    new Message('lopd_text_right_of_opposition_title', 'Direito de oposição:'),
    new Message('lopd_text_right_of_opposition_parraf1', 'Em certas circunstâncias e por razões relacionadas a ' +
        'sua situação particular, você pode se opor ao processamento de seus dados. APSA irá parar de processar os dados, ' +
        'Exceto por razões legítimas imperiosas ou para o exercício ou defesa de possíveis reivindicações.'),
    new Message('lopd_text_right_of_complaint_title', 'Direito de fazer uma reclamação sobre nós:'),
    new Message('lopd_text_right_of_complaint_parraf1', 'Você tem o direito de exercer seu direito de registrar uma reclamação ' +
        'perante uma autoridade de supervisão competente, em especial no Estado-Membro da sua residência habitual, local de ' +
        'trabalho ou local da alegada infração se você considerar que o processamento de seus dados pessoais viola o ' +
        'Legislação de proteção de dados.'),
    new Message('lopd_text_opt-out_title', 'Assinatura / Desativação'),
    new Message('lopd_text_opt-out_parraf1', 'Cada vez que o seu consentimento for necessário, a Empresa irá informá-lo ' +
        'com antecedência e lhe dará a opção de fornecer ou recusar seu consentimento para o uso de seus dados pessoais, ' +
        'incluindo o seu endereço de e-mail, marcando as caixas correspondentes.'),
    new Message('lopd_text_opt-out_parraf2', 'Queremos informar que podemos processar seus dados pessoais sem o seu ' +
        'consentimento em certas circunstâncias, como quando tal tratamento é necessário para realizar um ' +
        'obrigação legal a que estamos sujeitos ou quando tal tratamento é necessário para cumprir as obrigações ' +
        'com base em nosso legítimo interesse.'),
    new Message('lopd_text_contacts_title', 'Contatos'),
    new Message('lopd_text_contacts_parraf1', 'Você pode entrar em contato com a Empresa a qualquer momento, escrevendo para ' +
        'o seguinte endereço de e-mail <b> hrpersonaldata@puig.com </b> ' +
        'se você tiver alguma dúvida ou preocupação em relação a esta Política de Privacidade e seus dados pessoais fornecidos a ' +
        'da Empresa, ou se você deseja receber mais informações sobre como tratamos seus Dados Pessoais. '),
    new Message('lopd_text_privacy_policy_title', 'Alterações e atualizações a esta Política de Privacidade '),
    new Message('lopd_text_privacy_policy_parraf1', 'Podemos modificar ou simplesmente atualizar o todo ou parte ' +
        'desta Política de Privacidade, incluindo quando modificações são feitas em disposições legais ou regulamentares ' +
        'que regem a proteção de dados e protegem seus direitos. Alterações e atualizações da Política em ' +
        'A privacidade será vinculativa assim que forem publicados no Site e / ou Aplicativo nesta seção. ' +
        'Portanto, você será solicitado a acessar regularmente esta seção para verificar a publicação da Política ' +
        ' de privacidade mais recente e atualizada.'),
    new Message('lopd_text_privacy_policy_parraf2', 'Esta Política de Privacidade foi atualizada em abril de 2021.'),
    new Message('lopd_text_foot', '<b>© ANTONIO PUIG S.A., 2021. Todos os direitos reservados.</b>'),

    // ASSISTANCE TO THIRD PARTIES
    new Message('assistance_third_users_error', 'Falha ao obter usuários')
];
