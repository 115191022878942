<div class="main-container">
  <div class="fadeInLayer">
    <div class="profile">
      <form [formGroup]="profileForm" novalidate class="mui-form">
        <div class="row-profile">
          <div class="image-cropper" *ngIf="imageUrl && imageUrl.length > 0">
            <img [src]="imageUrl" alt="No Image" >
          </div>
          <label for="username">{{'user' | messagesPipe}}</label>
          <span id="username">{{user.username}}</span>
        </div>
        <div class="row-profile mui-textfield border-on">
          <label for="name">{{'name' | messagesPipe}}</label>
          <input id="name" type="text" name="name" [formControl]="profileForm.controls['name']" [readOnly]="!permissions.allowEditProfile || disabledPersonalData()">
          <span class="required" [hidden]="profileForm.controls.name.valid"  >{{'profile_name_required' | messagesPipe}}</span>
        </div>
        <div class="row-profile mui-textfield border-on">
          <label for="email">{{'email' | messagesPipe}}</label>
          <input id="email" type="text" name="email" [formControl]="profileForm.controls['email']" [readOnly]="!permissions.allowEditProfile || disabledPersonalData()">
          <span class="required" [hidden]="profileForm.controls.email.valid"  >{{'profile_email_required' | messagesPipe}}</span>
        </div>

        <!-- Seleccion de Sede -->
        <div class="row-profile mui-select">
          <label for="headquarters">{{'headquarters_list' | messagesPipe}}</label>
          <select id="headquarters" name="headquarters" formControlName="headquarters" 
                  (change)="headquartersChanged($event)">
            <option [value]="0" [selected]="profileForm.controls['headquarters'].value == 0">{{'select' | messagesPipe}}</option>
            <option [value]="headquarter.id" *ngFor="let headquarter of headquarters"
                    [selected]="profileForm.controls['headquarters'].value == headquarter.id">{{headquarter.name}}</option>
          </select>
          <span class="required" [hidden]="profileForm.controls.headquarters.valid || profileForm.controls.headquarters.disabled">{{'error_required' | messagesPipe}}</span>
        </div>

        <div class="row-profile mui-select">
          <label for="buildings">{{'buildings' | messagesPipe}}</label>
          <select id="buildings" name="buildings" formControlName="building">
            <option [value]="0" [selected]="profileForm.controls['building'].value == 0">{{'select' | messagesPipe}}</option>
            <option [value]="building.id" *ngFor="let building of buildings"
                    [selected]="profileForm.controls['building'].value == building.id">{{building.name}}</option>
          </select>
        </div>

        <!-- departments -->
        <div class="row-profile mui-select">
          <label for="department">{{'profile_department' | messagesPipe}}</label>
          <select id="department" name="department" formControlName="department">
            <option [value]="0" [selected]="profileForm.controls['department'].value == 0">{{'select' | messagesPipe}}</option>
            <option [value]="department.id" *ngFor="let department of departments"
                    [selected]="profileForm.controls['department'].value == department.id">{{department.name}}</option>
          </select>
        </div>

        <!-- My reservations -->
        <div class="row-profile" *ngIf="permissions.allowHotDesktop">
          <span class="checkbox">
            <input type="checkbox" id="myReservations" formControlName="myReservations" />
            <label for="myReservations">{{'profile_my_reservations' | messagesPipe}}</label>
          </span>
        </div>
        
        <!-- Image -->
        <div class="upload-btn-wrapper">
          <label>{{'user_image' | messagesPipe}}</label>
          <button class="btnr" title="{{'user_image_file_charge' | messagesPipe}}">{{'user_image_select_file' | messagesPipe}}</button>
          <span>{{fileName ? fileName : 'user_image_no_file' | messagesPipe}}</span>
          <input type="file" id="imageFile" (change)="fileChageEvent($event)" accept="image/*" title="{{'user_image_file_charge' | messagesPipe}}" />
        </div>
        <div class="row-profile">
          <div class="place-elements">
            <button *ngIf="permissions.allowEditProfile" (click)="updateProfile()" [disabled]="profileForm.invalid">{{'save_profile_btn' | messagesPipe}}</button>
            <button *ngIf="!permissions.allowLdap && !disabledPassAndVehicle() && !disabledPass" (click)="openModalForm('updatePassword')">{{ 'change_password_btn' | messagesPipe}}</button>
            <button *ngIf="permissions.allowEditVehicles && !disabledPassAndVehicle()" (click)="openModalForm('modifyVehicle')">{{ 'my_vehicles_btn' | messagesPipe}}</button>
            <button *ngIf="permissions.allowAssistance" (click)="openModalForm('supportAssistance')">{{ 'support_preferences_btn' | messagesPipe}}</button>
            <button (click)="openModalLanguage()">{{ 'support_language_btn' | messagesPipe}}</button>
          </div>
        </div>
      </form>
    </div>

    <div *ngIf="userWithoutHeadquarters" class="modal fade" role="dialog">
        <div class="modal-dialog modal-sm">
            <div class="modal-content">
              <div class="modal-info">
                <span class="message">{{ 'userWithoutHeadquartersMustSelectOne' | messagesPipe }}</span>
              </div>
              <div class="modal-footer">
                  <button type="button" class="btn btn-default" (click)="closeUserWithoutHeadquartersMessage()">{{'ok' | messagesPipe}}</button>
              </div>
            </div>
        </div>
    </div>
    <app-profile-form (closeFormEmmitter)="closeModalForm($event)"></app-profile-form>
    <app-modal-language
      (closeFormEmmitter)="closeModalLanguage()"
      [showForm]="showModalLanguage"
      [user]="user"
    ></app-modal-language>

  </div>
</div>
