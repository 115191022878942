<div
  class="reservation-detail-layer"
  [@slideDetailsBooking]="getDetailReservationAnimateOpacity()"
>
  <div
    class="reservation-detail"
    [@slideDetailsBooking]="getDetailReservationAnimate()"
    [ngClass]="{
      differentBuilding:
        reservationWs.room.floor.building.headquarters?.id !=
        this.currentUser.headquarters?.id
    }"
  >
    <div class="image">
      <div class="image-wrapper">
        <button type="button" (click)="closeDetail()">
          <i class="vdp-close-lateral-foto"></i>
        </button>
        <img
          [src]="reservationWs.room.imageUrl"
          *ngIf="reservationWs.room.imageUrl.length > 0"
        />
      </div>
      <div class="image-info">
        <div class="name">
          <span class="headquarter">
            {{ reservationWs.room.floor.building.headquarters?.name }}
          </span>
          <span class="room-detail">{{
            'room_detail_name_title' | messagesPipe
          }}</span>
          <span class="meeting-room">{{ reservationWs.room.name }}</span>
          <span class="code" *ngIf="reservationWs.room.conferenceCode != null"
            >{{ 'room_conference_code_title' | messagesPipe }}
            {{ reservationWs.room.conferenceCode }}</span
          >
          <span class="building">{{
            reservationWs.room.floor.building.name
          }}</span>
          <div class="assistants">
            <i class="vdp-label-asistentes"></i
            ><span>{{ getAssistants() }}</span>
          </div>
        </div>
        <i
          *ngIf="reservationWs.room.hasIncidence"
          class="vdp-salas-incidencia"
        ></i>
      </div>
    </div>
    <div class="info clearfix">
      <div class="time">
        <span class="title">{{ 'schedule' | messagesPipe }}</span>
        <span id="horario">{{ getTime() }}</span>
      </div>
      <div class="reason">
        <span class="title">{{ 'issue' | messagesPipe }}</span>
        <span id="asunto">{{ reservationWs.reason }}</span>
      </div>
      <div class="equipment">
        <span class="title">{{ 'equipment' | messagesPipe }}</span>
        <div
          class="col-xs-12"
          *ngFor="let equipment of reservationWs.room.equipment"
        >
          <div class="equipment-item">
            <img [src]="equipment.imageUrl" height="50px" width="50px" />
            <span>{{ equipment.name }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="calendar">
      <ul>
        <li *ngFor="let hour of hours">{{ hour }}</li>
      </ul>
      <div
        class="busy"
        *ngFor="let reservation of reservationWs.room.reservations"
        [ngClass]="
          getClassScheduleDiv(reservation.dateFrom, reservation.dateTo)
        "
      >
        <div class="message">
          <span *ngIf="reservation.userTo != null">{{
            'room_busy_user' | messagesPipe: [reservation.userTo.userName]
          }}</span>
          <span *ngIf="reservation.userTo == null">{{
            'room_busy' | messagesPipe
          }}</span>
        </div>
      </div>
      <div [ngClass]="getClassScheduleDiv(dateFrom, dateTo)">
        <div class="message">
          <span>{{ 'room_edit' | messagesPipe }}</span>
          <button
            class="btn"
            (click)="editReservation()"
            [disabled]="showEditModal"
          >
            {{ 'room_edit_btn' | messagesPipe }}
          </button>
        </div>
      </div>
      <div class="card" [ngClass]="getClassLaunchTime()">
        <i class="vdp-salas-lunch"></i>
      </div>
    </div>
    <div class="cancel" *ngIf="!getIsChecked()">
      <button type="button" (click)="cancelReservation()">
        {{ 'cancel_reservation_btn' | messagesPipe }}
        <i class="vdp-icon-cancelar"></i>
      </button>
    </div>
  </div>
</div>

<div [hidden]="!showEditModal">
  <div class="modal fade editmodal" role="dialog">
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-info clearfix">
          <button
            type="button"
            class="vdp-icon-cancelar"
            (click)="cancelEdit()"
          ></button>
          <label class="title" for="roomModalName">{{
            'modal_edit_reservation' | messagesPipe
          }}</label>
          <span class="roomName" id="roomModalName">{{
            getEditModalRoomName()
          }}</span>
          <div class="form-group select-group col-sm-6">
            <div class="mui-select">
              <label for="hourFrom">{{ 'from' | messagesPipe }}</label>
              <select
                #selectHourFrom
                class="form-control"
                id="hourFrom"
                name="hourFrom"
                (change)="onChangeHourFrom($event)"
              >
                <option *ngFor="let hourFrom of hoursFrom" [value]="hourFrom">{{
                  hourFrom
                }}</option>
              </select>
            </div>
            <span>:</span>
            <div class="mui-select">
              <select
                #selectMinuteFrom
                class="form-control"
                id="minuteFrom"
                name="minuteFrom"
                (change)="onChangeMinuteFrom($event)"
              >
                <option
                  *ngFor="let minuteFrom of minutesFrom"
                  [value]="minuteFrom"
                  >{{ minuteFrom }}</option
                >
              </select>
            </div>
          </div>
          <div class="form-group select-group col-sm-6">
            <div class="mui-select">
              <label for="hourTo">{{ 'since' | messagesPipe }}</label>
              <label for="hourTo">{{ 'since' | messagesPipe }}</label>
              <select
                #selectHourTo
                class="form-control"
                id="hourTo"
                name="hourTo"
                (change)="onChangeHourTo($event)"
              >
                <option *ngFor="let hourTo of hoursTo" [value]="hourTo">{{
                  hourTo
                }}</option>
              </select>
            </div>
            <span>:</span>
            <div class="mui-select">
              <select
                #selectMinuteTo
                class="form-control"
                id="minuteTo"
                name="minuteTo"
                (change)="onChangeMinuteTo($event)"
              >
                <option *ngFor="let minuteTo of minutesTo" [value]="minuteTo">{{
                  minuteTo
                }}</option>
              </select>
            </div>
          </div>
          <span class="required" [hidden]="!errorDatesInterval">{{
            editReservationDateError
          }}</span>
          <div class="last-textfield form-group col-sm-12 mui-textfield">
            <label for="reason">{{ 'meeting_reason' | messagesPipe }}</label>
            <textarea
              placeholder="{{
                'reservation_reason_placeholder' | messagesPipe
              }}"
              id="reason"
              name="reason"
              class="form-control"
              rows="3"
              [(ngModel)]="reason"
            ></textarea>
            <span class="required" [hidden]="reason.trim().length > 0">{{
              'error_required' | messagesPipe
            }}</span>
          </div>
          <button
            class="reservation-button"
            type="button"
            (click)="saveEditReservation()"
            [disabled]="disableSaveEditBtn || reason.trim().length <= 0"
          >
            {{ 'modal_save_btn' | messagesPipe }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
