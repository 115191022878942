<nav [ngClass]="{ collapsed: navCollapsed, sticky: navSticky }">
  <div class="logo">
    <a [routerLink]="['/menu/home']">
      <img src="../../assets/img/logo-menu.png" alt="" />
    </a>
  </div>
  <ul>
    <li (click)="setNavSticky()">
      <a [routerLink]="['/menu/home']" [routerLinkActive]="['active']"
        ><i class="vdp-navbar-salas"></i
        ><span>{{ "menu_init" | messagesPipe }}</span></a
      >
    </li>
    <li *ngIf="permissions.allowReservation && !hiddenRoomReservation" (click)="setNavSticky()">
      <a [routerLink]="['/menu/reservations']" [routerLinkActive]="['active']"
        ><i class="vdp-navbar-salas-activo"></i
        ><span>{{ "menu_room" | messagesPipe }}</span></a
      >
    </li>
    <li *ngIf="permissions.allowParking && permissions.parkingType == 2 && !hiddenParking" (click)="setNavSticky()">
      <a [routerLink]="['/menu/parking']" [routerLinkActive]="['active']"
        ><i class="vdp-navbar-parking-activo"></i
        ><span>{{ "menu_parking" | messagesPipe }}</span></a
      >
    </li>
    <li *ngIf="permissions.allowParking && permissions.parkingType == 1 && !hiddenParking" (click)="setNavSticky()">
      <a [routerLink]="['/menu/parkingOne']" [routerLinkActive]="['active']"
        ><i class="vdp-navbar-parking-activo"></i
        ><span>{{ "menu_parking" | messagesPipe }}</span></a
      >
    </li>
    <li *ngIf="permissions.allowIncidence && !hiddenIncidences" (click)="setNavSticky()">
      <a [routerLink]="['/menu/incidence']" [routerLinkActive]="['active']"
        ><i class="vdp-warning"></i
        ><span>{{ "menu_incidence" | messagesPipe }}</span></a
      >
    </li>

     <li *ngIf="permissions.allowSecurity" (click)="setNavSticky()">
      <a [routerLink]="['/menu/security']" [routerLinkActive]="['active']"
        ><i class="vdp-icon_password"></i
        ><span>{{ 'menu_security' | messagesPipe }}</span></a
      >
    </li>
    <li *ngIf="permissions.allowHotDesktop && !hiddenHotDesktop" (click)="setNavSticky()">
      <a [routerLink]="['/menu/job-position']" [routerLinkActive]="['active']"
        ><i class="vdp-puesto_trabajo"></i
        ><span>{{ 'menu_job_position' | messagesPipe }}</span></a
      >
    </li>

    <li *ngIf="permissions.allowDinnerDesktop && !hiddenDinnerDesk" (click)="setNavSticky()">
      <a [routerLink]="['/menu/dinning-position']" [routerLinkActive]="['active']"
        ><i class="vdp-puestos-restaurante"></i
        ><span>{{ 'menu_dinning_position' | messagesPipe }}</span></a
      >
    </li>

    <li *ngIf="permissions.allowAssistance" (click)="setNavSticky()">
      <a [routerLink]="['/menu/assistance']" [routerLinkActive]="['active']"
        ><i class="vdp-icon-reserva-sala"></i
        ><span>{{ 'menu_assistance' | messagesPipe }}</span></a
      >
    </li>

    <li *ngIf="permissions.allowAssistance && user.isManager > 0" (click)="setNavSticky()">
      <a [routerLink]="['/menu/assistance-user']" [routerLinkActive]="['active']"
        ><i class="vdp-icon-reserva-sala"></i
        ><span>{{ 'menu_assistance_user' | messagesPipe }}</span></a
      >
    </li>

    <li *ngIf="permissions.allowCantine" (click)="setNavSticky()">
      <a [routerLink]="['/menu/cantine']" [routerLinkActive]="['active']"
        ><i class="vdp-config_restaurant"></i
        ><span>{{ 'menu_cantine' | messagesPipe }}</span></a
      >
    </li>

    <li (click)="setNavSticky()">
      <a [routerLink]="['/menu/profile']" [routerLinkActive]="['active']"
        ><i class="vdp-navbar-perfil-activo"></i
        ><span>{{ "menu_profile" | messagesPipe }}</span></a
      >
    </li>

    <li (click)="setNavSticky()" *ngIf="disableDownload">
      <a [routerLink]="['/menu/descargas']" [routerLinkActive]="['active']"
        ><i class="vdp-navbar-descargar-activo"></i
        ><span>{{ "menu_descargas" | messagesPipe }}</span></a
      >
    </li>

    <li class="logout" (click)="logout()">
      <a href="#"
        ><i class="vdp-logout"></i
        ><span>{{ "menu_logout" | messagesPipe }}</span></a
      >
    </li>
  </ul>

  <div class="foot" *ngIf="enableLopd">
    <a [routerLink]="routerLinkLopd" [routerLinkActive]="['active']">
    <span>{{ 'lopd_text_title' | messagesPipe }}</span></a
  >
  </div>

  <div
    id="nav-icon3"
    (click)="setNavSticky()"
    [ngClass]="{ sticky: navSticky }"
  >
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </div>
</nav>
<header [ngClass]="{ collapsed: navCollapsed }">
  <button
    [ngClass]="{ collapsed: navCollapsed }"
    (click)="setNavCollapsed()"
    type="button"
    name="button"
    class="vdp-plegar-menu"
  ></button>
  <div class="pull-left" >
    <div class="pull-left-with-icon" (click)="goBack()" *ngIf="showIconBack()">
      <i class="vdp-go-back"></i>
    </div>
    <span>{{ linkName }}</span>
  </div>
  <div class="pull-right">
    <div class="user" (click)="showLogout()">
      <span>{{ userName }}</span>
      <div
        class="image-cropper"
        *ngIf="user.image != null && user.image.length > 0"
      >
        <img [src]="user.image" />
      </div>
      <div
        [@slideButtons]="getSlideButtonsClass()"
        (click)="logout()"
        id="logout"
        class="logout-message"
      >
        <i class="vdp-logout"></i>
        <span>{{ "menu_logout" | messagesPipe }}</span>
      </div>
    </div>
  </div>
</header>
<div [ngClass]="{ collapsed: navCollapsed }">
  <router-outlet></router-outlet>
</div>
