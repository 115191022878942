import { Headers, RequestOptions } from "@angular/http";

export const OPTIONS = new RequestOptions({
  headers: new Headers({ "Content-Type": "application/json" })
});

export const STATUS_CONFLICT = 409;
export const STATUS_FORBIDDEN = 401;
export const STATUS_SERVER_ERROR = 500;
export const STATUS_INCORRECT_DATA = 400;
export const STATUS_POSITION_OCCUPIED = 423;
export const STATUS_ERROR_EXTERNAL = 503;
export const STATUS_NOT_ACCEPTABLE = 406;
export const LOCAL_CURRENT_USER = "currentUser";
export const ANALYTIC_RESERVATION_CATEGORY = "Reservation";
export const ANALYTIC_RESERVATION_LABEL = "Reservation";
export const ANALYTIC_RESERVATION_EQUIPEMENT_LABEL =
  "Reservation With Equipment";
export const ANALYTIC_EXPRESS_RESERVATION_LABEL = "Express Reservation";
export const ANALYTIC_RESERVATION_CODE = 1;
export const ANALYTIC_RESERVATION_EQUIPEMENT_CODE = 2;
export const ANALYTIC_EXPRESS_RESERVATION_CODE = 3;
export const ALLOWHQ = "allowHeadquarters";

export const DEFAULT_PARKING_DATA_API_PATH = "api/parking/obtenerDatos";
export const DALKON_PARKING_DATA_API_PATH = "api/dalkon/obtainData";

export const DEFAULT_FLOOR_DATA_API_PATH = '/api/floors/listAll/';
export const VODAFONE_FLOOR_DATA_API_PATH = '/api/floor/building/';

export const DEFAULT_AREA_DATA_API_PATH = '/api/areas/listAll/';
export const VODAFONE_AREA_DATA_API_PATH = '/api/areas/floor/';

export const DEFAULT_AREA_DINNER_DATA_API_PATH = '/api/areas/dinner/floor/';
export const DEFAULT_AREA_HOTDESK_DATA_API_PATH = '/api/areas/hotdesk/floor/';
export const VODAFONE_AREA_DINNER_DATA_API_PATH = '/api/areas/dinner/floor/';
export const VODAFONE_AREA_HOTDESK_DATA_API_PATH = '/api/areas/hotdesk/floor/';

export const DEFAULT_HOLIDAY_DATA_API_PATH  = '/api/holidays/headquarter/';

export const DEFAULT_LANGUAGE = ['es', 'en', 'pt'];

export const APP_VERSION = 'APP_VERSION';