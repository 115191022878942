<div
  class="right-drawer multiple-reservation"
  [@slideDetailsBooking]="getAnimations()"
>
  <!-- Close button -->
  <button
    type="button"
    class="vdp-icon-cancelar"
    (click)="closeForm()"
  ></button>

  <!-- Form title -->
  <span>{{ 'reservation_menu_multiple' | messagesPipe }}</span>

  <!-- Tabs -->
  <div class="tab">
    <button #defaultOpen class="tablinks" (click)="manageTabs($event, 'tab1')">
      {{ 'reservation_multiple_tab1' | messagesPipe }}
    </button>
    <button class="tablinks" (click)="manageTabs($event, 'tab2')">
      {{ 'reservation_multiple_tab2' | messagesPipe }}
    </button>
  </div>

  <!-- Content 1 -->
  <div id="tab1" class="tabcontent">
    <form
      class="row mui-form"
      (ngSubmit)="seeAvailablePositions()"
      [formGroup]="multipleReservationForm"
      novalidate
    >
      <!-- Number of positions -->
      <div class="form-group col-sm-6 mui-textfield">
        <label for="assistants">{{
          'reservation_multiple_number_of_positions' | messagesPipe
        }}</label>
        <input
          type="text"
          placeholder="2"
          class="form-control"
          formControlName="numberOfpositions"
        />
        <span
          class="required"
          [hidden]="multipleReservationForm.controls.numberOfpositions.valid"
          >{{ 'error_numeric_required' | messagesPipe }}</span
        >
      </div>

      <!-- Hours -->
      <div class="select-by form-group select-group col-sm-6">
        <div class="mui-select">
          <label for="selectNumberOfHours">{{ !isDinner ? ('reservation_multiple_number_of_hours' | messagesPipe | uppercase) : ('reservation_multiple_number_of_minutes'  | messagesPipe | uppercase) }}</label>
          <select
            id="numberOfHours"
            name="numberOfHours"
            class="form-control"
            formControlName="numberOfHours"
          >
            <option [value]="-1" selected>
              {{ 'select' | messagesPipe }}
            </option>
            <option *ngFor="let hour of availableHours" [value]="hour">
              {{ hour }}
            </option>
          </select>
          <span
            class="required"
            [hidden]="multipleReservationForm.controls.numberOfHours.valid"
            >{{ 'error_numeric_required' | messagesPipe }}</span
          >
        </div>
      </div>

      <!-- Start Date -->
      <div class="form-group select-group col-sm-12 mui-textfield">
        <label for="startDate">{{
          'reservation_multiple_start_date' | messagesPipe
        }}</label>
        <datepicker
          class="custom-datepicker"
          #datePickerFromElement
          id="startDate"
          [options]="datepickerFromOptions"
          [locale]="locale"
          (dateChanged)="onStartDateChanged($event)"
        ></datepicker>
        <span class="required" [hidden]="startDate">{{
          'error_required' | messagesPipe
        }}</span>
      </div>

      <!-- End Date -->
      <div class="form-group select-group col-sm-12 mui-textfield">
        <label for="endDate">{{
          'reservation_multiple_end_date' | messagesPipe
        }}</label>
        <datepicker
          class="custom-datepicker"
          #datePickerToElement
          id="endDate"
          [options]="datepickerToOptions"
          [locale]="locale"
          (dateChanged)="onEndDateChanged($event)"
        ></datepicker>
        <span class="required" [hidden]="endDate">{{
          'error_required' | messagesPipe
        }}</span>
      </div>

      <!-- Button -->
      <div class="placed-button">
        <button
          type="submit"
          class="check-availability button-by"
          [disabled]="!isFormValid()"
        >
          <i class="vdp-vista-imagen"></i
          >{{ 'reservation_multiple_see_positions' | messagesPipe }}
        </button>
      </div>
    </form>
  </div>

  <!-- Content 2 -->
  <div id="tab2" class="tabcontent">
    <!-- Search People Component -->
    <app-search-people
      [serviceType]="searchType"
      (onSelectedUsersChange)="getSelectedPeople($event)"
    ></app-search-people>

    <!-- Button -->
    <div class="placed-button">
      <button
        (click)="seeAvailablePositions()"
        class="check-availability button-by"
        [disabled]="!isFormValid()"
      >
        <i class="vdp-vista-imagen"></i
        >{{ 'reservation_multiple_see_positions' | messagesPipe }}
      </button>
    </div>
  </div>
</div>
