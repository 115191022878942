<app-spinner></app-spinner>

<div class="row reservation-list">
  <!-- Col 1: Reserved positions -->
  <div class="col-md-6">
    <!-- Title -->
    <h2>{{ 'reserved_seats' | messagesPipe }}</h2>

    <!-- No reservation -->
    <div class="no-reservation" *ngIf="reservationList.length <= 0">
      <div class="image">
        <img src="../../assets/img/icon-sala-vacia.png" alt="" />
      </div>
      <span>{{ 'no_reserved_seats' | messagesPipe }}</span>
    </div>

    <!-- List -->
    <div class="card" *ngFor="let reservation of reservationList">
      <div
        class="detail"
        (click)="
          viewMyPosition(
            reservation?.desk.id,
            reservation?.desk.area.id,
            reservation.dateTo
          )
        "
      >
        <span class="detail--title">{{ reservation?.desk.area.name }}</span>
        <span class="detail--username" *ngIf="reservation?.organizer">
          <i class="vdp-icon_usuario"></i>
          {{ 'reservation_by_admin' | messagesPipe
          }}{{ reservation?.organizer.name }}
        </span>
        <span class="detail--building">
          {{ 'building' | messagesPipe }}
          {{ reservation?.desk.area.floor.building.name }},
          {{ 'floor' | messagesPipe }}
          {{ reservation?.desk.area.floor.name }}.
        </span>
        <span class="detail--date">
          {{ reservation.dateFrom | date }}
          {{ 'time_str_from' | messagesPipe }}
          {{ reservation?.hourFrom }}:{{ reservation?.minuteFrom }}
          {{ 'time_str_to' | messagesPipe }}
          {{ reservation?.hourTo }}:{{ reservation?.minuteTo }}
        </span>
      </div>
      <!-- Desk Code -->
      <span class="state">{{ reservation?.desk.code }}</span>

      <!-- Card Options -->
      <div class="card-options">
        <!-- Share -->
        <div class="share" (click)="showShareModal($event, reservation)">
          <i class="vdp-share"></i>
        </div>

        <!-- Available Options -->
        <div class="available-options">
          <!-- Cancel -->
          <div
            *ngIf="reservation.status.id != 4"
            (click)="
              confirmCancelation(
                $event,
                reservation.locatorCode,
                reservation.id
              )
            "
          >
            {{ 'reservation_give' | messagesPipe }}
          </div>

          <!-- Edit -->
          <div *ngIf="(!reservation.canCheckin && reservation.status.id != 4) || !isDinner"
            (click)="
              editPosition(
                reservation.id,
                reservation.desk.id,
                reservation.dateTo,
                reservation?.desk.area.id
              )
            "
          >
            {{ 'reservation_edit' | messagesPipe }}
          </div>

          <!-- Check-In -->
          <div
            *ngIf="reservation.canCheckin && permissions.allowWebCheckin"
            (click)="canCheckIn($event, reservation.id)"
          >
            {{ 'reservation_checkin' | messagesPipe }}
          </div>
          
          <!-- Check-out -->
          <div
            *ngIf="reservation.status.id == 4"
            (click)="doCheckOut($event, reservation.locatorCode)"
          >
            {{ 'reservation_checkout' | messagesPipe }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Col 2: History -->
  <div class="col-md-6">
    <!-- Title -->
    <h2>{{ 'reservation_historical_title' | messagesPipe }}</h2>

    <!-- No reservation -->
    <div class="no-reservation" *ngIf="reservationHistoryList.length <= 0">
      <div class="image">
        <img src="../../assets/img/icon-sala-vacia.png" alt="" />
      </div>
      <span>{{ 'reservation_historical_description' | messagesPipe }}</span>
    </div>

    <!-- List -->
    <div
      class="card history"
      *ngFor="let reservation of reservationHistoryList"
    >
      <div class="detail">
        <span class="detail--title">{{ reservation?.desk.area.name }}</span>
        <span class="detail--username" *ngIf="reservation?.organizer">
          <i class="vdp-icon_usuario"></i>
          {{ 'reservation_by_admin' | messagesPipe
          }}{{ reservation?.organizer.name }}
        </span>
        <span class="detail--building">
          {{ 'building' | messagesPipe }}
          {{ reservation?.desk.area.floor.building.name }},
          {{ 'floor' | messagesPipe }}
          {{ reservation?.desk.area.floor.name }}.
        </span>
        <span class="detail--date">
          {{ reservation.dateFrom | date }}
          {{ 'time_str_from' | messagesPipe }}
          {{ reservation?.hourFrom }}:00
          {{ 'time_str_to' | messagesPipe }}
          {{ reservation?.hourTo }}:59
        </span>
      </div>
      <!-- Desk Code -->
      <span class="state">{{ reservation?.desk.code }}</span>
    </div>

    <!-- Load More -->
    <div
      *ngIf="!isLast && this.reservationHistoryList.length > 0"
      class="load-more"
      (click)="getMoreElementsFromHistory()"
    >
      {{ 'reservation_load_more' | messagesPipe }}
    </div>
  </div>
</div>

<!-- Buttons -->
<div class="add-reservation">
  <button
    type="button"
    class="vdp-icon-mas add"
    (click)="showButtons()"
    [ngClass]="{ closed: showOptionsButtons }"
  ></button>
  <div
    class="list-of-positions"
    id="list-of-positions"
    [@slideButtons]="getSlideButtonsClass()"
  >
    <div class="list-of-positions-buttons">
      <!-- Option 1 -->
      <button type="button" (click)="openSearchUserPositionForm()">
        {{ 'reservation_menu_user' | messagesPipe }}
        <i class="vdp-buscar-usuario"></i>
      </button>
      <!-- Option 2 -->
      <button
        type="button"
        (click)="openMultipleReservationForm()"
        *ngIf="permissions?.allowMultipleHotdeskReservation"
      >
        {{ 'reservation_menu_multiple' | messagesPipe }}
        <i class="vdp-reserva-multiple"></i>
      </button>
      <!-- Option 3 -->
      <button type="button" (click)="openReservationByPositionForm()">
        {{ 'reservation_menu_position' | messagesPipe }}
        <i *ngIf="!isDinner" class="vdp-puesto_trabajo"></i>
        <i *ngIf="isDinner" class="vdp-puestos-restaurante"></i>
      </button>
    </div>
  </div>
</div>

<!-- Submenu option: search position by user -->
<app-search-user-position
  (closeFormEmmitter)="closeModalSearchUserPositionForm()"
  [showForm]="showSearchUserPositionForm"
>
</app-search-user-position>
<!-- Submenu option: multiple reservation -->
<app-multiple-reservation
  (closeFormEmmitter)="closeModalReservationForm()"
  [showForm]="showMultipleReservationForm"
>
</app-multiple-reservation>

<!-- Submenu option: reserve a seat -->
<app-reservation-by-position
  (closeFormEmmitter)="closeReservationByPositionForm()"
  [showForm]="showReservationByPositionForm"
>
</app-reservation-by-position>

<!-- Opens a modal window when we click on the share icon located on the reservation card -->
<app-share-my-position
  [visible]="isShareModalVisible"
  [myCurrentPosition]="myReservation"
  (closeModal)="hideShareModal()"
></app-share-my-position>
