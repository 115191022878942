import { Component, OnInit } from '@angular/core';
import { ModalAcceptDialogService } from './modal-accept-dialog.service';
import { MessagesPipe } from '../../../shared/pipes/messages.pipe';
import { UserLdapWs } from '../../../core/models/user.ldap.ws';
import { Department as Department } from 'src/app/core/models/department';
import { BuildingWs } from '../../../core/models/building.ws';
import { HeadquartersWS } from '../../../core/models/headquarters.ws';
import { CalendarEditConfirmService } from 'src/app/shared/components/calendar/calendar-edit/calendar-edit-confirm/calendar-edit-confirm.service';

@Component({
  selector: 'app-modal-accept-dialog',
  templateUrl: './modal-accept-dialog.component.html',
  styleUrls: ['./modal-accept-dialog.component.scss'],
})
export class ModalAcceptDialogComponent implements OnInit {
  modalConfig: any;
  user: UserLdapWs;
  messagesPipe = new MessagesPipe();
  departments: Department[];
  selectedDept: any;
  buildings: BuildingWs[];
  headquarters: HeadquartersWS[];
  assistanceInfo: any;

  constructor(
    private modalAcceptDialogService: ModalAcceptDialogService,
    private calendarEditConfirmService: CalendarEditConfirmService
  ) {
    this.modalAcceptDialogService.openModal = this.openModal.bind(this);
    this.modalAcceptDialogService.closeModal = this.closeModal.bind(this);
  }

  ngOnInit() {
    this.modalConfig = document.getElementById('modalConfig');
  }

  openModal(assistanceInfo: any) {
    this.assistanceInfo = assistanceInfo;
    this.modalConfig = document.getElementById('modalAcceptD');
    this.modalConfig.style.display = 'flex';
  }

  closeModal() {
    this.modalConfig.style.display = 'none';
  }

  confirm() {
    if (
      this.assistanceInfo &&
      this.assistanceInfo.attendance &&
      this.assistanceInfo.attendance.id
    ) {
      this.calendarEditConfirmService.cancelAssistance();
    } else if (
      this.assistanceInfo &&
      this.assistanceInfo.parking &&
      this.assistanceInfo.parking.hasParking
    ) {
      this.calendarEditConfirmService.cancelAssistancePlace();
    } else if (
      this.assistanceInfo &&
      this.assistanceInfo.dinner &&
      this.assistanceInfo.dinner.hasReservation
    ) {
      this.calendarEditConfirmService.cancelAssistanceTurn();
    }
    this.closeModal();
  }
}
