import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  STATUS_FORBIDDEN
} from 'src/app/core/constants/const';
import { LOGOUT_TIMEOUT } from 'src/app/core/constants/consts';
import { AvailablePeriodsInput } from 'src/app/core/models/available.periods.input';
import { ReservationPeriod } from 'src/app/core/models/reservation.periods';
import { RoomListWs } from 'src/app/core/models/room.list.ws';
import { RoomWs } from 'src/app/core/models/room.ws';
import { UserLdapWs } from 'src/app/core/models/user.ldap.ws';
import { DateStringPipe } from 'src/app/shared/pipes/date.string.pipe';
import { MessagesPipe } from 'src/app/shared/pipes/messages.pipe';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { BaseService } from 'src/app/shared/services/base.service';
import { FlashMessagesService } from 'src/app/shared/services/flash-messages.service';
import { ReservationService } from 'src/app/shared/services/reservation.service';
import { RoomService } from 'src/app/shared/services/room.service';

@Component({
  selector: 'app-reservation-results-by-room-and-period',
  templateUrl: 'reservation.results.by.room.period.component.html',
  styleUrls: ['reservation.results.by.room.period.component.scss'],
})
export class ReservationResultsByRoomAndPeriodComponent implements OnInit {
  @Input() availablePeriodsInput: AvailablePeriodsInput;
  @Input() selectedRoom: RoomWs;
  @Input() reservationReason: string;
  @Output() reservationDone = new EventEmitter();

  readonly DATE_FORMAT: string = 'DD/MM/YYYY';
  readonly TIME_FORMAT: string = 'HH:mm';

  results: ReservationPeriod[] = [];
  dateStringPipe = new DateStringPipe();
  messagesPipe = new MessagesPipe();
  page = 0;
  isLastPage = false;
  showServerLunchMsg = false;
  reservationResponse = '';
  currentUser: UserLdapWs;
  reservationInProgress = false;

  constructor(
    private reservationService: ReservationService,
    private authenticationService: AuthenticationService,
    private roomService: RoomService,
    private baseService: BaseService,
    private flashMessagesService: FlashMessagesService
  ) {
    this.currentUser = this.authenticationService.getCurrentUser();
  }

  ngOnInit() {
    this.reservationReason = this.reservationReason
      ? this.reservationReason
      : this.selectedRoom.name;
    this.getResults(this.page);
    this.getRoomDetail(
      this.selectedRoom.id,
      this.availablePeriodsInput.dateFrom
    );
  }

  reserve(period: ReservationPeriod) {
    const roomList = new RoomListWs(
      new Date(period.dateFrom),
      new Date(period.dateTo),
      this.reservationReason,
      1,
      [],
      null,
      null,
      null,
      this.currentUser.headquarters.id
    );

    this.reservationInProgress = true;

    this.reservationService
      .reservationBooking(
        this.selectedRoom,
        roomList,
        this.authenticationService.getToken()
      )
      .subscribe(
        (response) => {
          if (response.message != null && response.message.length > 0) {
            this.showServerLunchMsg = true;
            this.reservationResponse = response.message;
          } else {
            const dateStringPipe = new DateStringPipe();
            const successMessage = this.messagesPipe.transform(
              'room_reserved_msg',
              [
                this.selectedRoom.name,
                dateStringPipe.transform(roomList.dateFrom.toString(), 'DD/MM'),
                dateStringPipe.transform(roomList.dateFrom.toString(), 'HH:mm'),
                dateStringPipe.transform(roomList.dateTo.toString(), 'HH:mm'),
              ]
            );

            this.flashMessagesService.grayOut(false);
            this.flashMessagesService.show(successMessage, {
              cssClass: 'alert-success',
              timeout: 3000,
            });

            this.reservationDone.emit();
          }
        },
        (error) => {
          this.handleRequestError(error, 'reserve_creation_error', () => {
            this.reserve(period);
          });
        },
        () => {
          this.reservationInProgress = false;
        }
      );
  }

  onScrollDown(ev) {
    if (!this.isLastPage) {
      this.page++;
      this.getResults(this.page);
    }
  }

  formatDateFromMillis(date: number): string {
    return this.dateStringPipe.transform(
      new Date(date).toString(),
      this.DATE_FORMAT
    );
  }

  formatTimeFromMillis(date: number): string {
    return this.dateStringPipe.transform(
      new Date(date).toString(),
      this.TIME_FORMAT
    );
  }

  private getRoomDetail(roomId: number, date: Date) {
    this.roomService
      .getRoomDetail(roomId, date, this.authenticationService.getToken())
      .subscribe(
        (roomResponse) => {
          this.selectedRoom = roomResponse;
        },
        (error) => {
          this.handleRequestError(error, 'room_detail_error', () => {
            this.getRoomDetail(roomId, date);
          });
        }
      );
  }

  private getResults(page: number) {
    this.reservationService
      .getAvailablePeriods(
        this.authenticationService.getToken(),
        this.availablePeriodsInput,
        page
      )
      .subscribe(
        (pagedResult) => {
          this.isLastPage = pagedResult.last;
          this.results = this.results.concat(pagedResult.content);
        },
        (error) => {
          this.handleRequestError(error, 'room_detail_error', () => {
            this.getResults(page);
          });
        },
        () => {}
      );
  }

  // TODO: extract
  private handleRequestError(
    error: any,
    message: string,
    callback: Function
  ): void {
    if (error.code === STATUS_FORBIDDEN) {
      this.authenticationService.refreshToken().subscribe(
        (response) => {
          callback();
        },
        () => {
          this.authenticationService.validateSessionId().subscribe(
            (response) => {
              callback();
            },
            () => {
              this.flashMessagesService.grayOut(false);
              this.flashMessagesService.show(
                this.messagesPipe.transform('error_forbidden'),
                { cssClass: 'alert-danger', timeout: 3000 }
              );

              setTimeout(() => {
                this.authenticationService.logout();
              }, LOGOUT_TIMEOUT);
            }
          );
        }
      );
    } else {
      this.baseService.showErrorDialog(
        this.flashMessagesService,
        error,
        this.messagesPipe.transform(message)
      );
    }
  }
}
