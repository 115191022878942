import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';
import { finalize } from 'rxjs/operators';
import { MessagesPipe } from './shared/pipes/messages.pipe';
import { AuthenticationService } from './shared/services/authentication.service';
import { FlashMessagesService } from './shared/services/flash-messages.service';
import { LoginService } from './shared/services/login.service';
import { UserService } from './shared/services/user.service';
// import globals = require('./globals');

@Component({
  selector: 'app-main',
  templateUrl: 'app.component.html',
})
export class AppComponent implements OnInit {
  messagesPipe = new MessagesPipe();
  clientType = 'angular';

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private authService: MsalService,
    private loginService: LoginService,
    private userService: UserService,
    private flashMessagesService: FlashMessagesService
  ) {}

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (
          !this.authenticationService.existLogedUser() &&
          event.url !== '/login' &&
          !event.url.includes('/validate-token')
        ) {
          if (event.url.includes('/validate-token')) {
            // globals.redirectPage = event.url;
            this.router.navigate([event.url]);
          } else if (
            !event.url.includes('/boxOffice') &&
            !event.url.includes('/boxOffice-dinner') &&
            !event.url.includes('/readerBoxOffice') &&
            !event.url.includes('/readerBoxOffice-dinner') &&
            !event.url.includes('/reservationDesk') &&
            !event.url.includes('/reservationDesk-dinner') &&
            !event.url.includes('/viewMultipleBoxOffice') &&
            !event.url.includes('/viewMultipleBoxOffice-dinner') &&
            !event.url.includes('/viewSelectBoxOffice') &&
            !event.url.includes('/viewSelectBoxOffice-dinner') &&
            !event.url.includes('/reservationDeskEdit') &&
            !event.url.includes('/reservationDeskEdit-dinner') &&
            !event.url.includes('/resetPassword') &&
            !event.url.includes('/app-version') &&
            !event.url.includes('/dinning-occupation') &&
            !event.url.includes('/dinning-occupation-headquarter') &&
            !event.url.includes('/parking-occupation') &&
            !event.url.includes('/lopd')
          ) {
            // if (event.url !== '/') {
            //   globals.redirectPage = event.url;
            // }
            this.router.navigate(['/login']);
          }
        }

        if (
          event.url.includes('/boxOffice') ||
          event.url.includes('/boxOffice-dinner') ||
          event.url.includes('/readerBoxOffice') ||
          event.url.includes('/readerBoxOffice-dinner') ||
          event.url.includes('/reservationDesk') ||
          event.url.includes('/reservationDesk-dinner') ||
          event.url.includes('/viewMultipleBoxOffice') ||
          event.url.includes('/viewMultipleBoxOffice-dinner') ||
          event.url.includes('/viewSelectBoxOffice') ||
          event.url.includes('/viewSelectBoxOffice-dinner') ||
          event.url.includes('/reservationDeskEdit') ||
          event.url.includes('/reservationDeskEdit-dinner')
        ) {
          document.getElementById('container-page').style.animation = 'none';
        }
      }
    });

    this.authService.handleRedirectObservable().subscribe({
      next: (result: AuthenticationResult) => {
        if (result) {
          this.authService.instance.setActiveAccount(result.account);
          this.validateToken(result);
        }
      },
      error: (error) => console.log(error),
    });
  }

  validateToken(value: any) {
    this.loginService
      .validateTokenAzure(
        value.idToken,
        value.accessToken,
        value.idTokenClaims.preferred_username,
        this.clientType
      )
      .subscribe(
        (userData: any) => {
          if (userData && (userData.accessToken || userData.access_token)) {
            this.authenticationService.saveToken(userData);
            this.checkRedirection(userData);
          } else {
            this.flashMessagesService.show(
              this.messagesPipe.transform('incorrect_login'),
              { cssClass: 'alert-danger', timeout: 3000 }
            );
          }
        },
        (error) => {
          this.flashMessagesService.show(
            this.messagesPipe.transform('incorrect_login'),
            { cssClass: 'alert-danger', timeout: 3000 }
          );
        }
      );
  }

  checkRedirection(userData: any): void {
    if (this.authenticationService.getToken()) {
      this.userService
        .getLanguage(this.authenticationService.getToken())
        .pipe(
          finalize(() => {
            if (userData.headquarters && userData.headquarters.id) {
              this.router.navigate(['/menu/home']);
            } else {
              this.router.navigate(['/menu/profile']);
            }
          })
        )
        .subscribe((response) => {
          if (response) {
            localStorage.setItem('language_app', response.text);
          }
        });
    } else {
      if (userData.headquarters && userData.headquarters.id) {
        this.router.navigate(['/menu/home']);
      } else {
        this.router.navigate(['/menu/profile']);
      }
    }
  }
}
