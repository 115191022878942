<div class="assistance">
  <div *ngFor="let assistance of assistancesList; let index = index">
    <div class="row">
      <span>{{ assistance.date }}</span>
    </div>
    <form class="row mui-form" [formGroup]="assistanceForm" novalidate>
      <div
        class="row detailAssistance"
        *ngIf="attendanceIntegrationParking && assistance?.info?.parking"
      >
        <div class="col-sm-12 text">
          <label>
            {{
              !assistance?.info?.parking?.hasRequestParking &&
              !parkingSizeAttendanceEnable(assistance)
                ? ('assistance_text_not_space_available' | messagesPipe)
                : !assistance?.info?.parking?.hasRequestParking
                ? ('assistance_text_not_request_parking' | messagesPipe)
                : !assistance?.info?.parking?.hasVehicle
                ? ('assistance_text_not_vehicle_assign' | messagesPipe)
                : assistance?.info?.parking?.parkingSizeAttendance?.length > 0
                ? ('assistance_text_space_available' | messagesPipe)
                : ('assistance_text_not_space_available' | messagesPipe)
            }}</label
          >
        </div>
        <div
          class="col-sm-6"
          *ngIf="
            assistance?.info?.parking?.hasVehicle &&
            parkingSizeAttendanceEnable(assistance)
          "
        >
          <div class="select-group">
            <label for="placeParking">{{
              'assistance_text_available_vehicles' | messagesPipe
            }}</label>
            <select
              id="placeParking"
              name="placeParking"
              class="form-control"
              formControlName="placeParking"
            >
              <option
                [value]="0"
                [selected]="
                  assistance?.info?.parking?.hasRequestParking === false
                    ? true
                    : false
                "
              >
                {{ 'none' | messagesPipe }}
              </option>
              <option
                *ngFor="
                  let place of objectKeys(
                    assistance?.info?.parking?.parkingSizeAttendance
                  )
                "
                [value]="place?.typeId"
                [disabled]="
                  !assistance?.info?.parking?.parkingWaitingListEnabled &&
                  !place?.enabled
                "
              >
                {{ place?.size }}
                {{
                  assistance?.info?.parking?.parkingWaitingListEnabled &&
                  !place?.enabled
                    ? ('assistance_text_waiting' | messagesPipe)
                    : ''
                }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div
        class="row detailAssistance"
        *ngIf="attendanceIntegrationDinnerDesk && assistance?.info?.dinnerDesk"
      >
        <div class="col-sm-12 text">
          <label
            >{{
              !assistance?.info?.dinnerDesk?.hasRequestDinnerDesk
                ? ('assistance_text_not_request_dinner_space' | messagesPipe)
                : !checkDinnerDays(assistance.indexWeek) ||
                  !checkDinnerMonths(assistance.dateComplete)
                ? ('assistance_text_not_dining_room_service' | messagesPipe)
                : checkShiftPriority(
                    assistance?.info?.dinnerDesk?.availableTurns
                  ) && assistance?.info?.dinnerDesk?.availableTurns?.length > 0
                ? ('assistance_text_not_assign_dinner_space_hour_select'
                  | messagesPipe)
                : assistance?.info?.dinnerDesk?.availableTurns?.length <= 0
                ? ('assistance_text_not_assign_dinner_space' | messagesPipe)
                : ('assistance_text_assign_dinner_space' | messagesPipe)
            }}
          </label>
        </div>
        <div
          class="col-sm-6"
          *ngIf="
            checkDinnerDays(assistance.indexWeek) &&
            checkDinnerMonths(assistance.dateComplete) &&
            assistance.workingHome != true &&
            assistance.partTime != true
          "
        >
          <div class="select-group">
            <label for="turn">{{
              'assistance_text_available_shifts' | messagesPipe
            }}</label>
            <select
              id="turn"
              name="turn"
              class="form-control"
              formControlName="turn"
            >
              <option
                [value]="0"
                [selected]="
                  assistance?.info?.dinnerDesk?.hasRequestDinnerDesk ===
                    false ||
                  checkShiftPriority(
                    assistance?.info?.dinnerDesk?.availableTurns
                  )
                    ? true
                    : false
                "
              >
                {{ 'none' | messagesPipe }}
              </option>
              <option
                *ngFor="
                  let turn of assistance?.info?.dinnerDesk?.availableTurns
                "
                [value]="turn?.id"
              >
                {{ turn?.shiftFromAux }} - {{ turn?.shiftToAux }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div
        class="row detailAssistance"
        *ngIf="
          attendanceIntegrationDinnerDesk &&
          !assistance?.info?.dinnerDesk &&
          (assistance.workingHome || assistance.partTime)
        "
      >
        <div class="col-sm-12 text">
          <label
            >{{
              !checkDinnerDays(assistance.indexWeek) ||
              !checkDinnerMonths(assistance.dateComplete) ||
              assistance.workingHome ||
              assistance.partTime
                ? ('assistance_text_not_dining_room_service' | messagesPipe)
                : null
            }}
          </label>
        </div>
      </div>
    </form>
    <hr *ngIf="index + 1 != assistancesList.length" />
  </div>
  <div class="btn-flotante">
    <a (click)="closeForm()">
      {{ 'cancel_reservation_btn' | messagesPipe }}
    </a>
    <a
      (click)="confirmAssistance()"
      class="reserve"
      [ngClass]="[buttonDisabledConfirm ? 'a-not-active' : getActiveConfirm()]"
    >
      {{ 'assistance_text_not_confirm' | messagesPipe }}
    </a>
  </div>
</div>
