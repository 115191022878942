import { Component, OnInit, Input } from '@angular/core';
import { UserLdapWs } from 'src/app/core/models/user.ldap.ws';

@Component({
  selector: 'app-calendar-edit-list-users',
  templateUrl: './calendar-edit-list-users.component.html',
  styleUrls: ['./calendar-edit-list-users.component.scss']
})
export class CalendarEditListUsersComponent implements OnInit {
  @Input() assistanceSelected: any;
  @Input() listUsersForDay: UserLdapWs;

  user: UserLdapWs;

  constructor() { }

  ngOnInit() {
  }

}
