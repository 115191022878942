import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import {
  ANIMATE_CLASS_ACTIVE,
  ANIMATE_CLASS_INACTIVE,
} from '../../../core/constants/consts';
import { Router } from '@angular/router';
import { slideDetailsBooking } from '../../../shared/utils/reservation.list.animations';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MessagesPipe } from 'src/app/shared/pipes/messages.pipe';
import { MyDatePicker } from 'src/app/shared/components/date_picker/datepicker.component';
import { Utils } from 'src/app/shared/utils/utils';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { UserLdapWs } from '../../../core/models/user.ldap.ws';
import { OnChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-search-user-position',
  templateUrl: './search-user-position.component.html',
  styleUrls: ['./search-user-position.component.scss'],
  animations: [slideDetailsBooking()],
})
export class SearchUserPositionComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() showForm: boolean;
  @Output() closeFormEmmitter = new EventEmitter<any>();

  searchUserPositionForm: FormGroup;

  messagesPipe = new MessagesPipe();

  @ViewChild('datePickerElement', null) datePickerElement: MyDatePicker;
  @ViewChild('defaultOpen', null) defaultTab: ElementRef;

  searchType = 'basic';
  selectedPeople: any[] = [];

  datepickerOptions: any = {};
  date: Date;
  locale: string;

  user: UserLdapWs;

  disabledSearchPeople = false;
  label = 'reservation_search_user';
  placeHolder = 'search_user';

  url: string;

  constructor(
    private formBuilder: FormBuilder,
    private utils: Utils,
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.user = this.authenticationService.getCurrentUser();
    this.createForm();
    this.url = this.route.snapshot['_routerState'].url as string;
  }

  ngOnChanges() {
    this.datepickerOptions = this.utils.getDatepickerOptions(
      this.authenticationService.getMaxHotDeskAdvanceDays()
    );
    this.locale = localStorage.getItem('language_app') ? localStorage.getItem('language_app') : navigator.language;
  }

  ngAfterViewInit() {
    this.setDefaultDate();
  }

  getAnimations(): string {
    if (this.showForm) {
      return ANIMATE_CLASS_ACTIVE;
    } else {
      return ANIMATE_CLASS_INACTIVE;
    }
  }

  createForm() {
    this.searchUserPositionForm = this.formBuilder.group({});
  }

  isFormValid(): boolean {
    return (
      this.searchUserPositionForm.valid &&
      this.date != null &&
      this.selectedPeople.length === 1
    );
  }

  closeForm() {
    this.showForm = false;
    this.closeFormEmmitter.emit();
  }

  getSelectedPeople($event): void {
    this.selectedPeople = $event;
    if (this.selectedPeople.length > 0) {
      this.disabledSearchPeople = true;
    } else {
      this.disabledSearchPeople = false;
    }
  }

  onDateChanged($event) {
    this.date = $event.jsdate;
  }

  setDefaultDate() {
    const currentDate = new Date();

    this.datePickerElement.selectDate({
      day: currentDate.getDate(),
      month: currentDate.getMonth() + 1,
      year: currentDate.getFullYear(),
    });
  }

  seePositions(): void {
    const userId = this.selectedPeople[0].id;
    const date = this.date;
    this.closeReservationPositionForm();
    this.router.navigate([
      'menu',
      this.url.includes('/dinning-position')
        ? 'dinning-position'
        : 'job-position',
      'user-positions',
      date.getTime(),
      userId,
    ]);
  }

  closeReservationPositionForm() {
    this.showForm = false;
    this.removeValue();
    this.closeFormEmmitter.emit();
  }

  removeValue() {
    this.date = null;
    this.selectedPeople = [];
    this.createForm();
  }
}
