import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { environment } from '../../../../../../environments/environment';
import { MessagesPipe } from '../../../../pipes/messages.pipe';
import { AttendanceService } from '../../../../services/attendance.service';
import { BaseService } from '../../../../services/base.service';
import { FlashMessagesService } from '../../../../services/flash-messages.service';
import { CalendarEditConfirmService } from './calendar-edit-confirm.service';
declare var $: any;

@Component({
  selector: 'app-calendar-edit-confirm',
  templateUrl: './calendar-edit-confirm.component.html',
  styleUrls: ['./calendar-edit-confirm.component.scss'],
})
export class CalendarEditConfirmComponent implements OnInit, OnChanges {
  @Input() assistanceSelected: any;
  @Input() assistanceInfo: any;
  @Input() shiftPriority: any;
  @Input() mainVehicle: any;
  @Input() typeVehicle: any;
  @Input() showParkingSpotInfo: any;
  @Input() attendanceIntegrationParking: any;
  @Input() attendanceIntegrationDinnerDesk: any;
  @Input() userSelect: any;
  @Output() closeFormEmmitter = new EventEmitter<any>();
  @Output() confirmCancel = new EventEmitter<any>();
  @Output() confirmCancelPlace = new EventEmitter<any>();
  @Output() confirmCancelTurn = new EventEmitter<any>();

  messagesPipe = new MessagesPipe();
  assistanceForm: FormGroup;
  turnOK = false;

  buttonDisabledRequestParking = false;
  buttonDisabledRequestDinner = false;

  constructor(
    private formBuilder: FormBuilder,
    private flashMessagesService: FlashMessagesService,
    private attendanceService: AttendanceService,
    private baseService: BaseService,
    private router: Router,
    private calendarEditConfirmService: CalendarEditConfirmService
  ) {
    this.calendarEditConfirmService.cancelAssistance =
      this.cancelAssistance.bind(this);
    this.calendarEditConfirmService.cancelAssistancePlace =
      this.liberateParkig.bind(this);
    this.calendarEditConfirmService.cancelAssistanceTurn =
      this.cancelTurn.bind(this);
  }

  ngOnInit() {
    this.assistanceForm = this.formBuilder.group({
      turn: [this.shiftPriority ? this.shiftPriority : '0'],
      placeParking: [this.mainVehicle ? this.mainVehicle : '0'],
    });

    if (
      this.assistanceForm &&
      parseInt(this.assistanceForm.controls['turn'].value, 10) > 0
    ) {
      this.turnOK = true;
    } else {
      this.turnOK = false;
    }
  }

  ngOnChanges() {
    this.ngOnInit();
  }

  cancelAssistance() {
    this.attendanceService
      .cancelAssistance(this.assistanceSelected.assignments.id, this.userSelect)
      .subscribe(
        (res) => {
          this.flashMessagesService.show(
            this.messagesPipe.transform('assistance_text_cancel_ok'),
            { cssClass: 'alert-success', timeout: 3000 }
          );
          this.closeForm();
        },
        (error) => {
          this.baseService.showErrorDialog(
            this.flashMessagesService,
            error,
            this.messagesPipe.transform('assistance_text_cancel_ko')
          );
          this.closeForm();
        }
      );
  }

  confirmCancelAsistTurn() {
    const assist = {
      dinner: {
        hasReservation: true,
      },
    };
    this.confirmCancelTurn.emit(assist);
  }

  parkingSizeAttendanceEnable(assistance) {
    return assistance?.parking?.parkingSizeAttendance.find((x) => x.enabled);
  }

  cancelTurn() {
    this.attendanceService
      .cancelDinnerDesk(this.assistanceInfo.dinner.id, this.userSelect)
      .subscribe(
        (res) => {
          if (res) {
            this.flashMessagesService.show(
              this.messagesPipe.transform('assistance_text_cancel_dinner_ok'),
              { cssClass: 'alert-success', timeout: 3000 }
            );
            this.closeForm();
            this.router.navigate(['menu', 'assistance']);
          }
        },
        (error) => {
          this.baseService.showErrorDialog(
            this.flashMessagesService,
            error,
            this.messagesPipe.transform('assistance_text_cancel_dinner_ok')
          );
          this.closeForm();
        }
      );
  }

  solictTurn() {
    this.buttonDisabledRequestDinner = true;
    const optionSelected = this.assistanceForm.controls['turn'].value;
    const assistanceSelect = this.assistanceInfo.dinner.availableTurns.filter(
      (element) => {
        return parseInt(element.id, 10) === parseInt(optionSelected, 10);
      }
    );
    const request = {
      day: this.assistanceInfo.attendance.day,
      availableTurns: {
        shiftFrom:
          assistanceSelect && assistanceSelect.length > 0
            ? assistanceSelect[0].shiftFrom
            : '',
        shiftTo:
          assistanceSelect && assistanceSelect.length > 0
            ? assistanceSelect[0].shiftTo
            : '',
      },
      userId: this.userSelect,
    };
    this.attendanceService.requestDinnerDesk(request).subscribe(
      (res) => {
        if (res) {
          this.flashMessagesService.show(
            this.messagesPipe.transform('assistance_text_solict_dinner_ok'),
            { cssClass: 'alert-success', timeout: 3000 }
          );
          this.closeForm();
        }
      },
      (error) => {
        this.baseService.showErrorDialog(
          this.flashMessagesService,
          error,
          this.messagesPipe.transform('assistance_text_solict_dinner_ko')
        );
        this.closeForm();
      },
      () => {
        this.buttonDisabledRequestDinner = false;
      }
    );
  }

  checkShiftPriority(value: any) {
    const turn = this.assistanceForm.controls['turn'].value;
    let res = true;
    value.forEach((element) => {
      if (element.id === turn) {
        res = false;
      }
    });

    if (res) {
      this.assistanceForm.controls['turn'].setValue('0');
      this.turnOK = false;
    }

    return res;
  }

  changeTurn(value: any) {
    if (parseInt(value, 10) > 0) {
      this.turnOK = true;
    } else {
      this.turnOK = false;
    }
  }

  activeIconParking() {
    return this.assistanceInfo &&
      this.assistanceInfo.parking &&
      this.assistanceInfo.parking.hasParking &&
      this.assistanceInfo.parking.assignmentId &&
      this.parkingSizeAttendanceEnable(this.assistanceInfo)
      ? ` active`
      : ``;
  }

  activeIconDinner() {
    return this.assistanceInfo &&
      this.assistanceInfo.dinner &&
      this.assistanceInfo.dinner.hasReservation &&
      this.assistanceInfo.dinner.id
      ? ` active`
      : ``;
  }

  getMessageParking() {
    switch (true) {
      case this.assistanceInfo?.parking?.assignmentId &&
        this.assistanceInfo?.parking?.parkingSizeAttendance[0]?.size &&
        this.typeVehicle > 1:
        return this.messagesPipe.transform('assistance_text_assign_parking', [
          this.assistanceInfo?.parking?.parkingSizeAttendance[0]?.size,
        ]);
        break;
      case this.assistanceInfo?.parking?.assignmentId &&
        this.assistanceInfo?.parking?.parkingSizeAttendance[0]?.size &&
        this.typeVehicle == 1:
        return this.messagesPipe.transform(
          'assistance_text_assign_parking_space'
        );

      case !this.assistanceInfo?.parking?.assignmentId &&
        this.assistanceInfo?.parking?.parkingWaitingListEnabled &&
        this.assistanceInfo?.parking?.userInParkingWaitingList:
        return this.messagesPipe.transform('assistance_text_waiting_list');

      case this.parkingSizeAttendanceEnable(this.assistanceInfo) &&
        !this.assistanceInfo?.parking?.assignmentId:
        return this.messagesPipe.transform(
          'assistance_text_not_assign_parking'
        );

      default:
        return this.messagesPipe.transform(
          'assistance_text_not_space_available'
        );
        break;
    }
  }

  liberateOrSolictParking(value: any) {
    if (value) {
      const assist = {
        parking: {
          hasParking: true,
        },
      };
      this.confirmCancelPlace.emit(assist);
    } else {
      this.solictParking();
    }
  }

  liberateParkig() {
    this.attendanceService
      .liberateParking(
        this.assistanceSelected.assignments.parking.assignmentId,
        this.userSelect
      )
      .subscribe(
        (res) => {
          if (res) {
            this.flashMessagesService.show(
              this.messagesPipe.transform(
                'assistance_text_liberate_parking_ok'
              ),
              { cssClass: 'alert-success', timeout: 3000 }
            );
            this.closeForm();
            this.router.navigate(['menu', 'assistance']);
          }
        },
        (error) => {
          this.baseService.showErrorDialog(
            this.flashMessagesService,
            error,
            this.messagesPipe.transform('assistance_text_liberate_parking_ko')
          );
          this.closeForm();
        }
      );
  }

  solictParking() {
    this.buttonDisabledRequestParking = true;
    const optionSelected = $(
      'select[name="placeParking"] option:selected'
    ).val();
    const solict = {
      day: moment(this.assistanceInfo.attendance.day, 'DD/MM/YYYY').format(
        'DD/MM/YYYY'
      ),
      parking: {
        assignmentId: null,
        hasParking: false,
        parkingTypeId: optionSelected
          ? parseInt(optionSelected, 10)
          : this.mainVehicle,
      },
      userId: this.userSelect,
    };

    this.attendanceService.solictParking(solict).subscribe(
      (res) => {
        if (res) {
          this.flashMessagesService.show(
            this.messagesPipe.transform('assistance_text_solict_parking_ok'),
            { cssClass: 'alert-success', timeout: 3000 }
          );
          this.closeForm();
          this.router.navigate(['menu', 'assistance']);
        }
      },
      (error) => {
        this.baseService.showErrorDialog(
          this.flashMessagesService,
          error,
          this.messagesPipe.transform('assistance_text_solict_parking_ko')
        );
        this.closeForm();
      },
      () => {
        this.buttonDisabledRequestParking = false;
      }
    );
  }

  cancelWaitingList(day: string) {
    const dateFormat = moment(day).format('DD/MM/YYYY');
    this.attendanceService
      .cancelWaitingList(dateFormat, this.userSelect)
      .subscribe(
        (res) => {
          this.flashMessagesService.show(
            this.messagesPipe.transform(
              'assistance_text_liberate_waiting_list_ok'
            ),
            { cssClass: 'alert-success', timeout: 3000 }
          );
          this.closeForm();
        },
        (error) => {
          this.baseService.showErrorDialog(
            this.flashMessagesService,
            error,
            this.messagesPipe.transform(
              'assistance_text_liberate_waiting_list_ko'
            )
          );
          this.closeForm();
        }
      );
  }

  checkSelectedPlace(value: any) {
    let res = false;
    if (value && this.mainVehicle && this.mainVehicle.type) {
      if (value.typeId == this.mainVehicle.type.type_id && value.enabled) {
        res = true;
      }
    }

    if (!res) {
      this.assistanceForm.controls['placeParking'].setValue('0');
    }

    return res;
  }

  closeForm() {
    this.assistanceForm.reset();
    this.closeFormEmmitter.emit();
    this.buttonDisabledRequestDinner = false;
    this.buttonDisabledRequestParking = false;
  }

  checkSelectedDisabled() {
    const select = $('#placeParking').val();
    return select == 0 ? true : false;
  }

  checkDinnerDays(indexWeek) {
    let hasDinner = false;
    const dinnerDays = environment['dinnerDays'];
    if (dinnerDays) {
      dinnerDays.forEach((element) => {
        if (element == indexWeek) {
          hasDinner = true;
        }
      });
    } else {
      hasDinner = true;
    }
    return hasDinner;
  }

  checkDinnerMonths(day) {
    const date = moment(day, 'DD-MM-YYYY');
    const month = date.month() + 1;
    let hasDinner = false;
    const dinnerMonths = environment['dinnerMonths'];
    if (dinnerMonths) {
      dinnerMonths.forEach((element) => {
        if (element == month) {
          hasDinner = true;
        }
      });
    } else {
      hasDinner = true;
    }
    return hasDinner;
  }

  confirmCancelAssistance() {
    this.confirmCancel.emit(this.assistanceInfo);
  }
}
