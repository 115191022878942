import {
  DEFAULT_AREA_DATA_API_PATH,
  DEFAULT_AREA_DINNER_DATA_API_PATH,
  DEFAULT_AREA_HOTDESK_DATA_API_PATH,
  DEFAULT_FLOOR_DATA_API_PATH,
  DEFAULT_HOLIDAY_DATA_API_PATH,
  DEFAULT_LANGUAGE,
  DEFAULT_PARKING_DATA_API_PATH,
} from 'src/app/core/constants/const';
import { OptionsEnv } from 'src/app/core/models/options.env';

export const environment = {
  production: true,
  use_azure: true,
  clientId: 'b6ad875b-2f16-47fc-9eda-602074adbbc9',
  authority:
    'https://login.microsoftonline.com/9bc3d1cd-55ca-4e13-b5a2-a9e9deaeba3f/',
  redirectUrl: 'https://smartbuilding.alten.it',
  downloadAvailable: true,
  donwloadUrls: {
    android: 'https://smartbuilding.alten.it/download/Alten_Italy_Smartbuilding_2.6.8.apk',
    ios: 'https://smartbuilding.alten.it/download/manifest.plist',
  },
  enableLopd: false,
  showHeadquarterProfile: true,
  hiddenReservationOtherHeadquarters: false
};
export let NAME = 'Alten';
export const SERVICE_BASE_URL = 'https://smartbuilding.alten.it/ws';
export const COMPONENT_OAUTH_PATH = 'registerToken';
export const LOGIN_RETURN_URL = `https://smartbuilding.alten.it/ws${COMPONENT_OAUTH_PATH}`;
export const CLIENT_ID = '6b6e84630bdf431d6bff613e3a5c48a9';
export const PARKING_DATA_API_PATH = DEFAULT_PARKING_DATA_API_PATH;

export const AREA_DATA_API_PATH = DEFAULT_AREA_DATA_API_PATH;

export const FLOOR_DATA_API_PATH = DEFAULT_FLOOR_DATA_API_PATH;
export const AREA_DINNER_DATA_API_PATH = DEFAULT_AREA_DINNER_DATA_API_PATH;
export const AREA_HOTDESK_DATA_API_PATH = DEFAULT_AREA_HOTDESK_DATA_API_PATH;

export const HOLIDAY_DATA_API_PATH = DEFAULT_HOLIDAY_DATA_API_PATH;

export const LANGUAGE = DEFAULT_LANGUAGE;

export let OPTIONS_RESTRICTION: OptionsEnv = {
  dalkon_token: false,
};
