<div class="main-container">
  <div class="fadeInLayer" *ngIf="!permissions?.disableReservation">
    <app-reservation-detail
      (eventCloseDetail)="closeDetail()"
      (eventCancel)="cancelReservation($event)"
      [reservationWs]="reservationDetail"
      (eventEdit)="editReservation($event)"
    ></app-reservation-detail>
    <reservationSpeedDetail
      (eventCloseDetail)="closeDetail()"
      (eventCancel)="cancelReservation($event)"
      [reservationWs]="reservationDetailSpeed"
      (eventEdit)="editReservation($event)"
    ></reservationSpeedDetail>
    <div class="row">
      <div class="col-md-12 viewmode">
        <div class="date-filter">
          <span class="filterBy" *ngIf="dateFilterText.length > 0">{{
            dateFilterText
          }}</span>
          <datepicker
            #dateFilterElement
            class="custom-datepicker"
            id="dateFilter"
            (dateChanged)="onDateChanged($event)"
            [options]="datepickerOptions"
          ></datepicker>
        </div>

        <button (click)="changeView()" [disabled]="viewCard">
          <i class="vdp-vista-imagen"></i>
        </button>
        <button (click)="changeView()" [disabled]="!viewCard">
          <i class="vdp-vista-listado"></i>
        </button>
      </div>
      <div class="col-md-7 reservation-meeting-rooms">
        <h2>{{ 'rooms_reserve' | messagesPipe }}</h2>
        <div class="no-reservation" *ngIf="reservations.length <= 0">
          <div class="image">
            <img src="../../assets/img/icon-sala-vacia.png" alt="" />
          </div>
          <span>{{ 'rooms_reserve_empty' | messagesPipe }}</span>
        </div>
        <div *ngFor="let reservation of reservations">
          <app-reservation-card
            (eventInvitation)="showInvitationModal($event)"
            (eventDetail)="showDetail($event)"
            (eventCancel)="cancelReservation($event)"
            [reservationWs]="reservation"
            [viewCard]="viewCard"
          ></app-reservation-card>
        </div>
      </div>
      <div class="col-md-5 waiting-list">
        <h2>{{ 'waiting_list' | messagesPipe }}</h2>
        <div *ngFor="let waiting of waitingList">
          <waitingDetail
            [waiting]="waiting"
            [viewCard]="viewCard"
            (waitingEmitter)="cancelWaiting($event)"
          ></waitingDetail>
        </div>
      </div>
    </div>

    <div class="add-meeting-room">
      <button
        type="button"
        class="vdp-icon-mas add"
        (click)="showResButtons()"
        [ngClass]="{ closed: showReservationButtons }"
      ></button>
      <div
        class="add-meeting-room-detail"
        id="add-meeting-room-detail"
        [@slideButtons]="getSlideButtonsClass()"
      >
        <div class="add-meeting-room-detail-buttons">
          <button *ngIf="permissions.allowHeadquarters && !hiddenReservationOtherHeadquarters"
            type="button"
            (click)="newReservationbyHQ()"
          >
            {{ 'room_reserve_byHQ' | messagesPipe }}
            <i class="vdp-icon-reserva-sala"></i>
          </button>

          <button
            type="button"
            (click)="speedReservation()"
            *ngIf="permissions.allowSpeed"
          >
            {{ 'room_speed_reserve' | messagesPipe
            }}<i class="vdp-icon-reserva-speed"></i>
          </button>

          <button
            *ngIf="permissions.allowExpress"
            type="button"
            (click)="expressReservationShort()"
            [disabled]="expressLoading"
          >
            {{ 'express_reservation_btn' | messagesPipe: [getShortTimeDesc()] }}
            <i class="vdp-icon-reserva-express"></i>
          </button>

          <button
            *ngIf="permissions.allowExpress"
            type="button"
            (click)="expressReservationLong()"
            [disabled]="expressLoading"
          >
            {{ 'express_reservation_btn' | messagesPipe: [getLongTimeDesc()] }}
            <i class="vdp-icon-reserva-express"></i>
          </button>

          <button 
            type="button" 
            *ngIf="permissions?.allowRoomSearch && !hiddenInviteReservationRoom" 
            (click)="openSearchByRoomAndPeriodForm()"
          >
            {{ 'room_reserve_byRoomAndPeriod_btn' | messagesPipe }}
            <i class="vdp-icon-buscar-sala"></i>
          </button>

          <button type="button" (click)="newReservationByRoom()"
            *ngIf="permissions.allowRoomSearch">
            {{ 'room_reserve_byRoom_btn' | messagesPipe }}
            <i class="vdp-icon-buscar-sala"></i>
          </button>

          <button type="button" (click)="newReservation()">
            {{ 'room_reserve_btn' | messagesPipe }}
            <i class="vdp-icon-reserva-sala"></i>
          </button>
        </div>
      </div>
    </div>
    <div>
      <app-reservation-new-form
        (closeFormEmmitter)="closeFormNewReserve()"
        [showForm]="showNewReservationForm"
        [equimenent]="equipment"
        [reservationTypes]="reservationTypes"
      ></app-reservation-new-form>

      <reservationNewFormByHQ
        (closeFormEmmitter)="closeFormNewReserveByHQ()"
        [showForm]="showNewReservationFormByHQ"
        [equimenent]="equipment"
        [reservationTypes]="reservationTypes"
      ></reservationNewFormByHQ>

      <reservationNewDetailByRoom
        (closeFormEmmitter)="closeFormNewReserveRoom()"
        [showForm]="showNewReservationByRoomForm"
        [buildings]="builidingList"
      ></reservationNewDetailByRoom>

      <reservationSpeedNewDetail
        (closeFormEmmitter)="closeSpeedReservationForm()"
        [showForm]="showNewReservationSpeedForm"
        [reservationTypes]="reservationTypes"
      ></reservationSpeedNewDetail>

      <app-reservation-search-by-room-and-period
        (closeFormEmmitter)="closeSearchByRoomAndPeriodForm()"
        [show]="showSearchByRoomAndPeriodForm"
        [buildings]="builidingList"
      ></app-reservation-search-by-room-and-period>
    </div>
  </div>
  <div class="disableReservation" *ngIf="permissions?.disableReservation">
    <label> {{'rooms_disable_reservation_title' | messagesPipe}}</label>
    <span> {{'rooms_disable_reservation_description' | messagesPipe}}</span>
  </div>
</div>

<div *ngIf="showReservationMsg" class="modal fade" role="dialog">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-info">
        <span class="message">{{ reservationResponse.message }}</span>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-default"
          (click)="closeReservationMessage()"
        >
          {{ 'ok' | messagesPipe }}
        </button>
      </div>
    </div>
  </div>
</div>

<app-reservation-invites
  [visible]="invitationModalVisible"
  [currentReservation]="currentReservation"
  (closeEvent)="hideInvitationModal()"
></app-reservation-invites>
