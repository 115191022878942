<div
  class="right-drawer new-reservation"
  [@slideDetailsBooking]="getNewReservationAnimate()"
>
  <button
    type="button"
    class="vdp-icon-cancelar"
    (click)="closeFormNewReserve()"
  ></button>
  <span>{{'room_reserve_btn' | messagesPipe}}</span>
  <form class="row mui-form" (ngSubmit)="checkReservations()" novalidate>
    <div class="date-by form-group col-sm-6 mui-textfield">
      <label for="fecha">{{'date' | messagesPipe}}</label>
      <datepicker
        #datePickerElement
        class="custom-datepicker"
        id="fecha"
        [options]="datepickerOptions"
        (dateChanged)="onDateChanged($event)"
      ></datepicker>
      <span class="required" [hidden]="reservationDate"
        >{{'error_required' | messagesPipe}}</span
      >
    </div>

    <div class="form-group select-group col-sm-12">
      <div class="mui-select">
        <label for="userHeadquarters">{{'headquarters' | messagesPipe}}</label>
        <span id="userHeadquarters" name="userHeadquarters">
          {{ userHeadquarter.name }}
        </span>
      </div>
    </div>

    <div class="building-by form-group select-group col-sm-12">
      <div class="mui-select">
        <label for="selectBuildings"
          >{{'building_select_lbl' | messagesPipe}}</label
        >
        <select
          #selectBuildings
          id="selectBuildings"
          name="selectBuildings"
          class="form-control"
          (change)="buildingChanged($event)"
        >
          <option [value]="-1">{{'select_building' | messagesPipe}}</option>
          <option
            *ngFor="let building of buildings"
            [value]="building.id"
            [selected]="building && user.building && building.id == user.building.id"
          >
            {{building.name}}
          </option>
        </select>
      </div>
    </div>

    <div class="rooms-by form-group select-group col-sm-12">
      <div class="mui-select">
        <label for="selectRooms">{{'room_select_lbl' | messagesPipe}}</label>
        <select
          #selectRooms
          id="selectRooms"
          name="selectRooms"
          class="form-control"
          (change)="roomChanged($event)"
        >
          <option [value]="">{{'select_room' | messagesPipe}}</option>
          <option *ngFor="let room of rooms" [value]="room.id"
            >{{room.name}}</option
          >
        </select>
      </div>
    </div>
    <button
      type="submit"
      class="check-availability button-by"
      [disabled]="!reservationDate"
    >
      {{'btn_view_rooms' | messagesPipe}}
    </button>
  </form>
</div>
