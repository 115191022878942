import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ModalAcceptDialogService } from '../../shared/components/modal-accept-dialog/modal-accept-dialog.service';

@Component({
  selector: 'app-assistance',
  templateUrl: './assistance.component.html',
  styleUrls: ['./assistance.component.scss'],
})
export class AssistanceComponent implements OnInit {

  mode = 'ASSISTANCE';

  constructor(private modalAcceptDialogService: ModalAcceptDialogService) {}

  ngOnInit() {}

  confirmCancel(assistanceInfo: any) {
    this.modalAcceptDialogService.openModal(assistanceInfo);
  }

  confirmCancelPlace(assistanceInfo: any) {
    this.modalAcceptDialogService.openModal(assistanceInfo);
  }

  confirmCancelTurn(assistanceInfo: any) {
    this.modalAcceptDialogService.openModal(assistanceInfo);
  }
}
