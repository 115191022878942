import { Component, OnChanges, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { Router } from '@angular/router';
import { Department as Department } from 'src/app/core/models/department';
import { MenuPermissions } from 'src/app/core/models/menu.permissions';
import { DepartmentService } from 'src/app/shared/services/department.service';
import {
  LOCAL_CURRENT_USER,
  STATUS_FORBIDDEN,
  STATUS_CONFLICT,
} from '../../core/constants/const';
import { LOGOUT_TIMEOUT } from '../../core/constants/consts';
import { Building } from '../../core/models/building';
import { BuildingWs } from '../../core/models/building.ws';
import { Headquarters } from '../../core/models/headquarters';
import { HeadquartersWS } from '../../core/models/headquarters.ws';
import { UserLdapWs } from '../../core/models/user.ldap.ws';
import { MessagesPipe } from '../../shared/pipes/messages.pipe';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { BaseService } from '../../shared/services/base.service';
import { BuildingService } from '../../shared/services/building.service';
import { FlashMessagesService } from '../../shared/services/flash-messages.service';
import { HeadquartersService } from '../../shared/services/headquarters.service';
import { NavigatorRefreshService } from '../../shared/services/navigator.refresh.service';
import { UserService } from '../../shared/services/user.service';
import { ProfileHeadquarterValidator } from './headquarters.validator';
import { ProfileFormService } from './profile-form/profile-form.service';
import { environment } from '../../../environments/environment';
import { MsalService } from '@azure/msal-angular';
import * as moment from 'moment';
declare var $: any;

@Component({
  templateUrl: 'profile.component.html',
  providers: [ProfileHeadquarterValidator],
  styleUrls: ['profile.scss'],
})
export class ProfileComponent implements OnInit, OnChanges {
  user: UserLdapWs;
  buildings: BuildingWs[];
  buildingSelected: BuildingWs;
  messagesPipe = new MessagesPipe();
  profileForm: FormGroup;
  imageUrl: string;
  typeFormOpen: string;

  headquarters: HeadquartersWS[];
  headquartersSelected: HeadquartersWS;

  headquarterValid = true;
  headquartersSelectedHtml: HeadquartersWS;
  userWithoutHeadquarters: boolean;

  permissions: MenuPermissions;
  departments: Department[];

  changeHeadquarter = false;

  showSelectTurn: boolean;
  availableShifts: any;

  fileName: any;

  showModalLanguage = false;

  constructor(
    private authenticationService: AuthenticationService,
    private buildingService: BuildingService,
    private router: Router,
    private flashMessagesService: FlashMessagesService,
    private baseService: BaseService,
    private headquartersService: HeadquartersService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private navigatorRefreshService: NavigatorRefreshService,
    private profileFormService: ProfileFormService,
    private departmentService: DepartmentService,
    private azureService: MsalService
  ) {
    this.userWithoutHeadquarters = false;
  }

  ngOnInit() {
    this.user = this.authenticationService.getCurrentUser();
    this.permissions = this.authenticationService.getPermissionsForCurrentUser();
    this.getUserEditPermission();
    this.createForm();
    this.getFinalHeadquarterList();
    this.getAvailableShifts();
    this.navigatorRefreshService.emitMenuRefreshLinkEvent(
      this.messagesPipe.transform('menu_profile')
    );
    this.showSelectTurn = this.user.dinnerShiftRequest;
  }

  openModalForm(type: string) {
    this.typeFormOpen = type;
    if (type === 'supportAssistance') {
      const localUserCurrent = JSON.parse(
        localStorage.getItem(LOCAL_CURRENT_USER)
      );
      this.user.dinnerShiftRequest = localUserCurrent.dinnerShiftRequest;
      this.user.dinnerShiftFrom = localUserCurrent.dinnerShiftFrom;
      this.user.dinnerShiftTo = localUserCurrent.dinnerShiftTo;
      this.user.parkingRequest = localUserCurrent.parkingRequest;
      this.profileFormService.setUserProfile(this.user);
    }
    this.profileFormService.openModal(type);
  }

  openModalLanguage() {
    this.showModalLanguage = true;
  }

  closeModalLanguage() {
    this.showModalLanguage = false;
  }

  closeModalForm(value: string) {
    if (value === 'supportAssitance') {
      this.getUserEditPermission();
    }
  }

  createForm() {
    let buildingId;
    let headquartersId: number;
    let departmentId: number;
    let showHotdeskReservation: boolean;
    let shiftId: string;

    if (this.user.building != null) {
      buildingId = this.user.building.id;
    } else {
      buildingId = 0;
    }

    if (this.user.department != null) {
      departmentId = this.user.department.id;
    } else {
      departmentId = 0;
    }

    if (this.user.dinnerShiftFrom != null && this.user.dinnerShiftTo != null) {
      shiftId =
        moment(this.user.dinnerShiftFrom).format('HHmm') +
        '' +
        moment(this.user.dinnerShiftTo).format('HHmm');
    }

    if (this.user.showHotdeskReservation != null) {
      showHotdeskReservation = this.user.showHotdeskReservation;
    } else {
      showHotdeskReservation = false;
    }

    if (this.user.headquarters != null) {
      headquartersId = this.user.headquarters.id;
    } else {
      headquartersId = 0;
      buildingId = 0;
      this.userWithoutHeadquarters = true;
    }

    this.imageUrl = this.user.image;
    this.profileForm = this.formBuilder.group({
      name: [this.user.name],
      email: [
        this.user.email,
        [
          Validators.pattern(
            "^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~.-]+@[a-zA-Z0-9]([a-zA-Z0-9-]*[a-zA-Z0-9])?(.[a-zA-Z0-9]([a-zA-Z0-9-]*[a-zA-Z0-9])?)*$"
          ),
        ],
      ],
      image: [''],
      building: [buildingId],
      department: [departmentId],
      headquarters: [
        {
          value: headquartersId,
          disabled: this.notCheckAllowHeadquarter()
            ? false
            : !this.permissions.allowHeadquarters,
        },
        [ProfileHeadquarterValidator.validationHeadquarters],
      ],
      myReservations: [showHotdeskReservation],
      solictParking: '',
      solictDinner: '',
      availableShifts: [shiftId],
    });

    if (!this.permissions.allowEditProfile) {
      this.profileForm.controls.headquarters.disable();
      this.profileForm.controls['building'].disable();
      this.profileForm.controls['department'].disable();
      this.profileForm.controls['myReservations'].disable();
    }

    if (!this.showHeadquarter()) {
      this.profileForm.controls.headquarters.disable();
    }
  }

  getBuildingList() {
    this.buildingService
      .getBuildingList(this.authenticationService.getToken())
      .subscribe(
        (buildings) => {
          this.buildings = buildings;
        },
        (error) => {
          if (error.code == STATUS_FORBIDDEN) {
            this.authenticationService.refreshToken().subscribe(
              (response) => {
                this.getBuildingList();

                if (this.user.building) {
                  this.buildingSelected = new BuildingWs(
                    this.user.building.id,
                    this.user.building.name
                  );
                }
              },
              (error) => {
                this.authenticationService.validateSessionId().subscribe(
                  (response) => {
                    this.getBuildingList();

                    if (this.user.building) {
                      this.buildingSelected = new BuildingWs(
                        this.user.building.id,
                        this.user.building.name
                      );
                    }
                  },
                  (error) => {
                    this.flashMessagesService.grayOut(false);
                    this.flashMessagesService.show(
                      this.messagesPipe.transform('error_forbidden'),
                      { cssClass: 'alert-danger', timeout: 3000 }
                    );

                    setTimeout(() => {
                      this.authenticationService.logout();
                    }, LOGOUT_TIMEOUT);
                  }
                );
              }
            );
          } else {
            this.baseService.showErrorDialog(
              this.flashMessagesService,
              error,
              this.messagesPipe.transform('getBuildings_error')
            );
            this.router.navigate(['/menu/home']);
          }
        }
      );
  }

  ngOnChanges() {
    if (!this.permissions.allowEditProfile) {
      this.profileForm.controls.headquarters.disable();
      this.profileForm.controls['building'].disable();
    } else {
      this.profileForm.controls.headquarters.enable();
      this.profileForm.controls['building'].enable();
    }
  }

  getFinalHeadquarterList() {
    this.headquartersService
      .getFinalHeadquarterList(this.authenticationService.getToken())
      .subscribe(
        (headquarters) => {
          this.headquarters = headquarters;
          if (this.user.headquarters) {
            this.headquartersSelected = new HeadquartersWS(
              this.user.headquarters.id,
              this.user.headquarters.name,
              this.user.headquarters.allowParking
            );
            if (this.headquartersSelected) {
              this.getBuildingListByHeadquarter(
                this.authenticationService.getToken(),
                this.headquartersSelected.id
              );

              this.getDepartmentListByHeadquarter(
                this.authenticationService.getToken(),
                this.headquartersSelected.id
              );
            }
          }
        },
        (error) => {
          if (error.code == STATUS_FORBIDDEN) {
            this.authenticationService.refreshToken().subscribe(
              (response) => {
                this.getFinalHeadquarterList();
                if (this.user.headquarters) {
                  this.headquartersSelected = new HeadquartersWS(
                    this.user.headquarters.id,
                    this.user.headquarters.name
                  );
                  if (this.headquartersSelected) {
                    this.buildingService.getBuildingListByHeadquarters(
                      this.authenticationService.getToken(),
                      this.headquartersSelected.id
                    );
                  }
                }
              },
              (error) => {
                this.authenticationService.validateSessionId().subscribe(
                  (response) => {
                    this.getFinalHeadquarterList();
                    if (this.user.headquarters) {
                      this.headquartersSelected = new HeadquartersWS(
                        this.user.headquarters.id,
                        this.user.headquarters.name
                      );
                      if (this.headquartersSelected) {
                        this.buildingService.getBuildingListByHeadquarters(
                          this.authenticationService.getToken(),
                          this.headquartersSelected.id
                        );
                      }
                    }
                  },
                  (error) => {
                    this.flashMessagesService.grayOut(false);
                    this.flashMessagesService.show(
                      this.messagesPipe.transform('error_forbidden'),
                      { cssClass: 'alert-danger', timeout: 3000 }
                    );

                    setTimeout(() => {
                      this.authenticationService.logout();
                    }, LOGOUT_TIMEOUT);
                  }
                );
              }
            );
          } else {
            this.baseService.showErrorDialog(
              this.flashMessagesService,
              error,
              this.messagesPipe.transform('getHeadquarters_error')
            );
            this.router.navigate(['/menu/home']);
          }
        }
      );
  }

  getBuildingListByHeadquarter(token: string, headquartersId: number) {
    this.buildingService
      .getBuildingListByHeadquarters(
        this.authenticationService.getToken(),
        headquartersId
      )
      .subscribe(
        (buildings) => {
          this.buildings = buildings;
        },
        (error) => {
          if (error.code == STATUS_FORBIDDEN) {
            this.authenticationService.refreshToken().subscribe(
              (response) => {
                this.getBuildingListByHeadquarter(
                  this.authenticationService.getToken(),
                  headquartersId
                );

                if (this.user.building) {
                  this.buildingSelected = new BuildingWs(
                    this.user.building.id,
                    this.user.building.name
                  );
                }
              },
              (error) => {
                this.authenticationService.validateSessionId().subscribe(
                  (response) => {
                    this.getBuildingListByHeadquarter(
                      this.authenticationService.getToken(),
                      headquartersId
                    );

                    if (this.user.building) {
                      this.buildingSelected = new BuildingWs(
                        this.user.building.id,
                        this.user.building.name
                      );
                    }
                  },
                  (error) => {
                    this.flashMessagesService.grayOut(false);
                    this.flashMessagesService.show(
                      this.messagesPipe.transform('error_forbidden'),
                      { cssClass: 'alert-danger', timeout: 3000 }
                    );

                    setTimeout(() => {
                      this.authenticationService.logout();
                    }, LOGOUT_TIMEOUT);
                  }
                );
              }
            );
          } else {
            this.baseService.showErrorDialog(
              this.flashMessagesService,
              error,
              this.messagesPipe.transform('getBuildings_error')
            );
            this.router.navigate(['/menu/home']);
          }
        }
      );
  }

  getDepartmentListByHeadquarter(token: string, headquartersId: number) {
    this.departmentService
      .getDepartmentListByHeadquarterId(token, headquartersId)
      .subscribe(
        (data) => {
          this.departments = data;
        },
        (error) => {
          this.flashMessagesService.show(
            this.messagesPipe.transform('profile_departments_error'),
            { cssClass: 'alert-danger', timeout: 3000 }
          );
        }
      );
  }

  updateProfile() {
    let building;
    let department;
    let user = new UserLdapWs();
    let mediaType = 'base64,';
    let headquartersSelectedAux;

    user.name = this.profileForm.controls['name'].value;
    user.email = this.profileForm.controls['email'].value;
    user.image = this.profileForm.controls['image'].value;
    user.showHotdeskReservation = this.profileForm.controls[
      'myReservations'
    ].value;
    building = new Building(this.profileForm.controls['building'].value, null);
    department = new Department(
      this.profileForm.controls['department'].value,
      null,
      null,
      null
    );

    headquartersSelectedAux = this.headquarters.filter(
      (headquarter) =>
        headquarter.id == this.profileForm.controls['headquarters'].value
    );

    if (headquartersSelectedAux[0]) {
      user.headquarters = new Headquarters(
        headquartersSelectedAux[0].id,
        headquartersSelectedAux[0].name,
        headquartersSelectedAux[0].allowParking,
        headquartersSelectedAux[0].allowReservation
      );
    }

    if (user.image && user.image.length > 0) {
      user.image = user.image.substring(
        user.image.indexOf(mediaType) + mediaType.length,
        user.image.length
      );
    }

    if (
      user.headquarters &&
      user.headquarters == null &&
      user.headquarters.id <= 0
    ) {
      user.headquarters = null;
    }

    if (building != null && building.id > 0) {
      user.building = this.buildingService.findById(
        this.buildings,
        this.profileForm.controls['building'].value
      );
    } else {
      user.building = null;
    }

    if (department != null && department.id > 0 && this.departments) {
      user.department = this.departmentService.findById(
        this.departments,
        parseInt(this.profileForm.controls['department'].value, 10)
      );
    } else {
      user.department = null;
    }

    this.userService
      .updateProfile(this.authenticationService.getToken(), user)
      .subscribe(
        (response) => {
          if (response.image != null && response.image.length > 0) {
            user.image = response.image;
          } else if (this.user.image != null && this.user.image.length > 0) {
            user.image = this.user.image;
          }

          this.user.building = user.building;
          this.user.headquarters = user.headquarters;
          this.user.department = user.department;
          this.user.showHotdeskReservation = user.showHotdeskReservation;

          this.getUserEditPermission();

          this.navigatorRefreshService.emitMenuRefreshProfileEvent(
            this.profileForm.controls['image'].value
          );

          this.flashMessagesService.grayOut(false);
          this.flashMessagesService.show(
            this.messagesPipe.transform('updateProfile_success'),
            { cssClass: 'alert-success', timeout: 3000 }
          );
          if (this.changeHeadquarter) {
            this.logout();
          }
        },
        (error) => {
          if (error.code == STATUS_FORBIDDEN) {
            this.authenticationService.refreshToken().subscribe(
              (response) => {
                this.updateProfile();
              },
              (error) => {
                this.authenticationService.validateSessionId().subscribe(
                  (response) => {
                    this.updateProfile();
                  },
                  (error) => {
                    this.flashMessagesService.grayOut(false);
                    this.flashMessagesService.show(
                      this.messagesPipe.transform('error_forbidden'),
                      { cssClass: 'alert-danger', timeout: 3000 }
                    );

                    setTimeout(() => {
                      this.authenticationService.logout();
                    }, LOGOUT_TIMEOUT);
                  }
                );
              }
            );
          } else if (error.code == STATUS_CONFLICT) {
            this.baseService.showErrorDialog(
              this.flashMessagesService,
              '',
              this.messagesPipe.transform('indicate_your_dept_desc')
            );
          } else {
            this.baseService.showErrorDialog(
              this.flashMessagesService,
              error,
              this.messagesPipe.transform('updateProfile_error')
            );
            this.router.navigate(['/menu/home']);
          }
        }
      );
  }

  fileChageEvent(event) {
    this.fileName = null;
    if (event && event.target && event.target.files[0]) {
      const file = event.target.files[0];
      this.fileName = file.name;
      if (file.type.includes('image/')) {
        const sizeKiloByte = file.size / 1024;
        if (sizeKiloByte < 5000) {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            this.profileForm.get('image').patchValue(reader.result.toString());
          };
        } else {
          let fileImage = document.getElementById(
            'imageFile'
          ) as HTMLInputElement;
          fileImage.value = null;
          this.profileForm.get('image').patchValue(null);
          this.flashMessagesService.grayOut(false);
          this.flashMessagesService.show(this.messagesPipe.transform('profile_error_image'), {
              cssClass: 'alert-danger',
              timeout: 3000,
            });
        }
      } else {
        let fileImage = document.getElementById(
          'imageFile'
        ) as HTMLInputElement;
        fileImage.value = null;
        this.profileForm.get('image').patchValue(null);
        this.flashMessagesService.grayOut(false);
        this.flashMessagesService.show(
          this.messagesPipe.transform('incidence_file_type'),
          { cssClass: 'alert-error', timeout: 3000 }
        );
      }
    } else {
      this.profileForm.get('image').patchValue(null);
      this.flashMessagesService.grayOut(false);
      this.flashMessagesService.show(
        this.messagesPipe.transform('incidence_file_type'),
        { cssClass: 'alert-error', timeout: 3000 }
      );
    }
  }

  headquartersChanged($event: any) {
    this.buildingSelected = null;
    let headquartersId = parseInt($event.target.value);

    if (headquartersId && headquartersId > 0) {
      this.changeHeadquarter =
        headquartersId != this.headquartersSelected.id ? true : false;
      this.buildingSelected = new BuildingWs(
        null,
        null,
        new HeadquartersWS(headquartersId, null)
      );
      this.getBuildingListByHeadquarter(
        this.authenticationService.getToken(),
        headquartersId
      );
      this.getDepartmentListByHeadquarter(
        this.authenticationService.getToken(),
        headquartersId
      );
      this.headquarterValid = true;
    } else {
      this.headquartersSelected = null;
      this.buildings = [];
      this.departments = [];
      this.headquarterValid = false;
    }
  }

  validationHeadquarters(control: FormControl) {
    const input = control.value;
    if (input != undefined && input != null && input > 0) {
      return null;
    } else {
      return { validationHeadquarters: true };
    }
  }

  closeUserWithoutHeadquartersMessage() {
    this.userWithoutHeadquarters = false;
  }

  getUserEditPermission() {
    this.userService
      .getUserPermissions(this.authenticationService.getToken())
      .subscribe(
        (response) => {
          if (response.authorizations != null) {
            let user = JSON.parse(localStorage.getItem(LOCAL_CURRENT_USER));
            user.authorizations = response.authorizations;
            user.dinnerShiftRequest = response.dinnerShiftRequest;
            user.dinnerShiftFrom = response.dinnerShiftFrom;
            user.dinnerShiftTo = response.dinnerShiftTo;
            user.parkingRequest = response.parkingRequest;
            localStorage.setItem(LOCAL_CURRENT_USER, JSON.stringify(user));
            this.permissions = this.authenticationService.getPermissionsForCurrentUser();
            this.authenticationService.updateUser(response);
          }
        },
        (error) => {
          console.error();
        }
      );
  }

  showHeadquarter(): boolean {
    return environment['showHeadquarterProfile'];
  }

  disabledPassAndVehicle(): boolean {
    return environment['disabledPassAndVehicle'];
  }

  disabledPass(): boolean {
    return environment['disabledPass'];
  }

  notCheckAllowHeadquarter(): boolean {
    return environment['notCheckAllowHeadquarter'];
  }

  disabledPersonalData(): boolean {
    return environment['disabledPersonalData'];
  }

  logout() {
    this.authenticationService.logout();

    if (environment['use_azure']) {
      this.azureService.logout();
    }
  }

  getAvailableShifts() {
    const date = moment(new Date()).format('YYYY-MM-DD');
    this.userService
      .getDinnerShifts(this.authenticationService.getToken(), date)
      .subscribe((res) => {
        if (res) {
          this.availableShifts = res;
          let turn: any;
          for (let i = 0; i < this.availableShifts.length; i++) {
            const dateFrom = moment(this.availableShifts[i].shiftFrom).format(
              'HH:mm'
            );
            const dateTo = moment(this.availableShifts[i].shiftTo).format(
              'HH:mm'
            );
            turn = {
              id:
                moment(this.availableShifts[i].shiftFrom).format('HHmm') +
                '' +
                moment(this.availableShifts[i].shiftTo).format('HHmm'),
              shiftFrom: this.availableShifts[i].shiftFrom,
              shiftTo: this.availableShifts[i].shiftTo,
              shiftFromAux: dateFrom,
              shiftToAux: dateTo,
            };
            this.availableShifts[i] = turn;
          }
        }
      });
  }
}
