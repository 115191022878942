import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';
import { MenuPermissions } from 'src/app/core/models/menu.permissions';
import { AuthenticationService } from '../../../services/authentication.service';
import { UserLdapWs } from '../../../../core/models/user.ldap.ws';

@Component({
  selector: 'app-calendar-view-assistance',
  templateUrl: './calendar-view-assistance.component.html',
  styleUrls: ['./calendar-view-assistance.component.scss'],
})
export class CalendarViewAssistanceComponent implements OnInit {
  @Input() day: any;
  @Input() attendances: any;
  @Input() selectList: any;

  dateNew: Date;
  maxDayAssistance: number;
  permissions: MenuPermissions;
  user: UserLdapWs;

  constructor(private authenticationService: AuthenticationService) {}

  ngOnInit() {
    this.dateNew = new Date();
    this.permissions =
      this.authenticationService.getPermissionsForCurrentUser();
    this.maxDayAssistance = this.permissions.maxDaysAssistance;
    this.user = this.authenticationService.getCurrentUser();
  }

  getClassSelected(day: any) {
    let ret = '';
    if (day.selected) {
      ret = ` selected`;
    }
    if (day.disabledWhitAssistance) {
      ret += ` dayCalendarDisabledWhitAssistance`;
    }
    if (day.assignments && (day.assignments.id || day.assignments.day)) {
      ret += ` assignment`;
    }
    return ret;
  }

  checkClass(day: any) {
    let ret = '';
    if (day.isParking && day.isDinner) {
      ret += 'float-left ';
    }

    if (
      day &&
      day.assignments &&
      day.assignments.parking &&
      day.assignments.parking.parkingSizeAttendance &&
      day.assignments.parking.parkingSizeAttendance.typeCode === 'Motorcycle'
    ) {
      ret += 'vdp-parkingMotoBig ';
    } else {
      ret += 'vdp-parking_big';
    }

    return ret;
  }
}
