import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  ANIMATE_CLASS_ACTIVE,
  ANIMATE_CLASS_INACTIVE,
} from '../../../core/constants/consts';
import { slideDetailsBooking } from '../../../shared/utils/reservation.list.animations';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AttendanceService } from 'src/app/shared/services/attendance.service';
import { finalize } from 'rxjs/operators';
import { FlashMessagesService } from 'src/app/shared/services/flash-messages.service';
import { MessagesPipe } from 'src/app/shared/pipes/messages.pipe';
import {
  STATUS_CONFLICT,
  STATUS_NOT_ACCEPTABLE,
} from '../../../core/constants/const';

@Component({
  selector: 'app-assign-place',
  templateUrl: './assign-place.component.html',
  styleUrls: ['./assign-place.component.scss'],
  animations: [slideDetailsBooking()],
})
export class AssignPlaceComponent implements OnInit {
  @Input() showForm: boolean;
  @Output() closeFormEmmitter = new EventEmitter<any>();
  assignPlaceForm: FormGroup;
  messagesPipe = new MessagesPipe();

  constructor(
    private attendanceService: AttendanceService,
    private flashMessagesService: FlashMessagesService
  ) {}

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.assignPlaceForm = new FormGroup({
      enrollment: new FormControl('', [Validators.required]),
    });
  }

  getAnimations(): string {
    if (this.showForm) {
      return ANIMATE_CLASS_ACTIVE;
    } else {
      return ANIMATE_CLASS_INACTIVE;
    }
  }

  closeForm() {
    this.assignPlaceForm = null;
    this.showForm = false;
    this.closeFormEmmitter.emit();
    this.createForm();
  }

  save() {
    const license = this.assignPlaceForm.controls['enrollment'].value;
    this.attendanceService
      .assignPlace(license)
      .pipe(
        finalize(() => {
          this.closeForm();
        })
      )
      .subscribe(
        (response) => {
          this.flashMessagesService.grayOut(false);
          this.flashMessagesService.show(
            this.messagesPipe.transform('security_search_assign_place_ok'),
            {
              cssClass: 'alert-error',
              timeout: 3000,
            }
          );
        },
        (error) => {
          let message = '';
          if (error.code === STATUS_NOT_ACCEPTABLE) {
            message = this.messagesPipe.transform(
              'security_search_found_vehicle_error'
            );
          } else if (error.code === STATUS_CONFLICT) {
            message = this.messagesPipe.transform(
              'security_search_headquarters_vehicle_error'
            );
          } else {
            message = this.messagesPipe.transform(
              'security_search_assign_place_error'
            );
          }
          this.flashMessagesService.grayOut(false);
          this.flashMessagesService.show(message, {
            cssClass: 'alert-error',
            timeout: 3000,
          });
        }
      );
  }
}
