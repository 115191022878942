<div [ngClass]="showForm ? 'showOnlyForm' : ''">
  <div
    class="right-drawer assign-place"
    [@slideDetailsBooking]="getAnimations()"
  >
    <!-- Spinner -->
    <app-spinner></app-spinner>

    <!-- Close button -->
    <button
      type="button"
      class="vdp-icon-cancelar"
      (click)="closeForm()"
    ></button>

    <!-- Form title -->
    <span>{{ 'security_assign_place_btn' | messagesPipe }}</span>

    <form
      class="row mui-form"
      [formGroup]="assignPlaceForm"
      (ngSubmit)="save()"
      novalidate
    >
      <!-- Value -->
      <div class="col-12 row-security mui-textfield border-on">
        <label for="enrollment">{{
          'security_enrollment_value' | messagesPipe
        }}</label>
        <input id="enrollment" type="text" formControlName="enrollment" />
      </div>

      <div class="col-12 row-security">
        <button
          type="submit"
          class="check-availability button-by"
          [disabled]="!assignPlaceForm.valid"
        >
          {{ 'security_assign_place_btn' | messagesPipe }}
        </button>
      </div>
    </form>
  </div>
</div>
